var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

/*
 Highcharts JS v5.0.9 (2017-03-08)

 (c) 2009-2016 Torstein Honsi

 License: www.highcharts.com/license
*/
(function (I, a) {
  exports ? exports = I.document ? a(I) : a : I.Highcharts = a(I);
})("undefined" !== typeof window ? window : exports, function (I) {
  I = function () {
    var a = window,
        B = a.document,
        z = a.navigator && a.navigator.userAgent || "",
        C = B && B.createElementNS && !!B.createElementNS("http://www.w3.org/2000/svg", "svg").createSVGRect,
        E = /(edge|msie|trident)/i.test(z) && !window.opera,
        u = !C,
        h = /Firefox/.test(z),
        n = h && 4 > parseInt(z.split("Firefox/")[1], 10);
    return a.Highcharts ? a.Highcharts.error(16, !0) : {
      product: "Highcharts",
      version: "5.0.9",
      deg2rad: 2 * Math.PI / 360,
      doc: B,
      hasBidiBug: n,
      hasTouch: B && void 0 !== B.documentElement.ontouchstart,
      isMS: E,
      isWebKit: /AppleWebKit/.test(z),
      isFirefox: h,
      isTouchDevice: /(Mobile|Android|Windows Phone)/.test(z),
      SVG_NS: "http://www.w3.org/2000/svg",
      chartCount: 0,
      seriesTypes: {},
      symbolSizes: {},
      svg: C,
      vml: u,
      win: a,
      charts: [],
      marginNames: ["plotTop", "marginRight", "marginBottom", "plotLeft"],
      noop: function () {}
    };
  }();

  (function (a) {
    var B = [],
        z = a.charts,
        C = a.doc,
        E = a.win;

    a.error = function (u, h) {
      u = a.isNumber(u) ? "Highcharts error #" + u + ": www.highcharts.com/errors/" + u : u;
      if (h) throw Error(u);
      E.console && console.log(u);
    };

    a.Fx = function (a, h, n) {
      (this || _global).options = h;
      (this || _global).elem = a;
      (this || _global).prop = n;
    };

    a.Fx.prototype = {
      dSetter: function () {
        var a = (this || _global).paths[0],
            h = (this || _global).paths[1],
            n = [],
            t = (this || _global).now,
            l = a.length,
            k;
        if (1 === t) n = (this || _global).toD;else if (l === h.length && 1 > t) for (; l--;) k = parseFloat(a[l]), n[l] = isNaN(k) ? a[l] : t * parseFloat(h[l] - k) + k;else n = h;

        (this || _global).elem.attr("d", n, null, !0);
      },
      update: function () {
        var a = (this || _global).elem,
            h = (this || _global).prop,
            n = (this || _global).now,
            t = (this || _global).options.step;
        if ((this || _global)[h + "Setter"]) this[h + "Setter"]();else a.attr ? a.element && a.attr(h, n, null, !0) : a.style[h] = n + (this || _global).unit;
        t && t.call(a, n, this || _global);
      },
      run: function (a, h, n) {
        var u = this || _global,
            l = function (a) {
          return l.stopped ? !1 : u.step(a);
        },
            k;

        (this || _global).startTime = +new Date();
        (this || _global).start = a;
        (this || _global).end = h;
        (this || _global).unit = n;
        (this || _global).now = (this || _global).start;
        (this || _global).pos = 0;
        l.elem = (this || _global).elem;
        l.prop = (this || _global).prop;
        l() && 1 === B.push(l) && (l.timerId = setInterval(function () {
          for (k = 0; k < B.length; k++) B[k]() || B.splice(k--, 1);

          B.length || clearInterval(l.timerId);
        }, 13));
      },
      step: function (a) {
        var h = +new Date(),
            u,
            t = (this || _global).options;
        u = (this || _global).elem;
        var l = t.complete,
            k = t.duration,
            e = t.curAnim,
            c;
        if (u.attr && !u.element) u = !1;else if (a || h >= k + (this || _global).startTime) {
          (this || _global).now = (this || _global).end;
          (this || _global).pos = 1;
          this.update();
          a = e[(this || _global).prop] = !0;

          for (c in e) !0 !== e[c] && (a = !1);

          a && l && l.call(u);
          u = !1;
        } else (this || _global).pos = t.easing((h - (this || _global).startTime) / k), (this || _global).now = (this || _global).start + ((this || _global).end - (this || _global).start) * (this || _global).pos, this.update(), u = !0;
        return u;
      },
      initPath: function (u, h, n) {
        function t(a) {
          var b, f;

          for (r = a.length; r--;) b = "M" === a[r] || "L" === a[r], f = /[a-zA-Z]/.test(a[r + 3]), b && f && a.splice(r + 1, 0, a[r + 1], a[r + 2], a[r + 1], a[r + 2]);
        }

        function l(a, f) {
          for (; a.length < m;) {
            a[0] = f[m - a.length];
            var d = a.slice(0, b);
            [].splice.apply(a, [0, 0].concat(d));
            A && (d = a.slice(a.length - b), [].splice.apply(a, [a.length, 0].concat(d)), r--);
          }

          a[0] = "M";
        }

        function k(a, f) {
          for (var c = (m - a.length) / b; 0 < c && c--;) d = a.slice().splice(a.length / v - b, b * v), d[0] = f[m - b - c * b], H && (d[b - 6] = d[b - 2], d[b - 5] = d[b - 1]), [].splice.apply(a, [a.length / v, 0].concat(d)), A && c--;
        }

        h = h || "";
        var e,
            c = u.startX,
            p = u.endX,
            H = -1 < h.indexOf("C"),
            b = H ? 7 : 3,
            m,
            d,
            r;
        h = h.split(" ");
        n = n.slice();
        var A = u.isArea,
            v = A ? 2 : 1,
            f;
        H && (t(h), t(n));

        if (c && p) {
          for (r = 0; r < c.length; r++) if (c[r] === p[0]) {
            e = r;
            break;
          } else if (c[0] === p[p.length - c.length + r]) {
            e = r;
            f = !0;
            break;
          }

          void 0 === e && (h = []);
        }

        h.length && a.isNumber(e) && (m = n.length + e * v * b, f ? (l(h, n), k(n, h)) : (l(n, h), k(h, n)));
        return [h, n];
      }
    };

    a.extend = function (a, h) {
      var u;
      a || (a = {});

      for (u in h) a[u] = h[u];

      return a;
    };

    a.merge = function () {
      var u,
          h = arguments,
          n,
          t = {},
          l = function (k, e) {
        var c, p;
        "object" !== typeof k && (k = {});

        for (p in e) e.hasOwnProperty(p) && (c = e[p], a.isObject(c, !0) && "renderTo" !== p && "number" !== typeof c.nodeType ? k[p] = l(k[p] || {}, c) : k[p] = e[p]);

        return k;
      };

      !0 === h[0] && (t = h[1], h = Array.prototype.slice.call(h, 2));
      n = h.length;

      for (u = 0; u < n; u++) t = l(t, h[u]);

      return t;
    };

    a.pInt = function (a, h) {
      return parseInt(a, h || 10);
    };

    a.isString = function (a) {
      return "string" === typeof a;
    };

    a.isArray = function (a) {
      a = Object.prototype.toString.call(a);
      return "[object Array]" === a || "[object Array Iterator]" === a;
    };

    a.isObject = function (u, h) {
      return u && "object" === typeof u && (!h || !a.isArray(u));
    };

    a.isNumber = function (a) {
      return "number" === typeof a && !isNaN(a);
    };

    a.erase = function (a, h) {
      for (var u = a.length; u--;) if (a[u] === h) {
        a.splice(u, 1);
        break;
      }
    };

    a.defined = function (a) {
      return void 0 !== a && null !== a;
    };

    a.attr = function (u, h, n) {
      var t, l;
      if (a.isString(h)) a.defined(n) ? u.setAttribute(h, n) : u && u.getAttribute && (l = u.getAttribute(h));else if (a.defined(h) && a.isObject(h)) for (t in h) u.setAttribute(t, h[t]);
      return l;
    };

    a.splat = function (u) {
      return a.isArray(u) ? u : [u];
    };

    a.syncTimeout = function (a, h, n) {
      if (h) return setTimeout(a, h, n);
      a.call(0, n);
    };

    a.pick = function () {
      var a = arguments,
          h,
          n,
          t = a.length;

      for (h = 0; h < t; h++) if (n = a[h], void 0 !== n && null !== n) return n;
    };

    a.css = function (u, h) {
      a.isMS && !a.svg && h && void 0 !== h.opacity && (h.filter = "alpha(opacity=" + 100 * h.opacity + ")");
      a.extend(u.style, h);
    };

    a.createElement = function (u, h, n, t, l) {
      u = C.createElement(u);
      var k = a.css;
      h && a.extend(u, h);
      l && k(u, {
        padding: 0,
        border: "none",
        margin: 0
      });
      n && k(u, n);
      t && t.appendChild(u);
      return u;
    };

    a.extendClass = function (u, h) {
      var n = function () {};

      n.prototype = new u();
      a.extend(n.prototype, h);
      return n;
    };

    a.pad = function (a, h, n) {
      return Array((h || 2) + 1 - String(a).length).join(n || 0) + a;
    };

    a.relativeLength = function (a, h) {
      return /%$/.test(a) ? h * parseFloat(a) / 100 : parseFloat(a);
    };

    a.wrap = function (a, h, n) {
      var t = a[h];

      a[h] = function () {
        var a = Array.prototype.slice.call(arguments),
            k = arguments,
            e = this || _global;

        e.proceed = function () {
          t.apply(e, arguments.length ? arguments : k);
        };

        a.unshift(t);
        a = n.apply(this || _global, a);
        e.proceed = null;
        return a;
      };
    };

    a.getTZOffset = function (u) {
      var h = a.Date;
      return 60000 * (h.hcGetTimezoneOffset && h.hcGetTimezoneOffset(u) || h.hcTimezoneOffset || 0);
    };

    a.dateFormat = function (u, h, n) {
      if (!a.defined(h) || isNaN(h)) return a.defaultOptions.lang.invalidDate || "";
      u = a.pick(u, "%Y-%m-%d %H:%M:%S");
      var t = a.Date,
          l = new t(h - a.getTZOffset(h)),
          k,
          e = l[t.hcGetHours](),
          c = l[t.hcGetDay](),
          p = l[t.hcGetDate](),
          H = l[t.hcGetMonth](),
          b = l[t.hcGetFullYear](),
          m = a.defaultOptions.lang,
          d = m.weekdays,
          r = m.shortWeekdays,
          A = a.pad,
          t = a.extend({
        a: r ? r[c] : d[c].substr(0, 3),
        A: d[c],
        d: A(p),
        e: A(p, 2, " "),
        w: c,
        b: m.shortMonths[H],
        B: m.months[H],
        m: A(H + 1),
        y: b.toString().substr(2, 2),
        Y: b,
        H: A(e),
        k: e,
        I: A(e % 12 || 12),
        l: e % 12 || 12,
        M: A(l[t.hcGetMinutes]()),
        p: 12 > e ? "AM" : "PM",
        P: 12 > e ? "am" : "pm",
        S: A(l.getSeconds()),
        L: A(Math.round(h % 1000), 3)
      }, a.dateFormats);

      for (k in t) for (; -1 !== u.indexOf("%" + k);) u = u.replace("%" + k, "function" === typeof t[k] ? t[k](h) : t[k]);

      return n ? u.substr(0, 1).toUpperCase() + u.substr(1) : u;
    };

    a.formatSingle = function (u, h) {
      var n = /\.([0-9])/,
          t = a.defaultOptions.lang;
      /f$/.test(u) ? (n = (n = u.match(n)) ? n[1] : -1, null !== h && (h = a.numberFormat(h, n, t.decimalPoint, -1 < u.indexOf(",") ? t.thousandsSep : ""))) : h = a.dateFormat(u, h);
      return h;
    };

    a.format = function (u, h) {
      for (var n = "{", t = !1, l, k, e, c, p = [], H; u;) {
        n = u.indexOf(n);
        if (-1 === n) break;
        l = u.slice(0, n);

        if (t) {
          l = l.split(":");
          k = l.shift().split(".");
          c = k.length;
          H = h;

          for (e = 0; e < c; e++) H = H[k[e]];

          l.length && (H = a.formatSingle(l.join(":"), H));
          p.push(H);
        } else p.push(l);

        u = u.slice(n + 1);
        n = (t = !t) ? "}" : "{";
      }

      p.push(u);
      return p.join("");
    };

    a.getMagnitude = function (a) {
      return Math.pow(10, Math.floor(Math.log(a) / Math.LN10));
    };

    a.normalizeTickInterval = function (u, h, n, t, l) {
      var k,
          e = u;
      n = a.pick(n, 1);
      k = u / n;
      h || (h = l ? [1, 1.2, 1.5, 2, 2.5, 3, 4, 5, 6, 8, 10] : [1, 2, 2.5, 5, 10], !1 === t && (1 === n ? h = a.grep(h, function (a) {
        return 0 === a % 1;
      }) : 0.1 >= n && (h = [1 / n])));

      for (t = 0; t < h.length && !(e = h[t], l && e * n >= u || !l && k <= (h[t] + (h[t + 1] || h[t])) / 2); t++);

      return e = a.correctFloat(e * n, -Math.round(Math.log(0.001) / Math.LN10));
    };

    a.stableSort = function (a, h) {
      var n = a.length,
          t,
          l;

      for (l = 0; l < n; l++) a[l].safeI = l;

      a.sort(function (a, e) {
        t = h(a, e);
        return 0 === t ? a.safeI - e.safeI : t;
      });

      for (l = 0; l < n; l++) delete a[l].safeI;
    };

    a.arrayMin = function (a) {
      for (var h = a.length, n = a[0]; h--;) a[h] < n && (n = a[h]);

      return n;
    };

    a.arrayMax = function (a) {
      for (var h = a.length, n = a[0]; h--;) a[h] > n && (n = a[h]);

      return n;
    };

    a.destroyObjectProperties = function (a, h) {
      for (var n in a) a[n] && a[n] !== h && a[n].destroy && a[n].destroy(), delete a[n];
    };

    a.discardElement = function (u) {
      var h = a.garbageBin;
      h || (h = a.createElement("div"));
      u && h.appendChild(u);
      h.innerHTML = "";
    };

    a.correctFloat = function (a, h) {
      return parseFloat(a.toPrecision(h || 14));
    };

    a.setAnimation = function (u, h) {
      h.renderer.globalAnimation = a.pick(u, h.options.chart.animation, !0);
    };

    a.animObject = function (u) {
      return a.isObject(u) ? a.merge(u) : {
        duration: u ? 500 : 0
      };
    };

    a.timeUnits = {
      millisecond: 1,
      second: 1000,
      minute: 60000,
      hour: 3600000,
      day: 86400000,
      week: 604800000,
      month: 2419200000,
      year: 31449600000
    };

    a.numberFormat = function (u, h, n, t) {
      u = +u || 0;
      h = +h;
      var l = a.defaultOptions.lang,
          k = (u.toString().split(".")[1] || "").length,
          e,
          c;
      -1 === h ? h = Math.min(k, 20) : a.isNumber(h) || (h = 2);
      c = (Math.abs(u) + Math.pow(10, -Math.max(h, k) - 1)).toFixed(h);
      k = String(a.pInt(c));
      e = 3 < k.length ? k.length % 3 : 0;
      n = a.pick(n, l.decimalPoint);
      t = a.pick(t, l.thousandsSep);
      u = (0 > u ? "-" : "") + (e ? k.substr(0, e) + t : "");
      u += k.substr(e).replace(/(\d{3})(?=\d)/g, "$1" + t);
      h && (u += n + c.slice(-h));
      return u;
    };

    Math.easeInOutSine = function (a) {
      return -0.5 * (Math.cos(Math.PI * a) - 1);
    };

    a.getStyle = function (u, h) {
      return "width" === h ? Math.min(u.offsetWidth, u.scrollWidth) - a.getStyle(u, "padding-left") - a.getStyle(u, "padding-right") : "height" === h ? Math.min(u.offsetHeight, u.scrollHeight) - a.getStyle(u, "padding-top") - a.getStyle(u, "padding-bottom") : (u = E.getComputedStyle(u, void 0)) && a.pInt(u.getPropertyValue(h));
    };

    a.inArray = function (a, h) {
      return h.indexOf ? h.indexOf(a) : [].indexOf.call(h, a);
    };

    a.grep = function (a, h) {
      return [].filter.call(a, h);
    };

    a.find = function (a, h) {
      return [].find.call(a, h);
    };

    a.map = function (a, h) {
      for (var n = [], t = 0, l = a.length; t < l; t++) n[t] = h.call(a[t], a[t], t, a);

      return n;
    };

    a.offset = function (a) {
      var h = C.documentElement;
      a = a.getBoundingClientRect();
      return {
        top: a.top + (E.pageYOffset || h.scrollTop) - (h.clientTop || 0),
        left: a.left + (E.pageXOffset || h.scrollLeft) - (h.clientLeft || 0)
      };
    };

    a.stop = function (a, h) {
      for (var n = B.length; n--;) B[n].elem !== a || h && h !== B[n].prop || (B[n].stopped = !0);
    };

    a.each = function (a, h, n) {
      return Array.prototype.forEach.call(a, h, n);
    };

    a.addEvent = function (u, h, n) {
      function t(a) {
        a.target = a.srcElement || E;
        n.call(u, a);
      }

      var l = u.hcEvents = u.hcEvents || {};
      u.addEventListener ? u.addEventListener(h, n, !1) : u.attachEvent && (u.hcEventsIE || (u.hcEventsIE = {}), u.hcEventsIE[n.toString()] = t, u.attachEvent("on" + h, t));
      l[h] || (l[h] = []);
      l[h].push(n);
      return function () {
        a.removeEvent(u, h, n);
      };
    };

    a.removeEvent = function (u, h, n) {
      function t(a, c) {
        u.removeEventListener ? u.removeEventListener(a, c, !1) : u.attachEvent && (c = u.hcEventsIE[c.toString()], u.detachEvent("on" + a, c));
      }

      function l() {
        var a, c;
        if (u.nodeName) for (c in h ? (a = {}, a[h] = !0) : a = e, a) if (e[c]) for (a = e[c].length; a--;) t(c, e[c][a]);
      }

      var k,
          e = u.hcEvents,
          c;
      e && (h ? (k = e[h] || [], n ? (c = a.inArray(n, k), -1 < c && (k.splice(c, 1), e[h] = k), t(h, n)) : (l(), e[h] = [])) : (l(), u.hcEvents = {}));
    };

    a.fireEvent = function (u, h, n, t) {
      var l;
      l = u.hcEvents;
      var k, e;
      n = n || {};
      if (C.createEvent && (u.dispatchEvent || u.fireEvent)) l = C.createEvent("Events"), l.initEvent(h, !0, !0), a.extend(l, n), u.dispatchEvent ? u.dispatchEvent(l) : u.fireEvent(h, l);else if (l) for (l = l[h] || [], k = l.length, n.target || a.extend(n, {
        preventDefault: function () {
          n.defaultPrevented = !0;
        },
        target: u,
        type: h
      }), h = 0; h < k; h++) (e = l[h]) && !1 === e.call(u, n) && n.preventDefault();
      t && !n.defaultPrevented && t(n);
    };

    a.animate = function (u, h, n) {
      var t,
          l = "",
          k,
          e,
          c;
      a.isObject(n) || (t = arguments, n = {
        duration: t[2],
        easing: t[3],
        complete: t[4]
      });
      a.isNumber(n.duration) || (n.duration = 400);
      n.easing = "function" === typeof n.easing ? n.easing : Math[n.easing] || Math.easeInOutSine;
      n.curAnim = a.merge(h);

      for (c in h) a.stop(u, c), e = new a.Fx(u, n, c), k = null, "d" === c ? (e.paths = e.initPath(u, u.d, h.d), e.toD = h.d, t = 0, k = 1) : u.attr ? t = u.attr(c) : (t = parseFloat(a.getStyle(u, c)) || 0, "opacity" !== c && (l = "px")), k || (k = h[c]), k && k.match && k.match("px") && (k = k.replace(/px/g, "")), e.run(t, k, l);
    };

    a.seriesType = function (u, h, n, t, l) {
      var k = a.getOptions(),
          e = a.seriesTypes;
      k.plotOptions[u] = a.merge(k.plotOptions[h], n);
      e[u] = a.extendClass(e[h] || function () {}, t);
      e[u].prototype.type = u;
      l && (e[u].prototype.pointClass = a.extendClass(a.Point, l));
      return e[u];
    };

    a.uniqueKey = function () {
      var a = Math.random().toString(36).substring(2, 9),
          h = 0;
      return function () {
        return "highcharts-" + a + "-" + h++;
      };
    }();

    E.jQuery && (E.jQuery.fn.highcharts = function () {
      var u = [].slice.call(arguments);
      if ((this || _global)[0]) return u[0] ? (new a[a.isString(u[0]) ? u.shift() : "Chart"]((this || _global)[0], u[0], u[1]), this || _global) : z[a.attr((this || _global)[0], "data-highcharts-chart")];
    });
    C && !C.defaultView && (a.getStyle = function (u, h) {
      var n = {
        width: "clientWidth",
        height: "clientHeight"
      }[h];
      if (u.style[h]) return a.pInt(u.style[h]);
      "opacity" === h && (h = "filter");
      if (n) return u.style.zoom = 1, Math.max(u[n] - 2 * a.getStyle(u, "padding"), 0);
      u = u.currentStyle[h.replace(/\-(\w)/g, function (a, l) {
        return l.toUpperCase();
      })];
      "filter" === h && (u = u.replace(/alpha\(opacity=([0-9]+)\)/, function (a, l) {
        return l / 100;
      }));
      return "" === u ? 1 : a.pInt(u);
    });
    Array.prototype.forEach || (a.each = function (a, h, n) {
      for (var t = 0, l = a.length; t < l; t++) if (!1 === h.call(n, a[t], t, a)) return t;
    });
    Array.prototype.indexOf || (a.inArray = function (a, h) {
      var n,
          t = 0;
      if (h) for (n = h.length; t < n; t++) if (h[t] === a) return t;
      return -1;
    });
    Array.prototype.filter || (a.grep = function (a, h) {
      for (var n = [], t = 0, l = a.length; t < l; t++) h(a[t], t) && n.push(a[t]);

      return n;
    });
    Array.prototype.find || (a.find = function (a, h) {
      var n,
          t = a.length;

      for (n = 0; n < t; n++) if (h(a[n], n)) return a[n];
    });
  })(I);

  (function (a) {
    var B = a.each,
        z = a.isNumber,
        C = a.map,
        E = a.merge,
        u = a.pInt;

    a.Color = function (h) {
      if (!((this || _global) instanceof a.Color)) return new a.Color(h);
      this.init(h);
    };

    a.Color.prototype = {
      parsers: [{
        regex: /rgba\(\s*([0-9]{1,3})\s*,\s*([0-9]{1,3})\s*,\s*([0-9]{1,3})\s*,\s*([0-9]?(?:\.[0-9]+)?)\s*\)/,
        parse: function (a) {
          return [u(a[1]), u(a[2]), u(a[3]), parseFloat(a[4], 10)];
        }
      }, {
        regex: /rgb\(\s*([0-9]{1,3})\s*,\s*([0-9]{1,3})\s*,\s*([0-9]{1,3})\s*\)/,
        parse: function (a) {
          return [u(a[1]), u(a[2]), u(a[3]), 1];
        }
      }],
      names: {
        white: "#ffffff",
        black: "#000000"
      },
      init: function (h) {
        var n, t, l, k;
        if (((this || _global).input = h = (this || _global).names[h] || h) && h.stops) (this || _global).stops = C(h.stops, function (e) {
          return new a.Color(e[1]);
        });else if (h && "#" === h[0] && (n = h.length, h = parseInt(h.substr(1), 16), 7 === n ? t = [(h & 16711680) >> 16, (h & 65280) >> 8, h & 255, 1] : 4 === n && (t = [(h & 3840) >> 4 | (h & 3840) >> 8, (h & 240) >> 4 | h & 240, (h & 15) << 4 | h & 15, 1])), !t) for (l = (this || _global).parsers.length; l-- && !t;) k = (this || _global).parsers[l], (n = k.regex.exec(h)) && (t = k.parse(n));
        (this || _global).rgba = t || [];
      },
      get: function (a) {
        var h = (this || _global).input,
            t = (this || _global).rgba,
            l;
        (this || _global).stops ? (l = E(h), l.stops = [].concat(l.stops), B((this || _global).stops, function (k, e) {
          l.stops[e] = [l.stops[e][0], k.get(a)];
        })) : l = t && z(t[0]) ? "rgb" === a || !a && 1 === t[3] ? "rgb(" + t[0] + "," + t[1] + "," + t[2] + ")" : "a" === a ? t[3] : "rgba(" + t.join(",") + ")" : h;
        return l;
      },
      brighten: function (a) {
        var h,
            t = (this || _global).rgba;
        if ((this || _global).stops) B((this || _global).stops, function (l) {
          l.brighten(a);
        });else if (z(a) && 0 !== a) for (h = 0; 3 > h; h++) t[h] += u(255 * a), 0 > t[h] && (t[h] = 0), 255 < t[h] && (t[h] = 255);
        return this || _global;
      },
      setOpacity: function (a) {
        (this || _global).rgba[3] = a;
        return this || _global;
      }
    };

    a.color = function (h) {
      return new a.Color(h);
    };
  })(I);

  (function (a) {
    var B,
        z,
        C = a.addEvent,
        E = a.animate,
        u = a.attr,
        h = a.charts,
        n = a.color,
        t = a.css,
        l = a.createElement,
        k = a.defined,
        e = a.deg2rad,
        c = a.destroyObjectProperties,
        p = a.doc,
        H = a.each,
        b = a.extend,
        m = a.erase,
        d = a.grep,
        r = a.hasTouch,
        A = a.inArray,
        v = a.isArray,
        f = a.isFirefox,
        y = a.isMS,
        G = a.isObject,
        F = a.isString,
        q = a.isWebKit,
        x = a.merge,
        J = a.noop,
        K = a.pick,
        L = a.pInt,
        g = a.removeEvent,
        D = a.stop,
        S = a.svg,
        M = a.SVG_NS,
        R = a.symbolSizes,
        N = a.win;

    B = a.SVGElement = function () {
      return this || _global;
    };

    B.prototype = {
      opacity: 1,
      SVG_NS: M,
      textProps: "direction fontSize fontWeight fontFamily fontStyle color lineHeight width textAlign textDecoration textOverflow textOutline".split(" "),
      init: function (a, g) {
        (this || _global).element = "span" === g ? l(g) : p.createElementNS((this || _global).SVG_NS, g);
        (this || _global).renderer = a;
      },
      animate: function (w, g, b) {
        g = a.animObject(K(g, (this || _global).renderer.globalAnimation, !0));
        0 !== g.duration ? (b && (g.complete = b), E(this || _global, w, g)) : (this.attr(w, null, b), g.step && g.step.call(this || _global));
        return this || _global;
      },
      colorGradient: function (w, g, b) {
        var f = (this || _global).renderer,
            d,
            c,
            q,
            D,
            O,
            y,
            r,
            G,
            e,
            m,
            p,
            l = [],
            Q;
        w.linearGradient ? c = "linearGradient" : w.radialGradient && (c = "radialGradient");

        if (c) {
          q = w[c];
          O = f.gradients;
          r = w.stops;
          m = b.radialReference;
          v(q) && (w[c] = q = {
            x1: q[0],
            y1: q[1],
            x2: q[2],
            y2: q[3],
            gradientUnits: "userSpaceOnUse"
          });
          "radialGradient" === c && m && !k(q.gradientUnits) && (D = q, q = x(q, f.getRadialAttr(m, D), {
            gradientUnits: "userSpaceOnUse"
          }));

          for (p in q) "id" !== p && l.push(p, q[p]);

          for (p in r) l.push(r[p]);

          l = l.join(",");
          O[l] ? m = O[l].attr("id") : (q.id = m = a.uniqueKey(), O[l] = y = f.createElement(c).attr(q).add(f.defs), y.radAttr = D, y.stops = [], H(r, function (w) {
            0 === w[1].indexOf("rgba") ? (d = a.color(w[1]), G = d.get("rgb"), e = d.get("a")) : (G = w[1], e = 1);
            w = f.createElement("stop").attr({
              offset: w[0],
              "stop-color": G,
              "stop-opacity": e
            }).add(y);
            y.stops.push(w);
          }));
          Q = "url(" + f.url + "#" + m + ")";
          b.setAttribute(g, Q);
          b.gradient = l;

          w.toString = function () {
            return Q;
          };
        }
      },
      applyTextOutline: function (a) {
        var w = (this || _global).element,
            g,
            b,
            f,
            d;
        -1 !== a.indexOf("contrast") && (a = a.replace(/contrast/g, (this || _global).renderer.getContrast(w.style.fill)));
        (this || _global).fakeTS = !0;
        (this || _global).ySetter = (this || _global).xSetter;
        g = [].slice.call(w.getElementsByTagName("tspan"));
        a = a.split(" ");
        b = a[a.length - 1];
        (f = a[0]) && "none" !== f && (f = f.replace(/(^[\d\.]+)(.*?)$/g, function (a, w, g) {
          return 2 * w + g;
        }), H(g, function (a) {
          "highcharts-text-outline" === a.getAttribute("class") && m(g, w.removeChild(a));
        }), d = w.firstChild, H(g, function (a, g) {
          0 === g && (a.setAttribute("x", w.getAttribute("x")), g = w.getAttribute("y"), a.setAttribute("y", g || 0), null === g && w.setAttribute("y", 0));
          a = a.cloneNode(1);
          u(a, {
            "class": "highcharts-text-outline",
            fill: b,
            stroke: b,
            "stroke-width": f,
            "stroke-linejoin": "round"
          });
          w.insertBefore(a, d);
        }));
      },
      attr: function (a, g, b, f) {
        var w,
            d = (this || _global).element,
            q,
            c = this || _global,
            y;
        "string" === typeof a && void 0 !== g && (w = a, a = {}, a[w] = g);
        if ("string" === typeof a) c = ((this || _global)[a + "Getter"] || (this || _global)._defaultGetter).call(this || _global, a, d);else {
          for (w in a) g = a[w], y = !1, f || D(this || _global, w), (this || _global).symbolName && /^(x|y|width|height|r|start|end|innerR|anchorX|anchorY)/.test(w) && (q || (this.symbolAttr(a), q = !0), y = !0), !(this || _global).rotation || "x" !== w && "y" !== w || ((this || _global).doTransform = !0), y || (y = (this || _global)[w + "Setter"] || (this || _global)._defaultSetter, y.call(this || _global, g, w, d), (this || _global).shadows && /^(width|height|visibility|x|y|d|transform|cx|cy|r)$/.test(w) && this.updateShadows(w, g, y));

          (this || _global).doTransform && (this.updateTransform(), (this || _global).doTransform = !1);
        }
        b && b();
        return c;
      },
      updateShadows: function (a, g, b) {
        for (var w = (this || _global).shadows, f = w.length; f--;) b.call(w[f], "height" === a ? Math.max(g - (w[f].cutHeight || 0), 0) : "d" === a ? (this || _global).d : g, a, w[f]);
      },
      addClass: function (a, g) {
        var w = this.attr("class") || "";
        -1 === w.indexOf(a) && (g || (a = (w + (w ? " " : "") + a).replace("  ", " ")), this.attr("class", a));
        return this || _global;
      },
      hasClass: function (a) {
        return -1 !== u((this || _global).element, "class").indexOf(a);
      },
      removeClass: function (a) {
        u((this || _global).element, "class", (u((this || _global).element, "class") || "").replace(a, ""));
        return this || _global;
      },
      symbolAttr: function (a) {
        var w = this || _global;
        H("x y r start end width height innerR anchorX anchorY".split(" "), function (g) {
          w[g] = K(a[g], w[g]);
        });
        w.attr({
          d: w.renderer.symbols[w.symbolName](w.x, w.y, w.width, w.height, w)
        });
      },
      clip: function (a) {
        return this.attr("clip-path", a ? "url(" + (this || _global).renderer.url + "#" + a.id + ")" : "none");
      },
      crisp: function (a, g) {
        var w,
            f = {},
            b;
        g = g || a.strokeWidth || 0;
        b = Math.round(g) % 2 / 2;
        a.x = Math.floor(a.x || (this || _global).x || 0) + b;
        a.y = Math.floor(a.y || (this || _global).y || 0) + b;
        a.width = Math.floor((a.width || (this || _global).width || 0) - 2 * b);
        a.height = Math.floor((a.height || (this || _global).height || 0) - 2 * b);
        k(a.strokeWidth) && (a.strokeWidth = g);

        for (w in a) (this || _global)[w] !== a[w] && ((this || _global)[w] = f[w] = a[w]);

        return f;
      },
      css: function (a) {
        var w = (this || _global).styles,
            g = {},
            f = (this || _global).element,
            d,
            q = "",
            c = !w,
            D = ["textOverflow", "width"];
        a && a.color && (a.fill = a.color);
        if (w) for (d in a) a[d] !== w[d] && (g[d] = a[d], c = !0);

        if (c) {
          w && (a = b(w, g));
          w = (this || _global).textWidth = a && a.width && "auto" !== a.width && "text" === f.nodeName.toLowerCase() && L(a.width);
          (this || _global).styles = a;
          w && !S && (this || _global).renderer.forExport && delete a.width;
          if (y && !S) t((this || _global).element, a);else {
            w = function (a, w) {
              return "-" + w.toLowerCase();
            };

            for (d in a) -1 === A(d, D) && (q += d.replace(/([A-Z])/g, w) + ":" + a[d] + ";");

            q && u(f, "style", q);
          }
          (this || _global).added && ("text" === (this || _global).element.nodeName && (this || _global).renderer.buildText(this || _global), a && a.textOutline && this.applyTextOutline(a.textOutline));
        }

        return this || _global;
      },
      strokeWidth: function () {
        return (this || _global)["stroke-width"] || 0;
      },
      on: function (a, g) {
        var w = this || _global,
            f = w.element;
        r && "click" === a ? (f.ontouchstart = function (a) {
          w.touchEventFired = Date.now();
          a.preventDefault();
          g.call(f, a);
        }, f.onclick = function (a) {
          (-1 === N.navigator.userAgent.indexOf("Android") || 1100 < Date.now() - (w.touchEventFired || 0)) && g.call(f, a);
        }) : f["on" + a] = g;
        return this || _global;
      },
      setRadialReference: function (a) {
        var w = (this || _global).renderer.gradients[(this || _global).element.gradient];
        (this || _global).element.radialReference = a;
        w && w.radAttr && w.animate((this || _global).renderer.getRadialAttr(a, w.radAttr));
        return this || _global;
      },
      translate: function (a, g) {
        return this.attr({
          translateX: a,
          translateY: g
        });
      },
      invert: function (a) {
        (this || _global).inverted = a;
        this.updateTransform();
        return this || _global;
      },
      updateTransform: function () {
        var a = (this || _global).translateX || 0,
            g = (this || _global).translateY || 0,
            f = (this || _global).scaleX,
            b = (this || _global).scaleY,
            d = (this || _global).inverted,
            q = (this || _global).rotation,
            c = (this || _global).element;
        d && (a += (this || _global).width, g += (this || _global).height);
        a = ["translate(" + a + "," + g + ")"];
        d ? a.push("rotate(90) scale(-1,1)") : q && a.push("rotate(" + q + " " + (c.getAttribute("x") || 0) + " " + (c.getAttribute("y") || 0) + ")");
        (k(f) || k(b)) && a.push("scale(" + K(f, 1) + " " + K(b, 1) + ")");
        a.length && c.setAttribute("transform", a.join(" "));
      },
      toFront: function () {
        var a = (this || _global).element;
        a.parentNode.appendChild(a);
        return this || _global;
      },
      align: function (a, g, f) {
        var w,
            b,
            d,
            q,
            c = {};
        b = (this || _global).renderer;
        d = b.alignedObjects;
        var D, y;

        if (a) {
          if ((this || _global).alignOptions = a, (this || _global).alignByTranslate = g, !f || F(f)) (this || _global).alignTo = w = f || "renderer", m(d, this || _global), d.push(this || _global), f = null;
        } else a = (this || _global).alignOptions, g = (this || _global).alignByTranslate, w = (this || _global).alignTo;

        f = K(f, b[w], b);
        w = a.align;
        b = a.verticalAlign;
        d = (f.x || 0) + (a.x || 0);
        q = (f.y || 0) + (a.y || 0);
        "right" === w ? D = 1 : "center" === w && (D = 2);
        D && (d += (f.width - (a.width || 0)) / D);
        c[g ? "translateX" : "x"] = Math.round(d);
        "bottom" === b ? y = 1 : "middle" === b && (y = 2);
        y && (q += (f.height - (a.height || 0)) / y);
        c[g ? "translateY" : "y"] = Math.round(q);
        this[(this || _global).placed ? "animate" : "attr"](c);
        (this || _global).placed = !0;
        (this || _global).alignAttr = c;
        return this || _global;
      },
      getBBox: function (a, g) {
        var w,
            f = (this || _global).renderer,
            d,
            q = (this || _global).element,
            c = (this || _global).styles,
            D,
            y = (this || _global).textStr,
            x,
            r = f.cache,
            G = f.cacheKeys,
            m;
        g = K(g, (this || _global).rotation);
        d = g * e;
        D = c && c.fontSize;
        void 0 !== y && (m = y.toString(), -1 === m.indexOf("<") && (m = m.replace(/[0-9]/g, "0")), m += ["", g || 0, D, c && c.width, c && c.textOverflow].join());
        m && !a && (w = r[m]);

        if (!w) {
          if (q.namespaceURI === (this || _global).SVG_NS || f.forExport) {
            try {
              (x = (this || _global).fakeTS && function (a) {
                H(q.querySelectorAll(".highcharts-text-outline"), function (w) {
                  w.style.display = a;
                });
              }) && x("none"), w = q.getBBox ? b({}, q.getBBox()) : {
                width: q.offsetWidth,
                height: q.offsetHeight
              }, x && x("");
            } catch (X) {}

            if (!w || 0 > w.width) w = {
              width: 0,
              height: 0
            };
          } else w = this.htmlGetBBox();

          f.isSVG && (a = w.width, f = w.height, c && "11px" === c.fontSize && 17 === Math.round(f) && (w.height = f = 14), g && (w.width = Math.abs(f * Math.sin(d)) + Math.abs(a * Math.cos(d)), w.height = Math.abs(f * Math.cos(d)) + Math.abs(a * Math.sin(d))));

          if (m && 0 < w.height) {
            for (; 250 < G.length;) delete r[G.shift()];

            r[m] || G.push(m);
            r[m] = w;
          }
        }

        return w;
      },
      show: function (a) {
        return this.attr({
          visibility: a ? "inherit" : "visible"
        });
      },
      hide: function () {
        return this.attr({
          visibility: "hidden"
        });
      },
      fadeOut: function (a) {
        var w = this || _global;
        w.animate({
          opacity: 0
        }, {
          duration: a || 150,
          complete: function () {
            w.attr({
              y: -9999
            });
          }
        });
      },
      add: function (a) {
        var w = (this || _global).renderer,
            g = (this || _global).element,
            f;
        a && ((this || _global).parentGroup = a);
        (this || _global).parentInverted = a && a.inverted;
        void 0 !== (this || _global).textStr && w.buildText(this || _global);
        (this || _global).added = !0;
        if (!a || a.handleZ || (this || _global).zIndex) f = this.zIndexSetter();
        f || (a ? a.element : w.box).appendChild(g);
        if ((this || _global).onAdd) this.onAdd();
        return this || _global;
      },
      safeRemoveChild: function (a) {
        var w = a.parentNode;
        w && w.removeChild(a);
      },
      destroy: function () {
        var a = (this || _global).element || {},
            g = (this || _global).renderer.isSVG && "SPAN" === a.nodeName && (this || _global).parentGroup,
            f,
            b;
        a.onclick = a.onmouseout = a.onmouseover = a.onmousemove = a.point = null;
        D(this || _global);
        (this || _global).clipPath && ((this || _global).clipPath = (this || _global).clipPath.destroy());

        if ((this || _global).stops) {
          for (b = 0; b < (this || _global).stops.length; b++) (this || _global).stops[b] = (this || _global).stops[b].destroy();

          (this || _global).stops = null;
        }

        this.safeRemoveChild(a);

        for (this.destroyShadows(); g && g.div && 0 === g.div.childNodes.length;) a = g.parentGroup, this.safeRemoveChild(g.div), delete g.div, g = a;

        (this || _global).alignTo && m((this || _global).renderer.alignedObjects, this || _global);

        for (f in this || _global) delete (this || _global)[f];

        return null;
      },
      shadow: function (a, g, f) {
        var w = [],
            b,
            d,
            c = (this || _global).element,
            q,
            D,
            y,
            x;
        if (!a) this.destroyShadows();else if (!(this || _global).shadows) {
          D = K(a.width, 3);
          y = (a.opacity || 0.15) / D;
          x = (this || _global).parentInverted ? "(-1,-1)" : "(" + K(a.offsetX, 1) + ", " + K(a.offsetY, 1) + ")";

          for (b = 1; b <= D; b++) d = c.cloneNode(0), q = 2 * D + 1 - 2 * b, u(d, {
            isShadow: "true",
            stroke: a.color || "#000000",
            "stroke-opacity": y * b,
            "stroke-width": q,
            transform: "translate" + x,
            fill: "none"
          }), f && (u(d, "height", Math.max(u(d, "height") - q, 0)), d.cutHeight = q), g ? g.element.appendChild(d) : c.parentNode.insertBefore(d, c), w.push(d);

          (this || _global).shadows = w;
        }
        return this || _global;
      },
      destroyShadows: function () {
        H((this || _global).shadows || [], function (a) {
          this.safeRemoveChild(a);
        }, this || _global);
        (this || _global).shadows = void 0;
      },
      xGetter: function (a) {
        "circle" === (this || _global).element.nodeName && ("x" === a ? a = "cx" : "y" === a && (a = "cy"));
        return this._defaultGetter(a);
      },
      _defaultGetter: function (a) {
        a = K((this || _global)[a], (this || _global).element ? (this || _global).element.getAttribute(a) : null, 0);
        /^[\-0-9\.]+$/.test(a) && (a = parseFloat(a));
        return a;
      },
      dSetter: function (a, g, f) {
        a && a.join && (a = a.join(" "));
        /(NaN| {2}|^$)/.test(a) && (a = "M 0 0");
        f.setAttribute(g, a);
        (this || _global)[g] = a;
      },
      dashstyleSetter: function (a) {
        var w,
            g = (this || _global)["stroke-width"];
        "inherit" === g && (g = 1);

        if (a = a && a.toLowerCase()) {
          a = a.replace("shortdashdotdot", "3,1,1,1,1,1,").replace("shortdashdot", "3,1,1,1").replace("shortdot", "1,1,").replace("shortdash", "3,1,").replace("longdash", "8,3,").replace(/dot/g, "1,3,").replace("dash", "4,3,").replace(/,$/, "").split(",");

          for (w = a.length; w--;) a[w] = L(a[w]) * g;

          a = a.join(",").replace(/NaN/g, "none");

          (this || _global).element.setAttribute("stroke-dasharray", a);
        }
      },
      alignSetter: function (a) {
        (this || _global).element.setAttribute("text-anchor", {
          left: "start",
          center: "middle",
          right: "end"
        }[a]);
      },
      opacitySetter: function (a, g, f) {
        (this || _global)[g] = a;
        f.setAttribute(g, a);
      },
      titleSetter: function (a) {
        var w = (this || _global).element.getElementsByTagName("title")[0];

        w || (w = p.createElementNS((this || _global).SVG_NS, "title"), (this || _global).element.appendChild(w));
        w.firstChild && w.removeChild(w.firstChild);
        w.appendChild(p.createTextNode(String(K(a), "").replace(/<[^>]*>/g, "")));
      },
      textSetter: function (a) {
        a !== (this || _global).textStr && (delete (this || _global).bBox, (this || _global).textStr = a, (this || _global).added && (this || _global).renderer.buildText(this || _global));
      },
      fillSetter: function (a, g, f) {
        "string" === typeof a ? f.setAttribute(g, a) : a && this.colorGradient(a, g, f);
      },
      visibilitySetter: function (a, g, f) {
        "inherit" === a ? f.removeAttribute(g) : f.setAttribute(g, a);
      },
      zIndexSetter: function (a, g) {
        var w = (this || _global).renderer,
            f = (this || _global).parentGroup,
            b = (f || w).element || w.box,
            d,
            c = (this || _global).element,
            q;
        d = (this || _global).added;
        var D;
        k(a) && (c.zIndex = a, a = +a, (this || _global)[g] === a && (d = !1), (this || _global)[g] = a);

        if (d) {
          (a = (this || _global).zIndex) && f && (f.handleZ = !0);
          g = b.childNodes;

          for (D = 0; D < g.length && !q; D++) f = g[D], d = f.zIndex, f !== c && (L(d) > a || !k(a) && k(d) || 0 > a && !k(d) && b !== w.box) && (b.insertBefore(c, f), q = !0);

          q || b.appendChild(c);
        }

        return q;
      },
      _defaultSetter: function (a, g, f) {
        f.setAttribute(g, a);
      }
    };
    B.prototype.yGetter = B.prototype.xGetter;

    B.prototype.translateXSetter = B.prototype.translateYSetter = B.prototype.rotationSetter = B.prototype.verticalAlignSetter = B.prototype.scaleXSetter = B.prototype.scaleYSetter = function (a, g) {
      (this || _global)[g] = a;
      (this || _global).doTransform = !0;
    };

    B.prototype["stroke-widthSetter"] = B.prototype.strokeSetter = function (a, g, f) {
      (this || _global)[g] = a;
      (this || _global).stroke && (this || _global)["stroke-width"] ? (B.prototype.fillSetter.call(this || _global, (this || _global).stroke, "stroke", f), f.setAttribute("stroke-width", (this || _global)["stroke-width"]), (this || _global).hasStroke = !0) : "stroke-width" === g && 0 === a && (this || _global).hasStroke && (f.removeAttribute("stroke"), (this || _global).hasStroke = !1);
    };

    z = a.SVGRenderer = function () {
      (this || _global).init.apply(this || _global, arguments);
    };

    z.prototype = {
      Element: B,
      SVG_NS: M,
      init: function (a, g, b, d, c, D) {
        var w;
        d = this.createElement("svg").attr({
          version: "1.1",
          "class": "highcharts-root"
        }).css(this.getStyle(d));
        w = d.element;
        a.appendChild(w);
        -1 === a.innerHTML.indexOf("xmlns") && u(w, "xmlns", (this || _global).SVG_NS);
        (this || _global).isSVG = !0;
        (this || _global).box = w;
        (this || _global).boxWrapper = d;
        (this || _global).alignedObjects = [];
        (this || _global).url = (f || q) && p.getElementsByTagName("base").length ? N.location.href.replace(/#.*?$/, "").replace(/<[^>]*>/g, "").replace(/([\('\)])/g, "\\$1").replace(/ /g, "%20") : "";
        this.createElement("desc").add().element.appendChild(p.createTextNode("Created with Highcharts 5.0.9"));
        (this || _global).defs = this.createElement("defs").add();
        (this || _global).allowHTML = D;
        (this || _global).forExport = c;
        (this || _global).gradients = {};
        (this || _global).cache = {};
        (this || _global).cacheKeys = [];
        (this || _global).imgCount = 0;
        this.setSize(g, b, !1);
        var y;
        f && a.getBoundingClientRect && (g = function () {
          t(a, {
            left: 0,
            top: 0
          });
          y = a.getBoundingClientRect();
          t(a, {
            left: Math.ceil(y.left) - y.left + "px",
            top: Math.ceil(y.top) - y.top + "px"
          });
        }, g(), (this || _global).unSubPixelFix = C(N, "resize", g));
      },
      getStyle: function (a) {
        return (this || _global).style = b({
          fontFamily: "\"Lucida Grande\", \"Lucida Sans Unicode\", Arial, Helvetica, sans-serif",
          fontSize: "12px"
        }, a);
      },
      setStyle: function (a) {
        (this || _global).boxWrapper.css(this.getStyle(a));
      },
      isHidden: function () {
        return !(this || _global).boxWrapper.getBBox().width;
      },
      destroy: function () {
        var a = (this || _global).defs;
        (this || _global).box = null;
        (this || _global).boxWrapper = (this || _global).boxWrapper.destroy();
        c((this || _global).gradients || {});
        (this || _global).gradients = null;
        a && ((this || _global).defs = a.destroy());
        (this || _global).unSubPixelFix && this.unSubPixelFix();
        return (this || _global).alignedObjects = null;
      },
      createElement: function (a) {
        var g = new (this || _global).Element();
        g.init(this || _global, a);
        return g;
      },
      draw: J,
      getRadialAttr: function (a, g) {
        return {
          cx: a[0] - a[2] / 2 + g.cx * a[2],
          cy: a[1] - a[2] / 2 + g.cy * a[2],
          r: g.r * a[2]
        };
      },
      buildText: function (a) {
        var g = a.element,
            f = this || _global,
            w = f.forExport,
            b = K(a.textStr, "").toString(),
            c = -1 !== b.indexOf("<"),
            q = g.childNodes,
            D,
            y,
            x,
            r,
            G = u(g, "x"),
            m = a.styles,
            e = a.textWidth,
            l = m && m.lineHeight,
            v = m && m.textOutline,
            k = m && "ellipsis" === m.textOverflow,
            h = m && "nowrap" === m.whiteSpace,
            F = m && m.fontSize,
            A,
            J = q.length,
            m = e && !a.added && (this || _global).box,
            n = function (a) {
          var w;
          w = /(px|em)$/.test(a && a.style.fontSize) ? a.style.fontSize : F || f.style.fontSize || 12;
          return l ? L(l) : f.fontMetrics(w, a.getAttribute("style") ? a : g).h;
        };

        A = [b, k, h, l, v, F, e].join();

        if (A !== a.textCache) {
          for (a.textCache = A; J--;) g.removeChild(q[J]);

          c || v || k || e || -1 !== b.indexOf(" ") ? (D = /<.*class="([^"]+)".*>/, y = /<.*style="([^"]+)".*>/, x = /<.*href="(http[^"]+)".*>/, m && m.appendChild(g), b = c ? b.replace(/<(b|strong)>/g, "<span style=\"font-weight:bold\">").replace(/<(i|em)>/g, "<span style=\"font-style:italic\">").replace(/<a/g, "<span").replace(/<\/(b|strong|i|em|a)>/g, "</span>").split(/<br.*?>/g) : [b], b = d(b, function (a) {
            return "" !== a;
          }), H(b, function (b, d) {
            var c,
                q = 0;
            b = b.replace(/^\s+|\s+$/g, "").replace(/<span/g, "|||<span").replace(/<\/span>/g, "</span>|||");
            c = b.split("|||");
            H(c, function (b) {
              if ("" !== b || 1 === c.length) {
                var m = {},
                    v = p.createElementNS(f.SVG_NS, "tspan"),
                    l,
                    F;
                D.test(b) && (l = b.match(D)[1], u(v, "class", l));
                y.test(b) && (F = b.match(y)[1].replace(/(;| |^)color([ :])/, "$1fill$2"), u(v, "style", F));
                x.test(b) && !w && (u(v, "onclick", "location.href=\"" + b.match(x)[1] + "\""), t(v, {
                  cursor: "pointer"
                }));
                b = (b.replace(/<(.|\n)*?>/g, "") || " ").replace(/&lt;/g, "<").replace(/&gt;/g, ">");

                if (" " !== b) {
                  v.appendChild(p.createTextNode(b));
                  q ? m.dx = 0 : d && null !== G && (m.x = G);
                  u(v, m);
                  g.appendChild(v);
                  !q && d && (!S && w && t(v, {
                    display: "block"
                  }), u(v, "dy", n(v)));

                  if (e) {
                    m = b.replace(/([^\^])-/g, "$1- ").split(" ");
                    l = 1 < c.length || d || 1 < m.length && !h;

                    for (var A, H, O = [], J = n(v), K = a.rotation, L = b, P = L.length; (l || k) && (m.length || O.length);) a.rotation = 0, A = a.getBBox(!0), H = A.width, !S && f.forExport && (H = f.measureSpanWidth(v.firstChild.data, a.styles)), A = H > e, void 0 === r && (r = A), k && r ? (P /= 2, "" === L || !A && 0.5 > P ? m = [] : (L = b.substring(0, L.length + (A ? -1 : 1) * Math.ceil(P)), m = [L + (3 < e ? "\u2026" : "")], v.removeChild(v.firstChild))) : A && 1 !== m.length ? (v.removeChild(v.firstChild), O.unshift(m.pop())) : (m = O, O = [], m.length && !h && (v = p.createElementNS(M, "tspan"), u(v, {
                      dy: J,
                      x: G
                    }), F && u(v, "style", F), g.appendChild(v)), H > e && (e = H)), m.length && v.appendChild(p.createTextNode(m.join(" ").replace(/- /g, "-")));

                    a.rotation = K;
                  }

                  q++;
                }
              }
            });
          }), r && a.attr("title", a.textStr), m && m.removeChild(g), v && a.applyTextOutline && a.applyTextOutline(v)) : g.appendChild(p.createTextNode(b.replace(/&lt;/g, "<").replace(/&gt;/g, ">")));
        }
      },
      getContrast: function (a) {
        a = n(a).rgba;
        return 510 < a[0] + a[1] + a[2] ? "#000000" : "#FFFFFF";
      },
      button: function (a, g, f, d, c, q, D, m, r) {
        var w = this.label(a, g, f, r, null, null, null, null, "button"),
            G = 0;
        w.attr(x({
          padding: 8,
          r: 2
        }, c));
        var e, v, p, l;
        c = x({
          fill: "#f7f7f7",
          stroke: "#cccccc",
          "stroke-width": 1,
          style: {
            color: "#333333",
            cursor: "pointer",
            fontWeight: "normal"
          }
        }, c);
        e = c.style;
        delete c.style;
        q = x(c, {
          fill: "#e6e6e6"
        }, q);
        v = q.style;
        delete q.style;
        D = x(c, {
          fill: "#e6ebf5",
          style: {
            color: "#000000",
            fontWeight: "bold"
          }
        }, D);
        p = D.style;
        delete D.style;
        m = x(c, {
          style: {
            color: "#cccccc"
          }
        }, m);
        l = m.style;
        delete m.style;
        C(w.element, y ? "mouseover" : "mouseenter", function () {
          3 !== G && w.setState(1);
        });
        C(w.element, y ? "mouseout" : "mouseleave", function () {
          3 !== G && w.setState(G);
        });

        w.setState = function (a) {
          1 !== a && (w.state = G = a);
          w.removeClass(/highcharts-button-(normal|hover|pressed|disabled)/).addClass("highcharts-button-" + ["normal", "hover", "pressed", "disabled"][a || 0]);
          w.attr([c, q, D, m][a || 0]).css([e, v, p, l][a || 0]);
        };

        w.attr(c).css(b({
          cursor: "default"
        }, e));
        return w.on("click", function (a) {
          3 !== G && d.call(w, a);
        });
      },
      crispLine: function (a, g) {
        a[1] === a[4] && (a[1] = a[4] = Math.round(a[1]) - g % 2 / 2);
        a[2] === a[5] && (a[2] = a[5] = Math.round(a[2]) + g % 2 / 2);
        return a;
      },
      path: function (a) {
        var g = {
          fill: "none"
        };
        v(a) ? g.d = a : G(a) && b(g, a);
        return this.createElement("path").attr(g);
      },
      circle: function (a, g, f) {
        a = G(a) ? a : {
          x: a,
          y: g,
          r: f
        };
        g = this.createElement("circle");

        g.xSetter = g.ySetter = function (a, g, f) {
          f.setAttribute("c" + g, a);
        };

        return g.attr(a);
      },
      arc: function (a, g, f, b, d, c) {
        G(a) ? (b = a, g = b.y, f = b.r, a = b.x) : b = {
          innerR: b,
          start: d,
          end: c
        };
        a = this.symbol("arc", a, g, f, f, b);
        a.r = f;
        return a;
      },
      rect: function (a, g, f, b, d, c) {
        d = G(a) ? a.r : d;
        var w = this.createElement("rect");
        a = G(a) ? a : void 0 === a ? {} : {
          x: a,
          y: g,
          width: Math.max(f, 0),
          height: Math.max(b, 0)
        };
        void 0 !== c && (a.strokeWidth = c, a = w.crisp(a));
        a.fill = "none";
        d && (a.r = d);

        w.rSetter = function (a, g, f) {
          u(f, {
            rx: a,
            ry: a
          });
        };

        return w.attr(a);
      },
      setSize: function (a, g, f) {
        var b = (this || _global).alignedObjects,
            d = b.length;
        (this || _global).width = a;
        (this || _global).height = g;

        for ((this || _global).boxWrapper.animate({
          width: a,
          height: g
        }, {
          step: function () {
            this.attr({
              viewBox: "0 0 " + this.attr("width") + " " + this.attr("height")
            });
          },
          duration: K(f, !0) ? void 0 : 0
        }); d--;) b[d].align();
      },
      g: function (a) {
        var g = this.createElement("g");
        return a ? g.attr({
          "class": "highcharts-" + a
        }) : g;
      },
      image: function (a, g, f, d, c) {
        var w = {
          preserveAspectRatio: "none"
        };
        1 < arguments.length && b(w, {
          x: g,
          y: f,
          width: d,
          height: c
        });
        w = this.createElement("image").attr(w);
        w.element.setAttributeNS ? w.element.setAttributeNS("http://www.w3.org/1999/xlink", "href", a) : w.element.setAttribute("hc-svg-href", a);
        return w;
      },
      symbol: function (a, g, f, d, c, q) {
        var w = this || _global,
            D,
            y = (this || _global).symbols[a],
            m = k(g) && y && (this || _global).symbols[a](Math.round(g), Math.round(f), d, c, q),
            x = /^url\((.*?)\)$/,
            G,
            r;

        y ? (D = this.path(m), D.attr("fill", "none"), b(D, {
          symbolName: a,
          x: g,
          y: f,
          width: d,
          height: c
        }), q && b(D, q)) : x.test(a) && (G = a.match(x)[1], D = this.image(G), D.imgwidth = K(R[G] && R[G].width, q && q.width), D.imgheight = K(R[G] && R[G].height, q && q.height), r = function () {
          D.attr({
            width: D.width,
            height: D.height
          });
        }, H(["width", "height"], function (a) {
          D[a + "Setter"] = function (a, g) {
            var f = {},
                b = (this || _global)["img" + g],
                d = "width" === g ? "translateX" : "translateY";
            (this || _global)[g] = a;
            k(b) && ((this || _global).element && (this || _global).element.setAttribute(g, b), (this || _global).alignByTranslate || (f[d] = (((this || _global)[g] || 0) - b) / 2, this.attr(f)));
          };
        }), k(g) && D.attr({
          x: g,
          y: f
        }), D.isImg = !0, k(D.imgwidth) && k(D.imgheight) ? r() : (D.attr({
          width: 0,
          height: 0
        }), l("img", {
          onload: function () {
            var a = h[w.chartIndex];
            0 === (this || _global).width && (t(this || _global, {
              position: "absolute",
              top: "-999em"
            }), p.body.appendChild(this || _global));
            R[G] = {
              width: (this || _global).width,
              height: (this || _global).height
            };
            D.imgwidth = (this || _global).width;
            D.imgheight = (this || _global).height;
            D.element && r();
            (this || _global).parentNode && (this || _global).parentNode.removeChild(this || _global);
            w.imgCount--;
            if (!w.imgCount && a && a.onload) a.onload();
          },
          src: G
        }), (this || _global).imgCount++));
        return D;
      },
      symbols: {
        circle: function (a, g, f, b) {
          return this.arc(a + f / 2, g + b / 2, f / 2, b / 2, {
            start: 0,
            end: 2 * Math.PI,
            open: !1
          });
        },
        square: function (a, g, f, b) {
          return ["M", a, g, "L", a + f, g, a + f, g + b, a, g + b, "Z"];
        },
        triangle: function (a, g, f, b) {
          return ["M", a + f / 2, g, "L", a + f, g + b, a, g + b, "Z"];
        },
        "triangle-down": function (a, g, f, b) {
          return ["M", a, g, "L", a + f, g, a + f / 2, g + b, "Z"];
        },
        diamond: function (a, g, f, b) {
          return ["M", a + f / 2, g, "L", a + f, g + b / 2, a + f / 2, g + b, a, g + b / 2, "Z"];
        },
        arc: function (a, g, f, b, d) {
          var c = d.start,
              w = d.r || f,
              q = d.r || b || f,
              D = d.end - 0.001;
          f = d.innerR;
          b = d.open;
          var y = Math.cos(c),
              m = Math.sin(c),
              x = Math.cos(D),
              D = Math.sin(D);
          d = d.end - c < Math.PI ? 0 : 1;
          w = ["M", a + w * y, g + q * m, "A", w, q, 0, d, 1, a + w * x, g + q * D];
          k(f) && w.push(b ? "M" : "L", a + f * x, g + f * D, "A", f, f, 0, d, 0, a + f * y, g + f * m);
          w.push(b ? "" : "Z");
          return w;
        },
        callout: function (a, g, f, b, d) {
          var c = Math.min(d && d.r || 0, f, b),
              q = c + 6,
              D = d && d.anchorX;
          d = d && d.anchorY;
          var w;
          w = ["M", a + c, g, "L", a + f - c, g, "C", a + f, g, a + f, g, a + f, g + c, "L", a + f, g + b - c, "C", a + f, g + b, a + f, g + b, a + f - c, g + b, "L", a + c, g + b, "C", a, g + b, a, g + b, a, g + b - c, "L", a, g + c, "C", a, g, a, g, a + c, g];
          D && D > f ? d > g + q && d < g + b - q ? w.splice(13, 3, "L", a + f, d - 6, a + f + 6, d, a + f, d + 6, a + f, g + b - c) : w.splice(13, 3, "L", a + f, b / 2, D, d, a + f, b / 2, a + f, g + b - c) : D && 0 > D ? d > g + q && d < g + b - q ? w.splice(33, 3, "L", a, d + 6, a - 6, d, a, d - 6, a, g + c) : w.splice(33, 3, "L", a, b / 2, D, d, a, b / 2, a, g + c) : d && d > b && D > a + q && D < a + f - q ? w.splice(23, 3, "L", D + 6, g + b, D, g + b + 6, D - 6, g + b, a + c, g + b) : d && 0 > d && D > a + q && D < a + f - q && w.splice(3, 3, "L", D - 6, g, D, g - 6, D + 6, g, f - c, g);
          return w;
        }
      },
      clipRect: function (g, f, b, d) {
        var c = a.uniqueKey(),
            q = this.createElement("clipPath").attr({
          id: c
        }).add((this || _global).defs);
        g = this.rect(g, f, b, d, 0).add(q);
        g.id = c;
        g.clipPath = q;
        g.count = 0;
        return g;
      },
      text: function (a, g, f, b) {
        var d = !S && (this || _global).forExport,
            c = {};
        if (b && ((this || _global).allowHTML || !(this || _global).forExport)) return this.html(a, g, f);
        c.x = Math.round(g || 0);
        f && (c.y = Math.round(f));
        if (a || 0 === a) c.text = a;
        a = this.createElement("text").attr(c);
        d && a.css({
          position: "absolute"
        });
        b || (a.xSetter = function (a, g, f) {
          var b = f.getElementsByTagName("tspan"),
              d,
              c = f.getAttribute(g),
              q;

          for (q = 0; q < b.length; q++) d = b[q], d.getAttribute(g) === c && d.setAttribute(g, a);

          f.setAttribute(g, a);
        });
        return a;
      },
      fontMetrics: function (a, g) {
        a = a || g && g.style && g.style.fontSize || (this || _global).style && (this || _global).style.fontSize;
        a = /px/.test(a) ? L(a) : /em/.test(a) ? parseFloat(a) * (g ? this.fontMetrics(null, g.parentNode).f : 16) : 12;
        g = 24 > a ? a + 3 : Math.round(1.2 * a);
        return {
          h: g,
          b: Math.round(0.8 * g),
          f: a
        };
      },
      rotCorr: function (a, g, f) {
        var b = a;
        g && f && (b = Math.max(b * Math.cos(g * e), 4));
        return {
          x: -a / 3 * Math.sin(g * e),
          y: b
        };
      },
      label: function (f, d, c, q, D, y, m, G, r) {
        var w = this || _global,
            e = w.g("button" !== r && "label"),
            v = e.text = w.text("", 0, 0, m).attr({
          zIndex: 1
        }),
            p,
            l,
            F = 0,
            A = 3,
            h = 0,
            J,
            t,
            S,
            n,
            M,
            K = {},
            L,
            u,
            O = /^url\((.*?)\)$/.test(q),
            R = O,
            P,
            Q,
            N,
            U;
        r && e.addClass("highcharts-" + r);
        R = O;

        P = function () {
          return (L || 0) % 2 / 2;
        };

        Q = function () {
          var a = v.element.style,
              g = {};
          l = (void 0 === J || void 0 === t || M) && k(v.textStr) && v.getBBox();
          e.width = (J || l.width || 0) + 2 * A + h;
          e.height = (t || l.height || 0) + 2 * A;
          u = A + w.fontMetrics(a && a.fontSize, v).b;
          R && (p || (e.box = p = w.symbols[q] || O ? w.symbol(q) : w.rect(), p.addClass(("button" === r ? "" : "highcharts-label-box") + (r ? " highcharts-" + r + "-box" : "")), p.add(e), a = P(), g.x = a, g.y = (G ? -u : 0) + a), g.width = Math.round(e.width), g.height = Math.round(e.height), p.attr(b(g, K)), K = {});
        };

        N = function () {
          var a = h + A,
              g;
          g = G ? 0 : u;
          k(J) && l && ("center" === M || "right" === M) && (a += {
            center: 0.5,
            right: 1
          }[M] * (J - l.width));
          if (a !== v.x || g !== v.y) v.attr("x", a), void 0 !== g && v.attr("y", g);
          v.x = a;
          v.y = g;
        };

        U = function (a, g) {
          p ? p.attr(a, g) : K[a] = g;
        };

        e.onAdd = function () {
          v.add(e);
          e.attr({
            text: f || 0 === f ? f : "",
            x: d,
            y: c
          });
          p && k(D) && e.attr({
            anchorX: D,
            anchorY: y
          });
        };

        e.widthSetter = function (g) {
          J = a.isNumber(g) ? g : null;
        };

        e.heightSetter = function (a) {
          t = a;
        };

        e["text-alignSetter"] = function (a) {
          M = a;
        };

        e.paddingSetter = function (a) {
          k(a) && a !== A && (A = e.padding = a, N());
        };

        e.paddingLeftSetter = function (a) {
          k(a) && a !== h && (h = a, N());
        };

        e.alignSetter = function (a) {
          a = {
            left: 0,
            center: 0.5,
            right: 1
          }[a];
          a !== F && (F = a, l && e.attr({
            x: S
          }));
        };

        e.textSetter = function (a) {
          void 0 !== a && v.textSetter(a);
          Q();
          N();
        };

        e["stroke-widthSetter"] = function (a, g) {
          a && (R = !0);
          L = (this || _global)["stroke-width"] = a;
          U(g, a);
        };

        e.strokeSetter = e.fillSetter = e.rSetter = function (a, g) {
          "fill" === g && a && (R = !0);
          U(g, a);
        };

        e.anchorXSetter = function (a, g) {
          D = a;
          U(g, Math.round(a) - P() - S);
        };

        e.anchorYSetter = function (a, g) {
          y = a;
          U(g, a - n);
        };

        e.xSetter = function (a) {
          e.x = a;
          F && (a -= F * ((J || l.width) + 2 * A));
          S = Math.round(a);
          e.attr("translateX", S);
        };

        e.ySetter = function (a) {
          n = e.y = Math.round(a);
          e.attr("translateY", n);
        };

        var W = e.css;
        return b(e, {
          css: function (a) {
            if (a) {
              var g = {};
              a = x(a);
              H(e.textProps, function (f) {
                void 0 !== a[f] && (g[f] = a[f], delete a[f]);
              });
              v.css(g);
            }

            return W.call(e, a);
          },
          getBBox: function () {
            return {
              width: l.width + 2 * A,
              height: l.height + 2 * A,
              x: l.x - A,
              y: l.y - A
            };
          },
          shadow: function (a) {
            a && (Q(), p && p.shadow(a));
            return e;
          },
          destroy: function () {
            g(e.element, "mouseenter");
            g(e.element, "mouseleave");
            v && (v = v.destroy());
            p && (p = p.destroy());
            B.prototype.destroy.call(e);
            e = w = Q = N = U = null;
          }
        });
      }
    };
    a.Renderer = z;
  })(I);

  (function (a) {
    var B = a.attr,
        z = a.createElement,
        C = a.css,
        E = a.defined,
        u = a.each,
        h = a.extend,
        n = a.isFirefox,
        t = a.isMS,
        l = a.isWebKit,
        k = a.pInt,
        e = a.SVGRenderer,
        c = a.win,
        p = a.wrap;
    h(a.SVGElement.prototype, {
      htmlCss: function (a) {
        var b = (this || _global).element;
        if (b = a && "SPAN" === b.tagName && a.width) delete a.width, (this || _global).textWidth = b, this.updateTransform();
        a && "ellipsis" === a.textOverflow && (a.whiteSpace = "nowrap", a.overflow = "hidden");
        (this || _global).styles = h((this || _global).styles, a);
        C((this || _global).element, a);
        return this || _global;
      },
      htmlGetBBox: function () {
        var a = (this || _global).element;
        "text" === a.nodeName && (a.style.position = "absolute");
        return {
          x: a.offsetLeft,
          y: a.offsetTop,
          width: a.offsetWidth,
          height: a.offsetHeight
        };
      },
      htmlUpdateTransform: function () {
        if ((this || _global).added) {
          var a = (this || _global).renderer,
              b = (this || _global).element,
              c = (this || _global).translateX || 0,
              d = (this || _global).translateY || 0,
              e = (this || _global).x || 0,
              p = (this || _global).y || 0,
              v = (this || _global).textAlign || "left",
              f = {
            left: 0,
            center: 0.5,
            right: 1
          }[v],
              y = (this || _global).styles;
          C(b, {
            marginLeft: c,
            marginTop: d
          });
          (this || _global).shadows && u((this || _global).shadows, function (a) {
            C(a, {
              marginLeft: c + 1,
              marginTop: d + 1
            });
          });
          (this || _global).inverted && u(b.childNodes, function (f) {
            a.invertChild(f, b);
          });

          if ("SPAN" === b.tagName) {
            var G = (this || _global).rotation,
                F = k((this || _global).textWidth),
                q = y && y.whiteSpace,
                x = [G, v, b.innerHTML, (this || _global).textWidth, (this || _global).textAlign].join();
            x !== (this || _global).cTT && (y = a.fontMetrics(b.style.fontSize).b, E(G) && this.setSpanRotation(G, f, y), C(b, {
              width: "",
              whiteSpace: q || "nowrap"
            }), b.offsetWidth > F && /[ \-]/.test(b.textContent || b.innerText) && C(b, {
              width: F + "px",
              display: "block",
              whiteSpace: q || "normal"
            }), this.getSpanCorrection(b.offsetWidth, y, f, G, v));
            C(b, {
              left: e + ((this || _global).xCorr || 0) + "px",
              top: p + ((this || _global).yCorr || 0) + "px"
            });
            l && (y = b.offsetHeight);
            (this || _global).cTT = x;
          }
        } else (this || _global).alignOnAdd = !0;
      },
      setSpanRotation: function (a, b, e) {
        var d = {},
            m = t ? "-ms-transform" : l ? "-webkit-transform" : n ? "MozTransform" : c.opera ? "-o-transform" : "";
        d[m] = d.transform = "rotate(" + a + "deg)";
        d[m + (n ? "Origin" : "-origin")] = d.transformOrigin = 100 * b + "% " + e + "px";
        C((this || _global).element, d);
      },
      getSpanCorrection: function (a, b, c) {
        (this || _global).xCorr = -a * c;
        (this || _global).yCorr = -b;
      }
    });
    h(e.prototype, {
      html: function (a, b, c) {
        var d = this.createElement("span"),
            e = d.element,
            m = d.renderer,
            v = m.isSVG,
            f = function (a, f) {
          u(["opacity", "visibility"], function (b) {
            p(a, b + "Setter", function (a, b, d, c) {
              a.call(this || _global, b, d, c);
              f[d] = b;
            });
          });
        };

        d.textSetter = function (a) {
          a !== e.innerHTML && delete (this || _global).bBox;
          e.innerHTML = (this || _global).textStr = a;
          d.htmlUpdateTransform();
        };

        v && f(d, d.element.style);

        d.xSetter = d.ySetter = d.alignSetter = d.rotationSetter = function (a, f) {
          "align" === f && (f = "textAlign");
          d[f] = a;
          d.htmlUpdateTransform();
        };

        d.attr({
          text: a,
          x: Math.round(b),
          y: Math.round(c)
        }).css({
          fontFamily: (this || _global).style.fontFamily,
          fontSize: (this || _global).style.fontSize,
          position: "absolute"
        });
        e.style.whiteSpace = "nowrap";
        d.css = d.htmlCss;
        v && (d.add = function (a) {
          var b,
              c = m.box.parentNode,
              q = [];

          if ((this || _global).parentGroup = a) {
            if (b = a.div, !b) {
              for (; a;) q.push(a), a = a.parentGroup;

              u(q.reverse(), function (a) {
                var y,
                    e = B(a.element, "class");
                e && (e = {
                  className: e
                });
                b = a.div = a.div || z("div", e, {
                  position: "absolute",
                  left: (a.translateX || 0) + "px",
                  top: (a.translateY || 0) + "px",
                  display: a.display,
                  opacity: a.opacity,
                  pointerEvents: a.styles && a.styles.pointerEvents
                }, b || c);
                y = b.style;
                h(a, {
                  on: function () {
                    d.on.apply({
                      element: q[0].div
                    }, arguments);
                    return a;
                  },
                  translateXSetter: function (f, g) {
                    y.left = f + "px";
                    a[g] = f;
                    a.doTransform = !0;
                  },
                  translateYSetter: function (f, g) {
                    y.top = f + "px";
                    a[g] = f;
                    a.doTransform = !0;
                  }
                });
                f(a, y);
              });
            }
          } else b = c;

          b.appendChild(e);
          d.added = !0;
          d.alignOnAdd && d.htmlUpdateTransform();
          return d;
        });
        return d;
      }
    });
  })(I);

  (function (a) {
    var B,
        z,
        C = a.createElement,
        E = a.css,
        u = a.defined,
        h = a.deg2rad,
        n = a.discardElement,
        t = a.doc,
        l = a.each,
        k = a.erase,
        e = a.extend;
    B = a.extendClass;
    var c = a.isArray,
        p = a.isNumber,
        H = a.isObject,
        b = a.merge;
    z = a.noop;
    var m = a.pick,
        d = a.pInt,
        r = a.SVGElement,
        A = a.SVGRenderer,
        v = a.win;
    a.svg || (z = {
      docMode8: t && 8 === t.documentMode,
      init: function (a, b) {
        var f = ["<", b, " filled=\"f\" stroked=\"f\""],
            d = ["position: ", "absolute", ";"],
            c = "div" === b;
        ("shape" === b || c) && d.push("left:0;top:0;width:1px;height:1px;");
        d.push("visibility: ", c ? "hidden" : "visible");
        f.push(" style=\"", d.join(""), "\"/>");
        b && (f = c || "span" === b || "img" === b ? f.join("") : a.prepVML(f), (this || _global).element = C(f));
        (this || _global).renderer = a;
      },
      add: function (a) {
        var f = (this || _global).renderer,
            b = (this || _global).element,
            d = f.box,
            c = a && a.inverted,
            d = a ? a.element || a : d;
        a && ((this || _global).parentGroup = a);
        c && f.invertChild(b, d);
        d.appendChild(b);
        (this || _global).added = !0;
        (this || _global).alignOnAdd && !(this || _global).deferUpdateTransform && this.updateTransform();
        if ((this || _global).onAdd) this.onAdd();
        (this || _global).className && this.attr("class", (this || _global).className);
        return this || _global;
      },
      updateTransform: r.prototype.htmlUpdateTransform,
      setSpanRotation: function () {
        var a = (this || _global).rotation,
            b = Math.cos(a * h),
            d = Math.sin(a * h);
        E((this || _global).element, {
          filter: a ? ["progid:DXImageTransform.Microsoft.Matrix(M11=", b, ", M12=", -d, ", M21=", d, ", M22=", b, ", sizingMethod='auto expand')"].join("") : "none"
        });
      },
      getSpanCorrection: function (a, b, d, c, q) {
        var f = c ? Math.cos(c * h) : 1,
            e = c ? Math.sin(c * h) : 0,
            y = m((this || _global).elemHeight, (this || _global).element.offsetHeight),
            r;
        (this || _global).xCorr = 0 > f && -a;
        (this || _global).yCorr = 0 > e && -y;
        r = 0 > f * e;
        (this || _global).xCorr += e * b * (r ? 1 - d : d);
        (this || _global).yCorr -= f * b * (c ? r ? d : 1 - d : 1);
        q && "left" !== q && ((this || _global).xCorr -= a * d * (0 > f ? -1 : 1), c && ((this || _global).yCorr -= y * d * (0 > e ? -1 : 1)), E((this || _global).element, {
          textAlign: q
        }));
      },
      pathToVML: function (a) {
        for (var f = a.length, b = []; f--;) p(a[f]) ? b[f] = Math.round(10 * a[f]) - 5 : "Z" === a[f] ? b[f] = "x" : (b[f] = a[f], !a.isArc || "wa" !== a[f] && "at" !== a[f] || (b[f + 5] === b[f + 7] && (b[f + 7] += a[f + 7] > a[f + 5] ? 1 : -1), b[f + 6] === b[f + 8] && (b[f + 8] += a[f + 8] > a[f + 6] ? 1 : -1)));

        return b.join(" ") || "x";
      },
      clip: function (a) {
        var f = this || _global,
            b;
        a ? (b = a.members, k(b, f), b.push(f), f.destroyClip = function () {
          k(b, f);
        }, a = a.getCSS(f)) : (f.destroyClip && f.destroyClip(), a = {
          clip: f.docMode8 ? "inherit" : "rect(auto)"
        });
        return f.css(a);
      },
      css: r.prototype.htmlCss,
      safeRemoveChild: function (a) {
        a.parentNode && n(a);
      },
      destroy: function () {
        (this || _global).destroyClip && this.destroyClip();
        return r.prototype.destroy.apply(this || _global);
      },
      on: function (a, b) {
        (this || _global).element["on" + a] = function () {
          var a = v.event;
          a.target = a.srcElement;
          b(a);
        };

        return this || _global;
      },
      cutOffPath: function (a, b) {
        var f;
        a = a.split(/[ ,]/);
        f = a.length;
        if (9 === f || 11 === f) a[f - 4] = a[f - 2] = d(a[f - 2]) - 10 * b;
        return a.join(" ");
      },
      shadow: function (a, b, c) {
        var f = [],
            q,
            e = (this || _global).element,
            y = (this || _global).renderer,
            r,
            v = e.style,
            g,
            D = e.path,
            p,
            l,
            G,
            k;
        D && "string" !== typeof D.value && (D = "x");
        l = D;

        if (a) {
          G = m(a.width, 3);
          k = (a.opacity || 0.15) / G;

          for (q = 1; 3 >= q; q++) p = 2 * G + 1 - 2 * q, c && (l = this.cutOffPath(D.value, p + 0.5)), g = ["<shape isShadow=\"true\" strokeweight=\"", p, "\" filled=\"false\" path=\"", l, "\" coordsize=\"10 10\" style=\"", e.style.cssText, "\" />"], r = C(y.prepVML(g), null, {
            left: d(v.left) + m(a.offsetX, 1),
            top: d(v.top) + m(a.offsetY, 1)
          }), c && (r.cutOff = p + 1), g = ["<stroke color=\"", a.color || "#000000", "\" opacity=\"", k * q, "\"/>"], C(y.prepVML(g), null, null, r), b ? b.element.appendChild(r) : e.parentNode.insertBefore(r, e), f.push(r);

          (this || _global).shadows = f;
        }

        return this || _global;
      },
      updateShadows: z,
      setAttr: function (a, b) {
        (this || _global).docMode8 ? (this || _global).element[a] = b : (this || _global).element.setAttribute(a, b);
      },
      classSetter: function (a) {
        ((this || _global).added ? (this || _global).element : this || _global).className = a;
      },
      dashstyleSetter: function (a, b, d) {
        (d.getElementsByTagName("stroke")[0] || C((this || _global).renderer.prepVML(["<stroke/>"]), null, null, d))[b] = a || "solid";
        (this || _global)[b] = a;
      },
      dSetter: function (a, b, d) {
        var f = (this || _global).shadows;
        a = a || [];
        (this || _global).d = a.join && a.join(" ");
        d.path = a = this.pathToVML(a);
        if (f) for (d = f.length; d--;) f[d].path = f[d].cutOff ? this.cutOffPath(a, f[d].cutOff) : a;
        this.setAttr(b, a);
      },
      fillSetter: function (a, b, d) {
        var f = d.nodeName;
        "SPAN" === f ? d.style.color = a : "IMG" !== f && (d.filled = "none" !== a, this.setAttr("fillcolor", (this || _global).renderer.color(a, d, b, this || _global)));
      },
      "fill-opacitySetter": function (a, b, d) {
        C((this || _global).renderer.prepVML(["<", b.split("-")[0], " opacity=\"", a, "\"/>"]), null, null, d);
      },
      opacitySetter: z,
      rotationSetter: function (a, b, d) {
        d = d.style;
        (this || _global)[b] = d[b] = a;
        d.left = -Math.round(Math.sin(a * h) + 1) + "px";
        d.top = Math.round(Math.cos(a * h)) + "px";
      },
      strokeSetter: function (a, b, d) {
        this.setAttr("strokecolor", (this || _global).renderer.color(a, d, b, this || _global));
      },
      "stroke-widthSetter": function (a, b, d) {
        d.stroked = !!a;
        (this || _global)[b] = a;
        p(a) && (a += "px");
        this.setAttr("strokeweight", a);
      },
      titleSetter: function (a, b) {
        this.setAttr(b, a);
      },
      visibilitySetter: function (a, b, d) {
        "inherit" === a && (a = "visible");
        (this || _global).shadows && l((this || _global).shadows, function (f) {
          f.style[b] = a;
        });
        "DIV" === d.nodeName && (a = "hidden" === a ? "-999em" : 0, (this || _global).docMode8 || (d.style[b] = a ? "visible" : "hidden"), b = "top");
        d.style[b] = a;
      },
      xSetter: function (a, b, d) {
        (this || _global)[b] = a;
        "x" === b ? b = "left" : "y" === b && (b = "top");
        (this || _global).updateClipping ? ((this || _global)[b] = a, this.updateClipping()) : d.style[b] = a;
      },
      zIndexSetter: function (a, b, d) {
        d.style[b] = a;
      }
    }, z["stroke-opacitySetter"] = z["fill-opacitySetter"], a.VMLElement = z = B(r, z), z.prototype.ySetter = z.prototype.widthSetter = z.prototype.heightSetter = z.prototype.xSetter, z = {
      Element: z,
      isIE8: -1 < v.navigator.userAgent.indexOf("MSIE 8.0"),
      init: function (a, b, d) {
        var f, c;
        (this || _global).alignedObjects = [];
        f = this.createElement("div").css({
          position: "relative"
        });
        c = f.element;
        a.appendChild(f.element);
        (this || _global).isVML = !0;
        (this || _global).box = c;
        (this || _global).boxWrapper = f;
        (this || _global).gradients = {};
        (this || _global).cache = {};
        (this || _global).cacheKeys = [];
        (this || _global).imgCount = 0;
        this.setSize(b, d, !1);

        if (!t.namespaces.hcv) {
          t.namespaces.add("hcv", "urn:schemas-microsoft-com:vml");

          try {
            t.createStyleSheet().cssText = "hcv\\:fill, hcv\\:path, hcv\\:shape, hcv\\:stroke{ behavior:url(#default#VML); display: inline-block; } ";
          } catch (x) {
            t.styleSheets[0].cssText += "hcv\\:fill, hcv\\:path, hcv\\:shape, hcv\\:stroke{ behavior:url(#default#VML); display: inline-block; } ";
          }
        }
      },
      isHidden: function () {
        return !(this || _global).box.offsetWidth;
      },
      clipRect: function (a, b, d, c) {
        var f = this.createElement(),
            m = H(a);
        return e(f, {
          members: [],
          count: 0,
          left: (m ? a.x : a) + 1,
          top: (m ? a.y : b) + 1,
          width: (m ? a.width : d) - 1,
          height: (m ? a.height : c) - 1,
          getCSS: function (a) {
            var b = a.element,
                d = b.nodeName,
                g = a.inverted,
                f = (this || _global).top - ("shape" === d ? b.offsetTop : 0),
                c = (this || _global).left,
                b = c + (this || _global).width,
                q = f + (this || _global).height,
                f = {
              clip: "rect(" + Math.round(g ? c : f) + "px," + Math.round(g ? q : b) + "px," + Math.round(g ? b : q) + "px," + Math.round(g ? f : c) + "px)"
            };
            !g && a.docMode8 && "DIV" === d && e(f, {
              width: b + "px",
              height: q + "px"
            });
            return f;
          },
          updateClipping: function () {
            l(f.members, function (a) {
              a.element && a.css(f.getCSS(a));
            });
          }
        });
      },
      color: function (b, d, c, e) {
        var f = this || _global,
            m,
            r = /^rgba/,
            v,
            p,
            g = "none";
        b && b.linearGradient ? p = "gradient" : b && b.radialGradient && (p = "pattern");

        if (p) {
          var D,
              y,
              k = b.linearGradient || b.radialGradient,
              A,
              h,
              w,
              G,
              t,
              F = "";
          b = b.stops;

          var n,
              H = [],
              u = function () {
            v = ["<fill colors=\"" + H.join(",") + "\" opacity=\"", w, "\" o:opacity2=\"", h, "\" type=\"", p, "\" ", F, "focus=\"100%\" method=\"any\" />"];
            C(f.prepVML(v), null, null, d);
          };

          A = b[0];
          n = b[b.length - 1];
          0 < A[0] && b.unshift([0, A[1]]);
          1 > n[0] && b.push([1, n[1]]);
          l(b, function (g, b) {
            r.test(g[1]) ? (m = a.color(g[1]), D = m.get("rgb"), y = m.get("a")) : (D = g[1], y = 1);
            H.push(100 * g[0] + "% " + D);
            b ? (w = y, G = D) : (h = y, t = D);
          });
          if ("fill" === c) {
            if ("gradient" === p) c = k.x1 || k[0] || 0, b = k.y1 || k[1] || 0, A = k.x2 || k[2] || 0, k = k.y2 || k[3] || 0, F = "angle=\"" + (90 - 180 * Math.atan((k - b) / (A - c)) / Math.PI) + "\"", u();else {
              var g = k.r,
                  z = 2 * g,
                  B = 2 * g,
                  E = k.cx,
                  V = k.cy,
                  I = d.radialReference,
                  T,
                  g = function () {
                I && (T = e.getBBox(), E += (I[0] - T.x) / T.width - 0.5, V += (I[1] - T.y) / T.height - 0.5, z *= I[2] / T.width, B *= I[2] / T.height);
                F = "src=\"" + a.getOptions().global.VMLRadialGradientURL + "\" size=\"" + z + "," + B + "\" origin=\"0.5,0.5\" position=\"" + E + "," + V + "\" color2=\"" + t + "\" ";
                u();
              };

              e.added ? g() : e.onAdd = g;
              g = G;
            }
          } else g = D;
        } else r.test(b) && "IMG" !== d.tagName ? (m = a.color(b), e[c + "-opacitySetter"](m.get("a"), c, d), g = m.get("rgb")) : (g = d.getElementsByTagName(c), g.length && (g[0].opacity = 1, g[0].type = "solid"), g = b);

        return g;
      },
      prepVML: function (a) {
        var b = (this || _global).isIE8;
        a = a.join("");
        b ? (a = a.replace("/>", " xmlns=\"urn:schemas-microsoft-com:vml\" />"), a = -1 === a.indexOf("style=\"") ? a.replace("/>", " style=\"display:inline-block;behavior:url(#default#VML);\" />") : a.replace("style=\"", "style=\"display:inline-block;behavior:url(#default#VML);")) : a = a.replace("<", "<hcv:");
        return a;
      },
      text: A.prototype.html,
      path: function (a) {
        var b = {
          coordsize: "10 10"
        };
        c(a) ? b.d = a : H(a) && e(b, a);
        return this.createElement("shape").attr(b);
      },
      circle: function (a, b, d) {
        var f = this.symbol("circle");
        H(a) && (d = a.r, b = a.y, a = a.x);
        f.isCircle = !0;
        f.r = d;
        return f.attr({
          x: a,
          y: b
        });
      },
      g: function (a) {
        var b;
        a && (b = {
          className: "highcharts-" + a,
          "class": "highcharts-" + a
        });
        return this.createElement("div").attr(b);
      },
      image: function (a, b, d, c, q) {
        var f = this.createElement("img").attr({
          src: a
        });
        1 < arguments.length && f.attr({
          x: b,
          y: d,
          width: c,
          height: q
        });
        return f;
      },
      createElement: function (a) {
        return "rect" === a ? this.symbol(a) : A.prototype.createElement.call(this || _global, a);
      },
      invertChild: function (a, b) {
        var f = this || _global;
        b = b.style;
        var c = "IMG" === a.tagName && a.style;
        E(a, {
          flip: "x",
          left: d(b.width) - (c ? d(c.top) : 1),
          top: d(b.height) - (c ? d(c.left) : 1),
          rotation: -90
        });
        l(a.childNodes, function (b) {
          f.invertChild(b, a);
        });
      },
      symbols: {
        arc: function (a, b, d, c, q) {
          var f = q.start,
              e = q.end,
              m = q.r || d || c;
          d = q.innerR;
          c = Math.cos(f);
          var r = Math.sin(f),
              g = Math.cos(e),
              D = Math.sin(e);
          if (0 === e - f) return ["x"];
          f = ["wa", a - m, b - m, a + m, b + m, a + m * c, b + m * r, a + m * g, b + m * D];
          q.open && !d && f.push("e", "M", a, b);
          f.push("at", a - d, b - d, a + d, b + d, a + d * g, b + d * D, a + d * c, b + d * r, "x", "e");
          f.isArc = !0;
          return f;
        },
        circle: function (a, b, d, c, q) {
          q && u(q.r) && (d = c = 2 * q.r);
          q && q.isCircle && (a -= d / 2, b -= c / 2);
          return ["wa", a, b, a + d, b + c, a + d, b + c / 2, a + d, b + c / 2, "e"];
        },
        rect: function (a, b, d, c, q) {
          return A.prototype.symbols[u(q) && q.r ? "callout" : "square"].call(0, a, b, d, c, q);
        }
      }
    }, a.VMLRenderer = B = function () {
      (this || _global).init.apply(this || _global, arguments);
    }, B.prototype = b(A.prototype, z), a.Renderer = B);

    A.prototype.measureSpanWidth = function (a, b) {
      var d = t.createElement("span");
      a = t.createTextNode(a);
      d.appendChild(a);
      E(d, b);

      (this || _global).box.appendChild(d);

      b = d.offsetWidth;
      n(d);
      return b;
    };
  })(I);

  (function (a) {
    function B() {
      var l = a.defaultOptions.global,
          k = t.moment;

      if (l.timezone) {
        if (k) return function (a) {
          return -k.tz(a, l.timezone).utcOffset();
        };
        a.error(25);
      }

      return l.useUTC && l.getTimezoneOffset;
    }

    function z() {
      var l = a.defaultOptions.global,
          k,
          e = l.useUTC,
          c = e ? "getUTC" : "get",
          p = e ? "setUTC" : "set";
      a.Date = k = l.Date || t.Date;
      k.hcTimezoneOffset = e && l.timezoneOffset;
      k.hcGetTimezoneOffset = B();

      k.hcMakeTime = function (a, b, c, d, r, p) {
        var m;
        e ? (m = k.UTC.apply(0, arguments), m += u(m)) : m = new k(a, b, n(c, 1), n(d, 0), n(r, 0), n(p, 0)).getTime();
        return m;
      };

      E("Minutes Hours Day Date Month FullYear".split(" "), function (a) {
        k["hcGet" + a] = c + a;
      });
      E("Milliseconds Seconds Minutes Hours Date Month FullYear".split(" "), function (a) {
        k["hcSet" + a] = p + a;
      });
    }

    var C = a.color,
        E = a.each,
        u = a.getTZOffset,
        h = a.merge,
        n = a.pick,
        t = a.win;
    a.defaultOptions = {
      colors: "#7cb5ec #434348 #90ed7d #f7a35c #8085e9 #f15c80 #e4d354 #2b908f #f45b5b #91e8e1".split(" "),
      symbols: ["circle", "diamond", "square", "triangle", "triangle-down"],
      lang: {
        loading: "Loading...",
        months: "January February March April May June July August September October November December".split(" "),
        shortMonths: "Jan Feb Mar Apr May Jun Jul Aug Sep Oct Nov Dec".split(" "),
        weekdays: "Sunday Monday Tuesday Wednesday Thursday Friday Saturday".split(" "),
        decimalPoint: ".",
        numericSymbols: "kMGTPE".split(""),
        resetZoom: "Reset zoom",
        resetZoomTitle: "Reset zoom level 1:1",
        thousandsSep: " "
      },
      global: {
        useUTC: !0,
        VMLRadialGradientURL: "http://code.highcharts.com/5.0.9/gfx/vml-radial-gradient.png"
      },
      chart: {
        borderRadius: 0,
        defaultSeriesType: "line",
        ignoreHiddenSeries: !0,
        spacing: [10, 10, 15, 10],
        resetZoomButton: {
          theme: {
            zIndex: 20
          },
          position: {
            align: "right",
            x: -10,
            y: 10
          }
        },
        width: null,
        height: null,
        borderColor: "#335cad",
        backgroundColor: "#ffffff",
        plotBorderColor: "#cccccc"
      },
      title: {
        text: "Chart title",
        align: "center",
        margin: 15,
        widthAdjust: -44
      },
      subtitle: {
        text: "",
        align: "center",
        widthAdjust: -44
      },
      plotOptions: {},
      labels: {
        style: {
          position: "absolute",
          color: "#333333"
        }
      },
      legend: {
        enabled: !0,
        align: "center",
        layout: "horizontal",
        labelFormatter: function () {
          return (this || _global).name;
        },
        borderColor: "#999999",
        borderRadius: 0,
        navigation: {
          activeColor: "#003399",
          inactiveColor: "#cccccc"
        },
        itemStyle: {
          color: "#333333",
          fontSize: "12px",
          fontWeight: "bold"
        },
        itemHoverStyle: {
          color: "#000000"
        },
        itemHiddenStyle: {
          color: "#cccccc"
        },
        shadow: !1,
        itemCheckboxStyle: {
          position: "absolute",
          width: "13px",
          height: "13px"
        },
        squareSymbol: !0,
        symbolPadding: 5,
        verticalAlign: "bottom",
        x: 0,
        y: 0,
        title: {
          style: {
            fontWeight: "bold"
          }
        }
      },
      loading: {
        labelStyle: {
          fontWeight: "bold",
          position: "relative",
          top: "45%"
        },
        style: {
          position: "absolute",
          backgroundColor: "#ffffff",
          opacity: 0.5,
          textAlign: "center"
        }
      },
      tooltip: {
        enabled: !0,
        animation: a.svg,
        borderRadius: 3,
        dateTimeLabelFormats: {
          millisecond: "%A, %b %e, %H:%M:%S.%L",
          second: "%A, %b %e, %H:%M:%S",
          minute: "%A, %b %e, %H:%M",
          hour: "%A, %b %e, %H:%M",
          day: "%A, %b %e, %Y",
          week: "Week from %A, %b %e, %Y",
          month: "%B %Y",
          year: "%Y"
        },
        footerFormat: "",
        padding: 8,
        snap: a.isTouchDevice ? 25 : 10,
        backgroundColor: C("#f7f7f7").setOpacity(0.85).get(),
        borderWidth: 1,
        headerFormat: "<span style=\"font-size: 10px\">{point.key}</span><br/>",
        pointFormat: "<span style=\"color:{point.color}\">\u25CF</span> {series.name}: <b>{point.y}</b><br/>",
        shadow: !0,
        style: {
          color: "#333333",
          cursor: "default",
          fontSize: "12px",
          pointerEvents: "none",
          whiteSpace: "nowrap"
        }
      },
      credits: {
        enabled: !0,
        href: "http://www.highcharts.com",
        position: {
          align: "right",
          x: -10,
          verticalAlign: "bottom",
          y: -5
        },
        style: {
          cursor: "pointer",
          color: "#999999",
          fontSize: "9px"
        },
        text: "Highcharts.com"
      }
    };

    a.setOptions = function (l) {
      a.defaultOptions = h(!0, a.defaultOptions, l);
      z();
      return a.defaultOptions;
    };

    a.getOptions = function () {
      return a.defaultOptions;
    };

    a.defaultPlotOptions = a.defaultOptions.plotOptions;
    z();
  })(I);

  (function (a) {
    var B = a.arrayMax,
        z = a.arrayMin,
        C = a.defined,
        E = a.destroyObjectProperties,
        u = a.each,
        h = a.erase,
        n = a.merge,
        t = a.pick;

    a.PlotLineOrBand = function (a, k) {
      (this || _global).axis = a;
      k && ((this || _global).options = k, (this || _global).id = k.id);
    };

    a.PlotLineOrBand.prototype = {
      render: function () {
        var a = this || _global,
            k = a.axis,
            e = k.horiz,
            c = a.options,
            p = c.label,
            h = a.label,
            b = c.to,
            m = c.from,
            d = c.value,
            r = C(m) && C(b),
            A = C(d),
            v = a.svgElem,
            f = !v,
            y = [],
            G,
            F = c.color,
            q = t(c.zIndex, 0),
            x = c.events,
            y = {
          "class": "highcharts-plot-" + (r ? "band " : "line ") + (c.className || "")
        },
            J = {},
            u = k.chart.renderer,
            L = r ? "bands" : "lines",
            g = k.log2lin;
        k.isLog && (m = g(m), b = g(b), d = g(d));
        A ? (y = {
          stroke: F,
          "stroke-width": c.width
        }, c.dashStyle && (y.dashstyle = c.dashStyle)) : r && (F && (y.fill = F), c.borderWidth && (y.stroke = c.borderColor, y["stroke-width"] = c.borderWidth));
        J.zIndex = q;
        L += "-" + q;
        (F = k[L]) || (k[L] = F = u.g("plot-" + L).attr(J).add());
        f && (a.svgElem = v = u.path().attr(y).add(F));
        if (A) y = k.getPlotLinePath(d, v.strokeWidth());else if (r) y = k.getPlotBandPath(m, b, c);else return;

        if (f && y && y.length) {
          if (v.attr({
            d: y
          }), x) for (G in c = function (b) {
            v.on(b, function (g) {
              x[b].apply(a, [g]);
            });
          }, x) c(G);
        } else v && (y ? (v.show(), v.animate({
          d: y
        })) : (v.hide(), h && (a.label = h = h.destroy())));

        p && C(p.text) && y && y.length && 0 < k.width && 0 < k.height && !y.flat ? (p = n({
          align: e && r && "center",
          x: e ? !r && 4 : 10,
          verticalAlign: !e && r && "middle",
          y: e ? r ? 16 : 10 : r ? 6 : -4,
          rotation: e && !r && 90
        }, p), this.renderLabel(p, y, r, q)) : h && h.hide();
        return a;
      },
      renderLabel: function (a, k, e, c) {
        var p = (this || _global).label,
            l = (this || _global).axis.chart.renderer;
        p || (p = {
          align: a.textAlign || a.align,
          rotation: a.rotation,
          "class": "highcharts-plot-" + (e ? "band" : "line") + "-label " + (a.className || "")
        }, p.zIndex = c, (this || _global).label = p = l.text(a.text, 0, 0, a.useHTML).attr(p).add(), p.css(a.style));
        c = [k[1], k[4], e ? k[6] : k[1]];
        k = [k[2], k[5], e ? k[7] : k[2]];
        e = z(c);
        l = z(k);
        p.align(a, !1, {
          x: e,
          y: l,
          width: B(c) - e,
          height: B(k) - l
        });
        p.show();
      },
      destroy: function () {
        h((this || _global).axis.plotLinesAndBands, this || _global);
        delete (this || _global).axis;
        E(this || _global);
      }
    };
    a.AxisPlotLineOrBandExtension = {
      getPlotBandPath: function (a, k) {
        k = this.getPlotLinePath(k, null, null, !0);
        (a = this.getPlotLinePath(a, null, null, !0)) && k ? (a.flat = a.toString() === k.toString(), a.push(k[4], k[5], k[1], k[2], "z")) : a = null;
        return a;
      },
      addPlotBand: function (a) {
        return this.addPlotBandOrLine(a, "plotBands");
      },
      addPlotLine: function (a) {
        return this.addPlotBandOrLine(a, "plotLines");
      },
      addPlotBandOrLine: function (l, k) {
        var e = new a.PlotLineOrBand(this || _global, l).render(),
            c = (this || _global).userOptions;
        e && (k && (c[k] = c[k] || [], c[k].push(l)), (this || _global).plotLinesAndBands.push(e));
        return e;
      },
      removePlotBandOrLine: function (a) {
        for (var k = (this || _global).plotLinesAndBands, e = (this || _global).options, c = (this || _global).userOptions, p = k.length; p--;) k[p].id === a && k[p].destroy();

        u([e.plotLines || [], c.plotLines || [], e.plotBands || [], c.plotBands || []], function (c) {
          for (p = c.length; p--;) c[p].id === a && h(c, c[p]);
        });
      }
    };
  })(I);

  (function (a) {
    var B = a.correctFloat,
        z = a.defined,
        C = a.destroyObjectProperties,
        E = a.isNumber,
        u = a.merge,
        h = a.pick,
        n = a.deg2rad;

    a.Tick = function (a, l, k, e) {
      (this || _global).axis = a;
      (this || _global).pos = l;
      (this || _global).type = k || "";
      (this || _global).isNew = !0;
      k || e || this.addLabel();
    };

    a.Tick.prototype = {
      addLabel: function () {
        var a = (this || _global).axis,
            l = a.options,
            k = a.chart,
            e = a.categories,
            c = a.names,
            p = (this || _global).pos,
            n = l.labels,
            b = a.tickPositions,
            m = p === b[0],
            d = p === b[b.length - 1],
            c = e ? h(e[p], c[p], p) : p,
            e = (this || _global).label,
            b = b.info,
            r;
        a.isDatetimeAxis && b && (r = l.dateTimeLabelFormats[b.higherRanks[p] || b.unitName]);
        (this || _global).isFirst = m;
        (this || _global).isLast = d;
        l = a.labelFormatter.call({
          axis: a,
          chart: k,
          isFirst: m,
          isLast: d,
          dateTimeLabelFormat: r,
          value: a.isLog ? B(a.lin2log(c)) : c
        });
        z(e) ? e && e.attr({
          text: l
        }) : ((this || _global).labelLength = ((this || _global).label = e = z(l) && n.enabled ? k.renderer.text(l, 0, 0, n.useHTML).css(u(n.style)).add(a.labelGroup) : null) && e.getBBox().width, (this || _global).rotation = 0);
      },
      getLabelSize: function () {
        return (this || _global).label ? (this || _global).label.getBBox()[(this || _global).axis.horiz ? "height" : "width"] : 0;
      },
      handleOverflow: function (a) {
        var l = (this || _global).axis,
            k = a.x,
            e = l.chart.chartWidth,
            c = l.chart.spacing,
            p = h(l.labelLeft, Math.min(l.pos, c[3])),
            c = h(l.labelRight, Math.max(l.pos + l.len, e - c[1])),
            t = (this || _global).label,
            b = (this || _global).rotation,
            m = {
          left: 0,
          center: 0.5,
          right: 1
        }[l.labelAlign],
            d = t.getBBox().width,
            r = l.getSlotWidth(),
            A = r,
            v = 1,
            f,
            y = {};
        if (b) 0 > b && k - m * d < p ? f = Math.round(k / Math.cos(b * n) - p) : 0 < b && k + m * d > c && (f = Math.round((e - k) / Math.cos(b * n)));else if (e = k + (1 - m) * d, k - m * d < p ? A = a.x + A * (1 - m) - p : e > c && (A = c - a.x + A * m, v = -1), A = Math.min(r, A), A < r && "center" === l.labelAlign && (a.x += v * (r - A - m * (r - Math.min(d, A)))), d > A || l.autoRotation && (t.styles || {}).width) f = A;
        f && (y.width = f, (l.options.labels.style || {}).textOverflow || (y.textOverflow = "ellipsis"), t.css(y));
      },
      getPosition: function (a, l, k, e) {
        var c = (this || _global).axis,
            p = c.chart,
            h = e && p.oldChartHeight || p.chartHeight;
        return {
          x: a ? c.translate(l + k, null, null, e) + c.transB : c.left + c.offset + (c.opposite ? (e && p.oldChartWidth || p.chartWidth) - c.right - c.left : 0),
          y: a ? h - c.bottom + c.offset - (c.opposite ? c.height : 0) : h - c.translate(l + k, null, null, e) - c.transB
        };
      },
      getLabelPosition: function (a, h, k, e, c, p, H, b) {
        var m = (this || _global).axis,
            d = m.transA,
            r = m.reversed,
            l = m.staggerLines,
            v = m.tickRotCorr || {
          x: 0,
          y: 0
        },
            f = c.y;
        z(f) || (f = 0 === m.side ? k.rotation ? -8 : -k.getBBox().height : 2 === m.side ? v.y + 8 : Math.cos(k.rotation * n) * (v.y - k.getBBox(!1, 0).height / 2));
        a = a + c.x + v.x - (p && e ? p * d * (r ? -1 : 1) : 0);
        h = h + f - (p && !e ? p * d * (r ? 1 : -1) : 0);
        l && (k = H / (b || 1) % l, m.opposite && (k = l - k - 1), h += m.labelOffset / l * k);
        return {
          x: a,
          y: Math.round(h)
        };
      },
      getMarkPath: function (a, h, k, e, c, p) {
        return p.crispLine(["M", a, h, "L", a + (c ? 0 : -k), h + (c ? k : 0)], e);
      },
      renderGridLine: function (a, h, k) {
        var e = (this || _global).axis,
            c = e.options,
            p = (this || _global).gridLine,
            l = {},
            b = (this || _global).pos,
            m = (this || _global).type,
            d = e.tickmarkOffset,
            r = e.chart.renderer,
            A = m ? m + "Grid" : "grid",
            v = c[A + "LineWidth"],
            f = c[A + "LineColor"],
            c = c[A + "LineDashStyle"];
        p || (l.stroke = f, l["stroke-width"] = v, c && (l.dashstyle = c), m || (l.zIndex = 1), a && (l.opacity = 0), (this || _global).gridLine = p = r.path().attr(l).addClass("highcharts-" + (m ? m + "-" : "") + "grid-line").add(e.gridGroup));
        if (!a && p && (a = e.getPlotLinePath(b + d, p.strokeWidth() * k, a, !0))) p[(this || _global).isNew ? "attr" : "animate"]({
          d: a,
          opacity: h
        });
      },
      renderMark: function (a, l, k) {
        var e = (this || _global).axis,
            c = e.options,
            p = e.chart.renderer,
            n = (this || _global).type,
            b = n ? n + "Tick" : "tick",
            m = e.tickSize(b),
            d = (this || _global).mark,
            r = !d,
            A = a.x;
        a = a.y;
        var v = h(c[b + "Width"], !n && e.isXAxis ? 1 : 0),
            c = c[b + "Color"];
        m && (e.opposite && (m[0] = -m[0]), r && ((this || _global).mark = d = p.path().addClass("highcharts-" + (n ? n + "-" : "") + "tick").add(e.axisGroup), d.attr({
          stroke: c,
          "stroke-width": v
        })), d[r ? "attr" : "animate"]({
          d: this.getMarkPath(A, a, m[0], d.strokeWidth() * k, e.horiz, p),
          opacity: l
        }));
      },
      renderLabel: function (a, l, k, e) {
        var c = (this || _global).axis,
            p = c.horiz,
            n = c.options,
            b = (this || _global).label,
            m = n.labels,
            d = m.step,
            r = c.tickmarkOffset,
            A = !0,
            v = a.x;
        a = a.y;
        b && E(v) && (b.xy = a = this.getLabelPosition(v, a, b, p, m, r, e, d), (this || _global).isFirst && !(this || _global).isLast && !h(n.showFirstLabel, 1) || (this || _global).isLast && !(this || _global).isFirst && !h(n.showLastLabel, 1) ? A = !1 : !p || c.isRadial || m.step || m.rotation || l || 0 === k || this.handleOverflow(a), d && e % d && (A = !1), A && E(a.y) ? (a.opacity = k, b[(this || _global).isNew ? "attr" : "animate"](a)) : b.attr("y", -9999), (this || _global).isNew = !1);
      },
      render: function (a, l, k) {
        var e = (this || _global).axis,
            c = e.horiz,
            p = this.getPosition(c, (this || _global).pos, e.tickmarkOffset, l),
            n = p.x,
            b = p.y,
            e = c && n === e.pos + e.len || !c && b === e.pos ? -1 : 1;
        k = h(k, 1);
        (this || _global).isActive = !0;
        this.renderGridLine(l, k, e);
        this.renderMark(p, k, e);
        this.renderLabel(p, l, k, a);
      },
      destroy: function () {
        C(this || _global, (this || _global).axis);
      }
    };
  })(I);

  (function (a) {
    var B = a.addEvent,
        z = a.animObject,
        C = a.arrayMax,
        E = a.arrayMin,
        u = a.AxisPlotLineOrBandExtension,
        h = a.color,
        n = a.correctFloat,
        t = a.defaultOptions,
        l = a.defined,
        k = a.deg2rad,
        e = a.destroyObjectProperties,
        c = a.each,
        p = a.extend,
        H = a.fireEvent,
        b = a.format,
        m = a.getMagnitude,
        d = a.grep,
        r = a.inArray,
        A = a.isArray,
        v = a.isNumber,
        f = a.isString,
        y = a.merge,
        G = a.normalizeTickInterval,
        F = a.pick,
        q = a.PlotLineOrBand,
        x = a.removeEvent,
        J = a.splat,
        K = a.syncTimeout,
        L = a.Tick;

    a.Axis = function () {
      (this || _global).init.apply(this || _global, arguments);
    };

    a.Axis.prototype = {
      defaultOptions: {
        dateTimeLabelFormats: {
          millisecond: "%H:%M:%S.%L",
          second: "%H:%M:%S",
          minute: "%H:%M",
          hour: "%H:%M",
          day: "%e. %b",
          week: "%e. %b",
          month: "%b '%y",
          year: "%Y"
        },
        endOnTick: !1,
        labels: {
          enabled: !0,
          style: {
            color: "#666666",
            cursor: "default",
            fontSize: "11px"
          },
          x: 0
        },
        minPadding: 0.01,
        maxPadding: 0.01,
        minorTickLength: 2,
        minorTickPosition: "outside",
        startOfWeek: 1,
        startOnTick: !1,
        tickLength: 10,
        tickmarkPlacement: "between",
        tickPixelInterval: 100,
        tickPosition: "outside",
        title: {
          align: "middle",
          style: {
            color: "#666666"
          }
        },
        type: "linear",
        minorGridLineColor: "#f2f2f2",
        minorGridLineWidth: 1,
        minorTickColor: "#999999",
        lineColor: "#ccd6eb",
        lineWidth: 1,
        gridLineColor: "#e6e6e6",
        tickColor: "#ccd6eb"
      },
      defaultYAxisOptions: {
        endOnTick: !0,
        tickPixelInterval: 72,
        showLastLabel: !0,
        labels: {
          x: -8
        },
        maxPadding: 0.05,
        minPadding: 0.05,
        startOnTick: !0,
        title: {
          rotation: 270,
          text: "Values"
        },
        stackLabels: {
          enabled: !1,
          formatter: function () {
            return a.numberFormat((this || _global).total, -1);
          },
          style: {
            fontSize: "11px",
            fontWeight: "bold",
            color: "#000000",
            textOutline: "1px contrast"
          }
        },
        gridLineWidth: 1,
        lineWidth: 0
      },
      defaultLeftAxisOptions: {
        labels: {
          x: -15
        },
        title: {
          rotation: 270
        }
      },
      defaultRightAxisOptions: {
        labels: {
          x: 15
        },
        title: {
          rotation: 90
        }
      },
      defaultBottomAxisOptions: {
        labels: {
          autoRotation: [-45],
          x: 0
        },
        title: {
          rotation: 0
        }
      },
      defaultTopAxisOptions: {
        labels: {
          autoRotation: [-45],
          x: 0
        },
        title: {
          rotation: 0
        }
      },
      init: function (a, b) {
        var g = b.isX;
        (this || _global).chart = a;
        (this || _global).horiz = a.inverted ? !g : g;
        (this || _global).isXAxis = g;
        (this || _global).coll = (this || _global).coll || (g ? "xAxis" : "yAxis");
        (this || _global).opposite = b.opposite;
        (this || _global).side = b.side || ((this || _global).horiz ? (this || _global).opposite ? 0 : 2 : (this || _global).opposite ? 1 : 3);
        this.setOptions(b);
        var d = (this || _global).options,
            c = d.type;
        (this || _global).labelFormatter = d.labels.formatter || (this || _global).defaultLabelFormatter;
        (this || _global).userOptions = b;
        (this || _global).minPixelPadding = 0;
        (this || _global).reversed = d.reversed;
        (this || _global).visible = !1 !== d.visible;
        (this || _global).zoomEnabled = !1 !== d.zoomEnabled;
        (this || _global).hasNames = "category" === c || !0 === d.categories;
        (this || _global).categories = d.categories || (this || _global).hasNames;
        (this || _global).names = (this || _global).names || [];
        (this || _global).isLog = "logarithmic" === c;
        (this || _global).isDatetimeAxis = "datetime" === c;
        (this || _global).positiveValuesOnly = (this || _global).isLog && !(this || _global).allowNegativeLog;
        (this || _global).isLinked = l(d.linkedTo);
        (this || _global).ticks = {};
        (this || _global).labelEdge = [];
        (this || _global).minorTicks = {};
        (this || _global).plotLinesAndBands = [];
        (this || _global).alternateBands = {};
        (this || _global).len = 0;
        (this || _global).minRange = (this || _global).userMinRange = d.minRange || d.maxZoom;
        (this || _global).range = d.range;
        (this || _global).offset = d.offset || 0;
        (this || _global).stacks = {};
        (this || _global).oldStacks = {};
        (this || _global).stacksTouched = 0;
        (this || _global).min = (this || _global).max = null;
        (this || _global).crosshair = F(d.crosshair, J(a.options.tooltip.crosshairs)[g ? 0 : 1], !1);
        var f;
        b = (this || _global).options.events;
        -1 === r(this || _global, a.axes) && (g ? a.axes.splice(a.xAxis.length, 0, this || _global) : a.axes.push(this || _global), a[(this || _global).coll].push(this || _global));
        (this || _global).series = (this || _global).series || [];
        a.inverted && g && void 0 === (this || _global).reversed && ((this || _global).reversed = !0);
        (this || _global).removePlotLine = (this || _global).removePlotBand = (this || _global).removePlotBandOrLine;

        for (f in b) B(this || _global, f, b[f]);

        (this || _global).lin2log = d.linearToLogConverter || (this || _global).lin2log;
        (this || _global).isLog && ((this || _global).val2lin = (this || _global).log2lin, (this || _global).lin2val = (this || _global).lin2log);
      },
      setOptions: function (a) {
        (this || _global).options = y((this || _global).defaultOptions, "yAxis" === (this || _global).coll && (this || _global).defaultYAxisOptions, [(this || _global).defaultTopAxisOptions, (this || _global).defaultRightAxisOptions, (this || _global).defaultBottomAxisOptions, (this || _global).defaultLeftAxisOptions][(this || _global).side], y(t[(this || _global).coll], a));
      },
      defaultLabelFormatter: function () {
        var g = (this || _global).axis,
            d = (this || _global).value,
            c = g.categories,
            f = (this || _global).dateTimeLabelFormat,
            q = t.lang,
            e = q.numericSymbols,
            q = q.numericSymbolMagnitude || 1000,
            w = e && e.length,
            m,
            r = g.options.labels.format,
            g = g.isLog ? Math.abs(d) : g.tickInterval;
        if (r) m = b(r, this || _global);else if (c) m = d;else if (f) m = a.dateFormat(f, d);else if (w && 1000 <= g) for (; w-- && void 0 === m;) c = Math.pow(q, w + 1), g >= c && 0 === 10 * d % c && null !== e[w] && 0 !== d && (m = a.numberFormat(d / c, -1) + e[w]);
        void 0 === m && (m = 10000 <= Math.abs(d) ? a.numberFormat(d, -1) : a.numberFormat(d, -1, void 0, ""));
        return m;
      },
      getSeriesExtremes: function () {
        var a = this || _global,
            b = a.chart;
        a.hasVisibleSeries = !1;
        a.dataMin = a.dataMax = a.threshold = null;
        a.softThreshold = !a.isXAxis;
        a.buildStacks && a.buildStacks();
        c(a.series, function (g) {
          if (g.visible || !b.options.chart.ignoreHiddenSeries) {
            var c = g.options,
                f = c.threshold,
                q;
            a.hasVisibleSeries = !0;
            a.positiveValuesOnly && 0 >= f && (f = null);
            if (a.isXAxis) c = g.xData, c.length && (g = E(c), v(g) || g instanceof Date || (c = d(c, function (a) {
              return v(a);
            }), g = E(c)), a.dataMin = Math.min(F(a.dataMin, c[0]), g), a.dataMax = Math.max(F(a.dataMax, c[0]), C(c)));else if (g.getExtremes(), q = g.dataMax, g = g.dataMin, l(g) && l(q) && (a.dataMin = Math.min(F(a.dataMin, g), g), a.dataMax = Math.max(F(a.dataMax, q), q)), l(f) && (a.threshold = f), !c.softThreshold || a.positiveValuesOnly) a.softThreshold = !1;
          }
        });
      },
      translate: function (a, b, d, c, f, q) {
        var g = (this || _global).linkedParent || this || _global,
            D = 1,
            e = 0,
            m = c ? g.oldTransA : g.transA;
        c = c ? g.oldMin : g.min;
        var r = g.minPixelPadding;
        f = (g.isOrdinal || g.isBroken || g.isLog && f) && g.lin2val;
        m || (m = g.transA);
        d && (D *= -1, e = g.len);
        g.reversed && (D *= -1, e -= D * (g.sector || g.len));
        b ? (a = (a * D + e - r) / m + c, f && (a = g.lin2val(a))) : (f && (a = g.val2lin(a)), a = D * (a - c) * m + e + D * r + (v(q) ? m * q : 0));
        return a;
      },
      toPixels: function (a, b) {
        return this.translate(a, !1, !(this || _global).horiz, null, !0) + (b ? 0 : (this || _global).pos);
      },
      toValue: function (a, b) {
        return this.translate(a - (b ? 0 : (this || _global).pos), !0, !(this || _global).horiz, null, !0);
      },
      getPlotLinePath: function (a, b, d, c, f) {
        var g = (this || _global).chart,
            q = (this || _global).left,
            D = (this || _global).top,
            e,
            m,
            r = d && g.oldChartHeight || g.chartHeight,
            p = d && g.oldChartWidth || g.chartWidth,
            x;
        e = (this || _global).transB;

        var k = function (a, b, g) {
          if (a < b || a > g) c ? a = Math.min(Math.max(b, a), g) : x = !0;
          return a;
        };

        f = F(f, this.translate(a, null, null, d));
        a = d = Math.round(f + e);
        e = m = Math.round(r - f - e);
        v(f) ? (this || _global).horiz ? (e = D, m = r - (this || _global).bottom, a = d = k(a, q, q + (this || _global).width)) : (a = q, d = p - (this || _global).right, e = m = k(e, D, D + (this || _global).height)) : x = !0;
        return x && !c ? null : g.renderer.crispLine(["M", a, e, "L", d, m], b || 1);
      },
      getLinearTickPositions: function (a, b, d) {
        var g,
            c = n(Math.floor(b / a) * a);
        d = n(Math.ceil(d / a) * a);
        var f = [];
        if ((this || _global).single) return [b];

        for (b = c; b <= d;) {
          f.push(b);
          b = n(b + a);
          if (b === g) break;
          g = b;
        }

        return f;
      },
      getMinorTickPositions: function () {
        var a = this || _global,
            b = a.options,
            d = a.tickPositions,
            f = a.minorTickInterval,
            q = [],
            e = a.pointRangePadding || 0,
            m = a.min - e,
            e = a.max + e,
            r = e - m;
        if (r && r / f < a.len / 3) if (a.isLog) c((this || _global).paddedTicks, function (b, g, d) {
          g && q.push.apply(q, a.getLogTickPositions(f, d[g - 1], d[g], !0));
        });else if (a.isDatetimeAxis && "auto" === b.minorTickInterval) q = q.concat(a.getTimeTicks(a.normalizeTimeTickInterval(f), m, e, b.startOfWeek));else for (b = m + (d[0] - m) % f; b <= e && b !== q[0]; b += f) q.push(b);
        0 !== q.length && a.trimTicks(q);
        return q;
      },
      adjustForMinRange: function () {
        var a = (this || _global).options,
            b = (this || _global).min,
            d = (this || _global).max,
            f,
            q = (this || _global).dataMax - (this || _global).dataMin >= (this || _global).minRange,
            e,
            m,
            r,
            p,
            v,
            x;
        (this || _global).isXAxis && void 0 === (this || _global).minRange && !(this || _global).isLog && (l(a.min) || l(a.max) ? (this || _global).minRange = null : (c((this || _global).series, function (a) {
          p = a.xData;

          for (m = v = a.xIncrement ? 1 : p.length - 1; 0 < m; m--) if (r = p[m] - p[m - 1], void 0 === e || r < e) e = r;
        }), (this || _global).minRange = Math.min(5 * e, (this || _global).dataMax - (this || _global).dataMin)));
        d - b < (this || _global).minRange && (x = (this || _global).minRange, f = (x - d + b) / 2, f = [b - f, F(a.min, b - f)], q && (f[2] = (this || _global).isLog ? this.log2lin((this || _global).dataMin) : (this || _global).dataMin), b = C(f), d = [b + x, F(a.max, b + x)], q && (d[2] = (this || _global).isLog ? this.log2lin((this || _global).dataMax) : (this || _global).dataMax), d = E(d), d - b < x && (f[0] = d - x, f[1] = F(a.min, d - x), b = C(f)));
        (this || _global).min = b;
        (this || _global).max = d;
      },
      getClosest: function () {
        var a;
        (this || _global).categories ? a = 1 : c((this || _global).series, function (b) {
          var g = b.closestPointRange,
              d = b.visible || !b.chart.options.chart.ignoreHiddenSeries;
          !b.noSharedTooltip && l(g) && d && (a = l(a) ? Math.min(a, g) : g);
        });
        return a;
      },
      nameToX: function (a) {
        var b = A((this || _global).categories),
            g = b ? (this || _global).categories : (this || _global).names,
            d = a.options.x,
            c;
        a.series.requireSorting = !1;
        l(d) || (d = !1 === (this || _global).options.uniqueNames ? a.series.autoIncrement() : r(a.name, g));
        -1 === d ? b || (c = g.length) : c = d;
        (this || _global).names[c] = a.name;
        return c;
      },
      updateNames: function () {
        var a = this || _global;
        0 < (this || _global).names.length && ((this || _global).names.length = 0, (this || _global).minRange = void 0, c((this || _global).series || [], function (b) {
          b.xIncrement = null;
          if (!b.points || b.isDirtyData) b.processData(), b.generatePoints();
          c(b.points, function (g, d) {
            var c;
            g.options && (c = a.nameToX(g), c !== g.x && (g.x = c, b.xData[d] = c));
          });
        }));
      },
      setAxisTranslation: function (a) {
        var b = this || _global,
            g = b.max - b.min,
            d = b.axisPointRange || 0,
            q,
            e = 0,
            m = 0,
            r = b.linkedParent,
            p = !!b.categories,
            v = b.transA,
            x = b.isXAxis;
        if (x || p || d) q = b.getClosest(), r ? (e = r.minPointOffset, m = r.pointRangePadding) : c(b.series, function (a) {
          var g = p ? 1 : x ? F(a.options.pointRange, q, 0) : b.axisPointRange || 0;
          a = a.options.pointPlacement;
          d = Math.max(d, g);
          b.single || (e = Math.max(e, f(a) ? 0 : g / 2), m = Math.max(m, "on" === a ? 0 : g));
        }), r = b.ordinalSlope && q ? b.ordinalSlope / q : 1, b.minPointOffset = e *= r, b.pointRangePadding = m *= r, b.pointRange = Math.min(d, g), x && (b.closestPointRange = q);
        a && (b.oldTransA = v);
        b.translationSlope = b.transA = v = b.options.staticScale || b.len / (g + m || 1);
        b.transB = b.horiz ? b.left : b.bottom;
        b.minPixelPadding = v * e;
      },
      minFromRange: function () {
        return (this || _global).max - (this || _global).range;
      },
      setTickInterval: function (b) {
        var g = this || _global,
            d = g.chart,
            f = g.options,
            q = g.isLog,
            e = g.log2lin,
            r = g.isDatetimeAxis,
            p = g.isXAxis,
            x = g.isLinked,
            k = f.maxPadding,
            h = f.minPadding,
            y = f.tickInterval,
            A = f.tickPixelInterval,
            J = g.categories,
            t = g.threshold,
            u = g.softThreshold,
            L,
            K,
            z,
            B;
        r || J || x || this.getTickAmount();
        z = F(g.userMin, f.min);
        B = F(g.userMax, f.max);
        x ? (g.linkedParent = d[g.coll][f.linkedTo], d = g.linkedParent.getExtremes(), g.min = F(d.min, d.dataMin), g.max = F(d.max, d.dataMax), f.type !== g.linkedParent.options.type && a.error(11, 1)) : (!u && l(t) && (g.dataMin >= t ? (L = t, h = 0) : g.dataMax <= t && (K = t, k = 0)), g.min = F(z, L, g.dataMin), g.max = F(B, K, g.dataMax));
        q && (g.positiveValuesOnly && !b && 0 >= Math.min(g.min, F(g.dataMin, g.min)) && a.error(10, 1), g.min = n(e(g.min), 15), g.max = n(e(g.max), 15));
        g.range && l(g.max) && (g.userMin = g.min = z = Math.max(g.min, g.minFromRange()), g.userMax = B = g.max, g.range = null);
        H(g, "foundExtremes");
        g.beforePadding && g.beforePadding();
        g.adjustForMinRange();
        !(J || g.axisPointRange || g.usePercentage || x) && l(g.min) && l(g.max) && (e = g.max - g.min) && (!l(z) && h && (g.min -= e * h), !l(B) && k && (g.max += e * k));
        v(f.floor) ? g.min = Math.max(g.min, f.floor) : v(f.softMin) && (g.min = Math.min(g.min, f.softMin));
        v(f.ceiling) ? g.max = Math.min(g.max, f.ceiling) : v(f.softMax) && (g.max = Math.max(g.max, f.softMax));
        u && l(g.dataMin) && (t = t || 0, !l(z) && g.min < t && g.dataMin >= t ? g.min = t : !l(B) && g.max > t && g.dataMax <= t && (g.max = t));
        g.tickInterval = g.min === g.max || void 0 === g.min || void 0 === g.max ? 1 : x && !y && A === g.linkedParent.options.tickPixelInterval ? y = g.linkedParent.tickInterval : F(y, (this || _global).tickAmount ? (g.max - g.min) / Math.max((this || _global).tickAmount - 1, 1) : void 0, J ? 1 : (g.max - g.min) * A / Math.max(g.len, A));
        p && !b && c(g.series, function (a) {
          a.processData(g.min !== g.oldMin || g.max !== g.oldMax);
        });
        g.setAxisTranslation(!0);
        g.beforeSetTickPositions && g.beforeSetTickPositions();
        g.postProcessTickInterval && (g.tickInterval = g.postProcessTickInterval(g.tickInterval));
        g.pointRange && !y && (g.tickInterval = Math.max(g.pointRange, g.tickInterval));
        b = F(f.minTickInterval, g.isDatetimeAxis && g.closestPointRange);
        !y && g.tickInterval < b && (g.tickInterval = b);
        r || q || y || (g.tickInterval = G(g.tickInterval, null, m(g.tickInterval), F(f.allowDecimals, !(0.5 < g.tickInterval && 5 > g.tickInterval && 1000 < g.max && 9999 > g.max)), !!(this || _global).tickAmount));
        (this || _global).tickAmount || (g.tickInterval = g.unsquish());
        this.setTickPositions();
      },
      setTickPositions: function () {
        var a = (this || _global).options,
            b,
            d = a.tickPositions,
            c = a.tickPositioner,
            f = a.startOnTick,
            q = a.endOnTick;
        (this || _global).tickmarkOffset = (this || _global).categories && "between" === a.tickmarkPlacement && 1 === (this || _global).tickInterval ? 0.5 : 0;
        (this || _global).minorTickInterval = "auto" === a.minorTickInterval && (this || _global).tickInterval ? (this || _global).tickInterval / 5 : a.minorTickInterval;
        (this || _global).single = (this || _global).min === (this || _global).max && l((this || _global).min) && !(this || _global).tickAmount && !1 !== a.allowDecimals;
        (this || _global).tickPositions = b = d && d.slice();
        !b && (b = (this || _global).isDatetimeAxis ? this.getTimeTicks(this.normalizeTimeTickInterval((this || _global).tickInterval, a.units), (this || _global).min, (this || _global).max, a.startOfWeek, (this || _global).ordinalPositions, (this || _global).closestPointRange, !0) : (this || _global).isLog ? this.getLogTickPositions((this || _global).tickInterval, (this || _global).min, (this || _global).max) : this.getLinearTickPositions((this || _global).tickInterval, (this || _global).min, (this || _global).max), b.length > (this || _global).len && (b = [b[0], b.pop()]), (this || _global).tickPositions = b, c && (c = c.apply(this || _global, [(this || _global).min, (this || _global).max]))) && ((this || _global).tickPositions = b = c);
        (this || _global).paddedTicks = b.slice(0);
        this.trimTicks(b, f, q);
        (this || _global).isLinked || ((this || _global).single && ((this || _global).min -= 0.5, (this || _global).max += 0.5), d || c || this.adjustTickAmount());
      },
      trimTicks: function (a, b, d) {
        var g = a[0],
            c = a[a.length - 1],
            f = (this || _global).minPointOffset || 0;

        if (!(this || _global).isLinked) {
          if (b) (this || _global).min = g;else for (; (this || _global).min - f > a[0];) a.shift();
          if (d) (this || _global).max = c;else for (; (this || _global).max + f < a[a.length - 1];) a.pop();
          0 === a.length && l(g) && a.push((c + g) / 2);
        }
      },
      alignToOthers: function () {
        var a = {},
            b,
            d = (this || _global).options;
        !1 === (this || _global).chart.options.chart.alignTicks || !1 === d.alignTicks || (this || _global).isLog || c((this || _global).chart[(this || _global).coll], function (g) {
          var d = g.options,
              d = [g.horiz ? d.left : d.top, d.width, d.height, d.pane].join();
          g.series.length && (a[d] ? b = !0 : a[d] = 1);
        });
        return b;
      },
      getTickAmount: function () {
        var a = (this || _global).options,
            b = a.tickAmount,
            d = a.tickPixelInterval;
        !l(a.tickInterval) && (this || _global).len < d && !(this || _global).isRadial && !(this || _global).isLog && a.startOnTick && a.endOnTick && (b = 2);
        !b && this.alignToOthers() && (b = Math.ceil((this || _global).len / d) + 1);
        4 > b && ((this || _global).finalTickAmt = b, b = 5);
        (this || _global).tickAmount = b;
      },
      adjustTickAmount: function () {
        var a = (this || _global).tickInterval,
            b = (this || _global).tickPositions,
            d = (this || _global).tickAmount,
            c = (this || _global).finalTickAmt,
            f = b && b.length;

        if (f < d) {
          for (; b.length < d;) b.push(n(b[b.length - 1] + a));

          (this || _global).transA *= (f - 1) / (d - 1);
          (this || _global).max = b[b.length - 1];
        } else f > d && ((this || _global).tickInterval *= 2, this.setTickPositions());

        if (l(c)) {
          for (a = d = b.length; a--;) (3 === c && 1 === a % 2 || 2 >= c && 0 < a && a < d - 1) && b.splice(a, 1);

          (this || _global).finalTickAmt = void 0;
        }
      },
      setScale: function () {
        var a, b;
        (this || _global).oldMin = (this || _global).min;
        (this || _global).oldMax = (this || _global).max;
        (this || _global).oldAxisLength = (this || _global).len;
        this.setAxisSize();
        b = (this || _global).len !== (this || _global).oldAxisLength;
        c((this || _global).series, function (b) {
          if (b.isDirtyData || b.isDirty || b.xAxis.isDirty) a = !0;
        });
        b || a || (this || _global).isLinked || (this || _global).forceRedraw || (this || _global).userMin !== (this || _global).oldUserMin || (this || _global).userMax !== (this || _global).oldUserMax || this.alignToOthers() ? ((this || _global).resetStacks && this.resetStacks(), (this || _global).forceRedraw = !1, this.getSeriesExtremes(), this.setTickInterval(), (this || _global).oldUserMin = (this || _global).userMin, (this || _global).oldUserMax = (this || _global).userMax, (this || _global).isDirty || ((this || _global).isDirty = b || (this || _global).min !== (this || _global).oldMin || (this || _global).max !== (this || _global).oldMax)) : (this || _global).cleanStacks && this.cleanStacks();
      },
      setExtremes: function (a, b, d, f, q) {
        var g = this || _global,
            e = g.chart;
        d = F(d, !0);
        c(g.series, function (a) {
          delete a.kdTree;
        });
        q = p(q, {
          min: a,
          max: b
        });
        H(g, "setExtremes", q, function () {
          g.userMin = a;
          g.userMax = b;
          g.eventArgs = q;
          d && e.redraw(f);
        });
      },
      zoom: function (a, b) {
        var g = (this || _global).dataMin,
            d = (this || _global).dataMax,
            c = (this || _global).options,
            f = Math.min(g, F(c.min, g)),
            c = Math.max(d, F(c.max, d));
        if (a !== (this || _global).min || b !== (this || _global).max) (this || _global).allowZoomOutside || (l(g) && (a < f && (a = f), a > c && (a = c)), l(d) && (b < f && (b = f), b > c && (b = c))), (this || _global).displayBtn = void 0 !== a || void 0 !== b, this.setExtremes(a, b, !1, void 0, {
          trigger: "zoom"
        });
        return !0;
      },
      setAxisSize: function () {
        var a = (this || _global).chart,
            b = (this || _global).options,
            d = b.offsets || [0, 0, 0, 0],
            c = (this || _global).horiz,
            f = F(b.width, a.plotWidth - d[3] + d[1]),
            q = F(b.height, a.plotHeight - d[0] + d[2]),
            e = F(b.top, a.plotTop + d[0]),
            b = F(b.left, a.plotLeft + d[3]),
            d = /%$/;
        d.test(q) && (q = Math.round(parseFloat(q) / 100 * a.plotHeight));
        d.test(e) && (e = Math.round(parseFloat(e) / 100 * a.plotHeight + a.plotTop));
        (this || _global).left = b;
        (this || _global).top = e;
        (this || _global).width = f;
        (this || _global).height = q;
        (this || _global).bottom = a.chartHeight - q - e;
        (this || _global).right = a.chartWidth - f - b;
        (this || _global).len = Math.max(c ? f : q, 0);
        (this || _global).pos = c ? b : e;
      },
      getExtremes: function () {
        var a = (this || _global).isLog,
            b = (this || _global).lin2log;
        return {
          min: a ? n(b((this || _global).min)) : (this || _global).min,
          max: a ? n(b((this || _global).max)) : (this || _global).max,
          dataMin: (this || _global).dataMin,
          dataMax: (this || _global).dataMax,
          userMin: (this || _global).userMin,
          userMax: (this || _global).userMax
        };
      },
      getThreshold: function (a) {
        var b = (this || _global).isLog,
            g = (this || _global).lin2log,
            d = b ? g((this || _global).min) : (this || _global).min,
            b = b ? g((this || _global).max) : (this || _global).max;
        null === a ? a = d : d > a ? a = d : b < a && (a = b);
        return this.translate(a, 0, 1, 0, 1);
      },
      autoLabelAlign: function (a) {
        a = (F(a, 0) - 90 * (this || _global).side + 720) % 360;
        return 15 < a && 165 > a ? "right" : 195 < a && 345 > a ? "left" : "center";
      },
      tickSize: function (a) {
        var b = (this || _global).options,
            g = b[a + "Length"],
            d = F(b[a + "Width"], "tick" === a && (this || _global).isXAxis ? 1 : 0);
        if (d && g) return "inside" === b[a + "Position"] && (g = -g), [g, d];
      },
      labelMetrics: function () {
        return (this || _global).chart.renderer.fontMetrics((this || _global).options.labels.style && (this || _global).options.labels.style.fontSize, (this || _global).ticks[0] && (this || _global).ticks[0].label);
      },
      unsquish: function () {
        var a = (this || _global).options.labels,
            b = (this || _global).horiz,
            d = (this || _global).tickInterval,
            f = d,
            q = (this || _global).len / ((((this || _global).categories ? 1 : 0) + (this || _global).max - (this || _global).min) / d),
            e,
            m = a.rotation,
            r = this.labelMetrics(),
            p,
            x = Number.MAX_VALUE,
            v,
            h = function (a) {
          a /= q || 1;
          a = 1 < a ? Math.ceil(a) : 1;
          return a * d;
        };

        b ? (v = !a.staggerLines && !a.step && (l(m) ? [m] : q < F(a.autoRotationLimit, 80) && a.autoRotation)) && c(v, function (a) {
          var b;
          if (a === m || a && -90 <= a && 90 >= a) p = h(Math.abs(r.h / Math.sin(k * a))), b = p + Math.abs(a / 360), b < x && (x = b, e = a, f = p);
        }) : a.step || (f = h(r.h));
        (this || _global).autoRotation = v;
        (this || _global).labelRotation = F(e, m);
        return f;
      },
      getSlotWidth: function () {
        var a = (this || _global).chart,
            b = (this || _global).horiz,
            d = (this || _global).options.labels,
            c = Math.max((this || _global).tickPositions.length - ((this || _global).categories ? 0 : 1), 1),
            f = a.margin[3];
        return b && 2 > (d.step || 0) && !d.rotation && ((this || _global).staggerLines || 1) * (this || _global).len / c || !b && (f && f - a.spacing[3] || 0.33 * a.chartWidth);
      },
      renderUnsquish: function () {
        var a = (this || _global).chart,
            b = a.renderer,
            d = (this || _global).tickPositions,
            q = (this || _global).ticks,
            e = (this || _global).options.labels,
            m = (this || _global).horiz,
            r = this.getSlotWidth(),
            p = Math.max(1, Math.round(r - 2 * (e.padding || 5))),
            x = {},
            v = this.labelMetrics(),
            k = e.style && e.style.textOverflow,
            h,
            l = 0,
            A,
            n;
        f(e.rotation) || (x.rotation = e.rotation || 0);
        c(d, function (a) {
          (a = q[a]) && a.labelLength > l && (l = a.labelLength);
        });
        (this || _global).maxLabelLength = l;
        if ((this || _global).autoRotation) l > p && l > v.h ? x.rotation = (this || _global).labelRotation : (this || _global).labelRotation = 0;else if (r && (h = {
          width: p + "px"
        }, !k)) for (h.textOverflow = "clip", A = d.length; !m && A--;) if (n = d[A], p = q[n].label) p.styles && "ellipsis" === p.styles.textOverflow ? p.css({
          textOverflow: "clip"
        }) : q[n].labelLength > r && p.css({
          width: r + "px"
        }), p.getBBox().height > (this || _global).len / d.length - (v.h - v.f) && (p.specCss = {
          textOverflow: "ellipsis"
        });
        x.rotation && (h = {
          width: (l > 0.5 * a.chartHeight ? 0.33 * a.chartHeight : a.chartHeight) + "px"
        }, k || (h.textOverflow = "ellipsis"));
        if ((this || _global).labelAlign = e.align || this.autoLabelAlign((this || _global).labelRotation)) x.align = (this || _global).labelAlign;
        c(d, function (a) {
          var b = (a = q[a]) && a.label;
          b && (b.attr(x), h && b.css(y(h, b.specCss)), delete b.specCss, a.rotation = x.rotation);
        });
        (this || _global).tickRotCorr = b.rotCorr(v.b, (this || _global).labelRotation || 0, 0 !== (this || _global).side);
      },
      hasData: function () {
        return (this || _global).hasVisibleSeries || l((this || _global).min) && l((this || _global).max) && !!(this || _global).tickPositions;
      },
      addTitle: function (a) {
        var b = (this || _global).chart.renderer,
            d = (this || _global).horiz,
            g = (this || _global).opposite,
            c = (this || _global).options.title,
            f;
        (this || _global).axisTitle || ((f = c.textAlign) || (f = (d ? {
          low: "left",
          middle: "center",
          high: "right"
        } : {
          low: g ? "right" : "left",
          middle: "center",
          high: g ? "left" : "right"
        })[c.align]), (this || _global).axisTitle = b.text(c.text, 0, 0, c.useHTML).attr({
          zIndex: 7,
          rotation: c.rotation || 0,
          align: f
        }).addClass("highcharts-axis-title").css(c.style).add((this || _global).axisGroup), (this || _global).axisTitle.isNew = !0);

        (this || _global).axisTitle[a ? "show" : "hide"](!0);
      },
      generateTick: function (a) {
        var b = (this || _global).ticks;
        b[a] ? b[a].addLabel() : b[a] = new L(this || _global, a);
      },
      getOffset: function () {
        var a = this || _global,
            b = a.chart,
            d = b.renderer,
            f = a.options,
            q = a.tickPositions,
            e = a.ticks,
            m = a.horiz,
            r = a.side,
            p = b.inverted ? [1, 0, 3, 2][r] : r,
            x,
            v,
            h = 0,
            k,
            y = 0,
            A = f.title,
            n = f.labels,
            G = 0,
            J = b.axisOffset,
            b = b.clipOffset,
            t = [-1, 1, 1, -1][r],
            u,
            H = f.className,
            L = a.axisParent,
            K = this.tickSize("tick");
        x = a.hasData();
        a.showAxis = v = x || F(f.showEmpty, !0);
        a.staggerLines = a.horiz && n.staggerLines;
        a.axisGroup || (a.gridGroup = d.g("grid").attr({
          zIndex: f.gridZIndex || 1
        }).addClass("highcharts-" + (this || _global).coll.toLowerCase() + "-grid " + (H || "")).add(L), a.axisGroup = d.g("axis").attr({
          zIndex: f.zIndex || 2
        }).addClass("highcharts-" + (this || _global).coll.toLowerCase() + " " + (H || "")).add(L), a.labelGroup = d.g("axis-labels").attr({
          zIndex: n.zIndex || 7
        }).addClass("highcharts-" + a.coll.toLowerCase() + "-labels " + (H || "")).add(L));
        if (x || a.isLinked) c(q, function (b, d) {
          a.generateTick(b, d);
        }), a.renderUnsquish(), !1 === n.reserveSpace || 0 !== r && 2 !== r && {
          1: "left",
          3: "right"
        }[r] !== a.labelAlign && "center" !== a.labelAlign || c(q, function (a) {
          G = Math.max(e[a].getLabelSize(), G);
        }), a.staggerLines && (G *= a.staggerLines, a.labelOffset = G * (a.opposite ? -1 : 1));else for (u in e) e[u].destroy(), delete e[u];
        A && A.text && !1 !== A.enabled && (a.addTitle(v), v && (h = a.axisTitle.getBBox()[m ? "height" : "width"], k = A.offset, y = l(k) ? 0 : F(A.margin, m ? 5 : 10)));
        a.renderLine();
        a.offset = t * F(f.offset, J[r]);
        a.tickRotCorr = a.tickRotCorr || {
          x: 0,
          y: 0
        };
        d = 0 === r ? -a.labelMetrics().h : 2 === r ? a.tickRotCorr.y : 0;
        y = Math.abs(G) + y;
        G && (y = y - d + t * (m ? F(n.y, a.tickRotCorr.y + 8 * t) : n.x));
        a.axisTitleMargin = F(k, y);
        J[r] = Math.max(J[r], a.axisTitleMargin + h + t * a.offset, y, x && q.length && K ? K[0] + t * a.offset : 0);
        f = f.offset ? 0 : 2 * Math.floor(a.axisLine.strokeWidth() / 2);
        b[p] = Math.max(b[p], f);
      },
      getLinePath: function (a) {
        var b = (this || _global).chart,
            d = (this || _global).opposite,
            g = (this || _global).offset,
            f = (this || _global).horiz,
            c = (this || _global).left + (d ? (this || _global).width : 0) + g,
            g = b.chartHeight - (this || _global).bottom - (d ? (this || _global).height : 0) + g;
        d && (a *= -1);
        return b.renderer.crispLine(["M", f ? (this || _global).left : c, f ? g : (this || _global).top, "L", f ? b.chartWidth - (this || _global).right : c, f ? g : b.chartHeight - (this || _global).bottom], a);
      },
      renderLine: function () {
        (this || _global).axisLine || ((this || _global).axisLine = (this || _global).chart.renderer.path().addClass("highcharts-axis-line").add((this || _global).axisGroup), (this || _global).axisLine.attr({
          stroke: (this || _global).options.lineColor,
          "stroke-width": (this || _global).options.lineWidth,
          zIndex: 7
        }));
      },
      getTitlePosition: function () {
        var a = (this || _global).horiz,
            b = (this || _global).left,
            d = (this || _global).top,
            f = (this || _global).len,
            c = (this || _global).options.title,
            q = a ? b : d,
            e = (this || _global).opposite,
            m = (this || _global).offset,
            r = c.x || 0,
            p = c.y || 0,
            x = (this || _global).chart.renderer.fontMetrics(c.style && c.style.fontSize, (this || _global).axisTitle).f,
            f = {
          low: q + (a ? 0 : f),
          middle: q + f / 2,
          high: q + (a ? f : 0)
        }[c.align],
            b = (a ? d + (this || _global).height : b) + (a ? 1 : -1) * (e ? -1 : 1) * (this || _global).axisTitleMargin + (2 === (this || _global).side ? x : 0);

        return {
          x: a ? f + r : b + (e ? (this || _global).width : 0) + m + r,
          y: a ? b + p - (e ? (this || _global).height : 0) + m : f + p
        };
      },
      renderMinorTick: function (a) {
        var b = (this || _global).chart.hasRendered && v((this || _global).oldMin),
            d = (this || _global).minorTicks;
        d[a] || (d[a] = new L(this || _global, a, "minor"));
        b && d[a].isNew && d[a].render(null, !0);
        d[a].render(null, !1, 1);
      },
      renderTick: function (a, b) {
        var d = (this || _global).isLinked,
            g = (this || _global).ticks,
            f = (this || _global).chart.hasRendered && v((this || _global).oldMin);
        if (!d || a >= (this || _global).min && a <= (this || _global).max) g[a] || (g[a] = new L(this || _global, a)), f && g[a].isNew && g[a].render(b, !0, 0.1), g[a].render(b);
      },
      render: function () {
        var a = this || _global,
            b = a.chart,
            d = a.options,
            f = a.isLog,
            e = a.lin2log,
            m = a.isLinked,
            r = a.tickPositions,
            p = a.axisTitle,
            x = a.ticks,
            v = a.minorTicks,
            h = a.alternateBands,
            k = d.stackLabels,
            y = d.alternateGridColor,
            l = a.tickmarkOffset,
            A = a.axisLine,
            n = a.showAxis,
            G = z(b.renderer.globalAnimation),
            J,
            t;
        a.labelEdge.length = 0;
        a.overlap = !1;
        c([x, v, h], function (a) {
          for (var b in a) a[b].isActive = !1;
        });
        if (a.hasData() || m) a.minorTickInterval && !a.categories && c(a.getMinorTickPositions(), function (b) {
          a.renderMinorTick(b);
        }), r.length && (c(r, function (b, d) {
          a.renderTick(b, d);
        }), l && (0 === a.min || a.single) && (x[-1] || (x[-1] = new L(a, -1, null, !0)), x[-1].render(-1))), y && c(r, function (d, g) {
          t = void 0 !== r[g + 1] ? r[g + 1] + l : a.max - l;
          0 === g % 2 && d < a.max && t <= a.max + (b.polar ? -l : l) && (h[d] || (h[d] = new q(a)), J = d + l, h[d].options = {
            from: f ? e(J) : J,
            to: f ? e(t) : t,
            color: y
          }, h[d].render(), h[d].isActive = !0);
        }), a._addedPlotLB || (c((d.plotLines || []).concat(d.plotBands || []), function (b) {
          a.addPlotBandOrLine(b);
        }), a._addedPlotLB = !0);
        c([x, v, h], function (a) {
          var d,
              g,
              f = [],
              c = G.duration;

          for (d in a) a[d].isActive || (a[d].render(d, !1, 0), a[d].isActive = !1, f.push(d));

          K(function () {
            for (g = f.length; g--;) a[f[g]] && !a[f[g]].isActive && (a[f[g]].destroy(), delete a[f[g]]);
          }, a !== h && b.hasRendered && c ? c : 0);
        });
        A && (A[A.isPlaced ? "animate" : "attr"]({
          d: this.getLinePath(A.strokeWidth())
        }), A.isPlaced = !0, A[n ? "show" : "hide"](!0));
        p && n && (p[p.isNew ? "attr" : "animate"](a.getTitlePosition()), p.isNew = !1);
        k && k.enabled && a.renderStackTotals();
        a.isDirty = !1;
      },
      redraw: function () {
        (this || _global).visible && (this.render(), c((this || _global).plotLinesAndBands, function (a) {
          a.render();
        }));
        c((this || _global).series, function (a) {
          a.isDirty = !0;
        });
      },
      keepProps: "extKey hcEvents names series userMax userMin".split(" "),
      destroy: function (a) {
        var b = this || _global,
            d = b.stacks,
            g,
            f = b.plotLinesAndBands,
            q;
        a || x(b);

        for (g in d) e(d[g]), d[g] = null;

        c([b.ticks, b.minorTicks, b.alternateBands], function (a) {
          e(a);
        });
        if (f) for (a = f.length; a--;) f[a].destroy();
        c("stackTotalGroup axisLine axisTitle axisGroup gridGroup labelGroup cross".split(" "), function (a) {
          b[a] && (b[a] = b[a].destroy());
        });

        for (q in b) b.hasOwnProperty(q) && -1 === r(q, b.keepProps) && delete b[q];
      },
      drawCrosshair: function (a, b) {
        var d,
            f = (this || _global).crosshair,
            g = F(f.snap, !0),
            c,
            q = (this || _global).cross;
        a || (a = (this || _global).cross && (this || _global).cross.e);
        (this || _global).crosshair && !1 !== (l(b) || !g) ? (g ? l(b) && (c = (this || _global).isXAxis ? b.plotX : (this || _global).len - b.plotY) : c = a && ((this || _global).horiz ? a.chartX - (this || _global).pos : (this || _global).len - a.chartY + (this || _global).pos), l(c) && (d = this.getPlotLinePath(b && ((this || _global).isXAxis ? b.x : F(b.stackY, b.y)), null, null, null, c) || null), l(d) ? (b = (this || _global).categories && !(this || _global).isRadial, q || ((this || _global).cross = q = (this || _global).chart.renderer.path().addClass("highcharts-crosshair highcharts-crosshair-" + (b ? "category " : "thin ") + f.className).attr({
          zIndex: F(f.zIndex, 2)
        }).add(), q.attr({
          stroke: f.color || (b ? h("#ccd6eb").setOpacity(0.25).get() : "#cccccc"),
          "stroke-width": F(f.width, 1)
        }), f.dashStyle && q.attr({
          dashstyle: f.dashStyle
        })), q.show().attr({
          d: d
        }), b && !f.width && q.attr({
          "stroke-width": (this || _global).transA
        }), (this || _global).cross.e = a) : this.hideCrosshair()) : this.hideCrosshair();
      },
      hideCrosshair: function () {
        (this || _global).cross && (this || _global).cross.hide();
      }
    };
    p(a.Axis.prototype, u);
  })(I);

  (function (a) {
    var B = a.Axis,
        z = a.Date,
        C = a.dateFormat,
        E = a.defaultOptions,
        u = a.defined,
        h = a.each,
        n = a.extend,
        t = a.getMagnitude,
        l = a.getTZOffset,
        k = a.normalizeTickInterval,
        e = a.pick,
        c = a.timeUnits;

    B.prototype.getTimeTicks = function (a, k, b, m) {
      var d = [],
          r = {},
          p = E.global.useUTC,
          v,
          f = new z(k - Math.abs(l(k))),
          y = z.hcMakeTime,
          G = a.unitRange,
          t = a.count,
          q;

      if (u(k)) {
        f[z.hcSetMilliseconds](G >= c.second ? 0 : t * Math.floor(f.getMilliseconds() / t));
        if (G >= c.second) f[z.hcSetSeconds](G >= c.minute ? 0 : t * Math.floor(f.getSeconds() / t));
        if (G >= c.minute) f[z.hcSetMinutes](G >= c.hour ? 0 : t * Math.floor(f[z.hcGetMinutes]() / t));
        if (G >= c.hour) f[z.hcSetHours](G >= c.day ? 0 : t * Math.floor(f[z.hcGetHours]() / t));
        if (G >= c.day) f[z.hcSetDate](G >= c.month ? 1 : t * Math.floor(f[z.hcGetDate]() / t));
        G >= c.month && (f[z.hcSetMonth](G >= c.year ? 0 : t * Math.floor(f[z.hcGetMonth]() / t)), v = f[z.hcGetFullYear]());
        if (G >= c.year) f[z.hcSetFullYear](v - v % t);
        if (G === c.week) f[z.hcSetDate](f[z.hcGetDate]() - f[z.hcGetDay]() + e(m, 1));
        v = f[z.hcGetFullYear]();
        m = f[z.hcGetMonth]();
        var x = f[z.hcGetDate](),
            J = f[z.hcGetHours]();
        if (z.hcTimezoneOffset || z.hcGetTimezoneOffset) q = (!p || !!z.hcGetTimezoneOffset) && (b - k > 4 * c.month || l(k) !== l(b)), f = f.getTime(), f = new z(f + l(f));
        p = f.getTime();

        for (k = 1; p < b;) d.push(p), p = G === c.year ? y(v + k * t, 0) : G === c.month ? y(v, m + k * t) : !q || G !== c.day && G !== c.week ? q && G === c.hour ? y(v, m, x, J + k * t) : p + G * t : y(v, m, x + k * t * (G === c.day ? 1 : 7)), k++;

        d.push(p);
        G <= c.hour && 10000 > d.length && h(d, function (a) {
          0 === a % 1800000 && "000000000" === C("%H%M%S%L", a) && (r[a] = "day");
        });
      }

      d.info = n(a, {
        higherRanks: r,
        totalRange: G * t
      });
      return d;
    };

    B.prototype.normalizeTimeTickInterval = function (a, e) {
      var b = e || [["millisecond", [1, 2, 5, 10, 20, 25, 50, 100, 200, 500]], ["second", [1, 2, 5, 10, 15, 30]], ["minute", [1, 2, 5, 10, 15, 30]], ["hour", [1, 2, 3, 4, 6, 8, 12]], ["day", [1, 2]], ["week", [1, 2]], ["month", [1, 2, 3, 4, 6]], ["year", null]];
      e = b[b.length - 1];
      var m = c[e[0]],
          d = e[1],
          r;

      for (r = 0; r < b.length && !(e = b[r], m = c[e[0]], d = e[1], b[r + 1] && a <= (m * d[d.length - 1] + c[b[r + 1][0]]) / 2); r++);

      m === c.year && a < 5 * m && (d = [1, 2, 5]);
      a = k(a / m, d, "year" === e[0] ? Math.max(t(a / m), 1) : 1);
      return {
        unitRange: m,
        count: a,
        unitName: e[0]
      };
    };
  })(I);

  (function (a) {
    var B = a.Axis,
        z = a.getMagnitude,
        C = a.map,
        E = a.normalizeTickInterval,
        u = a.pick;

    B.prototype.getLogTickPositions = function (a, n, t, l) {
      var k = (this || _global).options,
          e = (this || _global).len,
          c = (this || _global).lin2log,
          p = (this || _global).log2lin,
          h = [];
      l || ((this || _global)._minorAutoInterval = null);
      if (0.5 <= a) a = Math.round(a), h = this.getLinearTickPositions(a, n, t);else if (0.08 <= a) for (var e = Math.floor(n), b, m, d, r, A, k = 0.3 < a ? [1, 2, 4] : 0.15 < a ? [1, 2, 4, 6, 8] : [1, 2, 3, 4, 5, 6, 7, 8, 9]; e < t + 1 && !A; e++) for (m = k.length, b = 0; b < m && !A; b++) d = p(c(e) * k[b]), d > n && (!l || r <= t) && void 0 !== r && h.push(r), r > t && (A = !0), r = d;else n = c(n), t = c(t), a = k[l ? "minorTickInterval" : "tickInterval"], a = u("auto" === a ? null : a, (this || _global)._minorAutoInterval, k.tickPixelInterval / (l ? 5 : 1) * (t - n) / ((l ? e / (this || _global).tickPositions.length : e) || 1)), a = E(a, null, z(a)), h = C(this.getLinearTickPositions(a, n, t), p), l || ((this || _global)._minorAutoInterval = a / 5);
      l || ((this || _global).tickInterval = a);
      return h;
    };

    B.prototype.log2lin = function (a) {
      return Math.log(a) / Math.LN10;
    };

    B.prototype.lin2log = function (a) {
      return Math.pow(10, a);
    };
  })(I);

  (function (a) {
    var B = a.dateFormat,
        z = a.each,
        C = a.extend,
        E = a.format,
        u = a.isNumber,
        h = a.map,
        n = a.merge,
        t = a.pick,
        l = a.splat,
        k = a.syncTimeout,
        e = a.timeUnits;

    a.Tooltip = function () {
      (this || _global).init.apply(this || _global, arguments);
    };

    a.Tooltip.prototype = {
      init: function (a, e) {
        (this || _global).chart = a;
        (this || _global).options = e;
        (this || _global).crosshairs = [];
        (this || _global).now = {
          x: 0,
          y: 0
        };
        (this || _global).isHidden = !0;
        (this || _global).split = e.split && !a.inverted;
        (this || _global).shared = e.shared || (this || _global).split;
      },
      cleanSplit: function (a) {
        z((this || _global).chart.series, function (c) {
          var e = c && c.tt;
          e && (!e.isActive || a ? c.tt = e.destroy() : e.isActive = !1);
        });
      },
      getLabel: function () {
        var a = (this || _global).chart.renderer,
            e = (this || _global).options;
        (this || _global).label || ((this || _global).split ? (this || _global).label = a.g("tooltip") : ((this || _global).label = a.label("", 0, 0, e.shape || "callout", null, null, e.useHTML, null, "tooltip").attr({
          padding: e.padding,
          r: e.borderRadius
        }), (this || _global).label.attr({
          fill: e.backgroundColor,
          "stroke-width": e.borderWidth
        }).css(e.style).shadow(e.shadow)), (this || _global).label.attr({
          zIndex: 8
        }).add());
        return (this || _global).label;
      },
      update: function (a) {
        this.destroy();
        this.init((this || _global).chart, n(!0, (this || _global).options, a));
      },
      destroy: function () {
        (this || _global).label && ((this || _global).label = (this || _global).label.destroy());
        (this || _global).split && (this || _global).tt && (this.cleanSplit((this || _global).chart, !0), (this || _global).tt = (this || _global).tt.destroy());
        clearTimeout((this || _global).hideTimer);
        clearTimeout((this || _global).tooltipTimeout);
      },
      move: function (a, e, k, b) {
        var c = this || _global,
            d = c.now,
            r = !1 !== c.options.animation && !c.isHidden && (1 < Math.abs(a - d.x) || 1 < Math.abs(e - d.y)),
            p = c.followPointer || 1 < c.len;
        C(d, {
          x: r ? (2 * d.x + a) / 3 : a,
          y: r ? (d.y + e) / 2 : e,
          anchorX: p ? void 0 : r ? (2 * d.anchorX + k) / 3 : k,
          anchorY: p ? void 0 : r ? (d.anchorY + b) / 2 : b
        });
        c.getLabel().attr(d);
        r && (clearTimeout((this || _global).tooltipTimeout), (this || _global).tooltipTimeout = setTimeout(function () {
          c && c.move(a, e, k, b);
        }, 32));
      },
      hide: function (a) {
        var c = this || _global;
        clearTimeout((this || _global).hideTimer);
        a = t(a, (this || _global).options.hideDelay, 500);
        (this || _global).isHidden || ((this || _global).hideTimer = k(function () {
          c.getLabel()[a ? "fadeOut" : "hide"]();
          c.isHidden = !0;
        }, a));
      },
      getAnchor: function (a, e) {
        var c,
            b = (this || _global).chart,
            m = b.inverted,
            d = b.plotTop,
            r = b.plotLeft,
            p = 0,
            v = 0,
            f,
            k;
        a = l(a);
        c = a[0].tooltipPos;
        (this || _global).followPointer && e && (void 0 === e.chartX && (e = b.pointer.normalize(e)), c = [e.chartX - b.plotLeft, e.chartY - d]);
        c || (z(a, function (a) {
          f = a.series.yAxis;
          k = a.series.xAxis;
          p += a.plotX + (!m && k ? k.left - r : 0);
          v += (a.plotLow ? (a.plotLow + a.plotHigh) / 2 : a.plotY) + (!m && f ? f.top - d : 0);
        }), p /= a.length, v /= a.length, c = [m ? b.plotWidth - v : p, (this || _global).shared && !m && 1 < a.length && e ? e.chartY - d : m ? b.plotHeight - p : v]);
        return h(c, Math.round);
      },
      getPosition: function (a, e, k) {
        var b = (this || _global).chart,
            c = (this || _global).distance,
            d = {},
            r = k.h || 0,
            p,
            v = ["y", b.chartHeight, e, k.plotY + b.plotTop, b.plotTop, b.plotTop + b.plotHeight],
            f = ["x", b.chartWidth, a, k.plotX + b.plotLeft, b.plotLeft, b.plotLeft + b.plotWidth],
            h = !(this || _global).followPointer && t(k.ttBelow, !b.inverted === !!k.negative),
            l = function (a, b, f, g, q, e) {
          var m = f < g - c,
              x = g + c + f < b,
              v = g - c - f;
          g += c;
          if (h && x) d[a] = g;else if (!h && m) d[a] = v;else if (m) d[a] = Math.min(e - f, 0 > v - r ? v : v - r);else if (x) d[a] = Math.max(q, g + r + f > b ? g : g + r);else return !1;
        },
            n = function (a, b, f, g) {
          var q;
          g < c || g > b - c ? q = !1 : d[a] = g < f / 2 ? 1 : g > b - f / 2 ? b - f - 2 : g - f / 2;
          return q;
        },
            q = function (a) {
          var b = v;
          v = f;
          f = b;
          p = a;
        },
            x = function () {
          !1 !== l.apply(0, v) ? !1 !== n.apply(0, f) || p || (q(!0), x()) : p ? d.x = d.y = 0 : (q(!0), x());
        };

        (b.inverted || 1 < (this || _global).len) && q();
        x();
        return d;
      },
      defaultFormatter: function (a) {
        var c = (this || _global).points || l(this || _global),
            e;
        e = [a.tooltipFooterHeaderFormatter(c[0])];
        e = e.concat(a.bodyFormatter(c));
        e.push(a.tooltipFooterHeaderFormatter(c[0], !0));
        return e;
      },
      refresh: function (a, e) {
        var c,
            b = (this || _global).options,
            m,
            d = a,
            r,
            p = {},
            v = [];
        c = b.formatter || (this || _global).defaultFormatter;
        var p = (this || _global).shared,
            f;
        clearTimeout((this || _global).hideTimer);
        (this || _global).followPointer = l(d)[0].series.tooltipOptions.followPointer;
        r = this.getAnchor(d, e);
        e = r[0];
        m = r[1];
        !p || d.series && d.series.noSharedTooltip ? p = d.getLabelConfig() : (z(d, function (a) {
          a.setState("hover");
          v.push(a.getLabelConfig());
        }), p = {
          x: d[0].category,
          y: d[0].y
        }, p.points = v, d = d[0]);
        (this || _global).len = v.length;
        p = c.call(p, this || _global);
        f = d.series;
        (this || _global).distance = t(f.tooltipOptions.distance, 16);
        !1 === p ? this.hide() : (c = this.getLabel(), (this || _global).isHidden && c.attr({
          opacity: 1
        }).show(), (this || _global).split ? this.renderSplit(p, a) : (c.attr({
          text: p && p.join ? p.join("") : p
        }), c.removeClass(/highcharts-color-[\d]+/g).addClass("highcharts-color-" + t(d.colorIndex, f.colorIndex)), c.attr({
          stroke: b.borderColor || d.color || f.color || "#666666"
        }), this.updatePosition({
          plotX: e,
          plotY: m,
          negative: d.negative,
          ttBelow: d.ttBelow,
          h: r[2] || 0
        })), (this || _global).isHidden = !1);
      },
      renderSplit: function (c, e) {
        var p = this || _global,
            b = [],
            m = (this || _global).chart,
            d = m.renderer,
            r = !0,
            k = (this || _global).options,
            v,
            f = this.getLabel();
        z(c.slice(0, e.length + 1), function (a, c) {
          c = e[c - 1] || {
            isHeader: !0,
            plotX: e[0].plotX
          };
          var h = c.series || p,
              q = h.tt,
              x = c.series || {},
              l = "highcharts-color-" + t(c.colorIndex, x.colorIndex, "none");
          q || (h.tt = q = d.label(null, null, null, "callout").addClass("highcharts-tooltip-box " + l).attr({
            padding: k.padding,
            r: k.borderRadius,
            fill: k.backgroundColor,
            stroke: c.color || x.color || "#333333",
            "stroke-width": k.borderWidth
          }).add(f));
          q.isActive = !0;
          q.attr({
            text: a
          });
          q.css(k.style);
          a = q.getBBox();
          x = a.width + q.strokeWidth();
          c.isHeader ? (v = a.height, x = Math.max(0, Math.min(c.plotX + m.plotLeft - x / 2, m.chartWidth - x))) : x = c.plotX + m.plotLeft - t(k.distance, 16) - x;
          0 > x && (r = !1);
          a = (c.series && c.series.yAxis && c.series.yAxis.pos) + (c.plotY || 0);
          a -= m.plotTop;
          b.push({
            target: c.isHeader ? m.plotHeight + v : a,
            rank: c.isHeader ? 1 : 0,
            size: h.tt.getBBox().height + 1,
            point: c,
            x: x,
            tt: q
          });
        });
        this.cleanSplit();
        a.distribute(b, m.plotHeight + v);
        z(b, function (a) {
          var b = a.point,
              d = b.series;
          a.tt.attr({
            visibility: void 0 === a.pos ? "hidden" : "inherit",
            x: r || b.isHeader ? a.x : b.plotX + m.plotLeft + t(k.distance, 16),
            y: a.pos + m.plotTop,
            anchorX: b.isHeader ? b.plotX + m.plotLeft : b.plotX + d.xAxis.pos,
            anchorY: b.isHeader ? a.pos + m.plotTop - 15 : b.plotY + d.yAxis.pos
          });
        });
      },
      updatePosition: function (a) {
        var c = (this || _global).chart,
            e = this.getLabel(),
            e = ((this || _global).options.positioner || (this || _global).getPosition).call(this || _global, e.width, e.height, a);

        this.move(Math.round(e.x), Math.round(e.y || 0), a.plotX + c.plotLeft, a.plotY + c.plotTop);
      },
      getDateFormat: function (a, p, k, b) {
        var c = B("%m-%d %H:%M:%S.%L", p),
            d,
            r,
            h = {
          millisecond: 15,
          second: 12,
          minute: 9,
          hour: 6,
          day: 3
        },
            v = "millisecond";

        for (r in e) {
          if (a === e.week && +B("%w", p) === k && "00:00:00.000" === c.substr(6)) {
            r = "week";
            break;
          }

          if (e[r] > a) {
            r = v;
            break;
          }

          if (h[r] && c.substr(h[r]) !== "01-01 00:00:00.000".substr(h[r])) break;
          "week" !== r && (v = r);
        }

        r && (d = b[r]);
        return d;
      },
      getXDateFormat: function (a, e, k) {
        e = e.dateTimeLabelFormats;
        var b = k && k.closestPointRange;
        return (b ? this.getDateFormat(b, a.x, k.options.startOfWeek, e) : e.day) || e.year;
      },
      tooltipFooterHeaderFormatter: function (a, e) {
        var c = e ? "footer" : "header";
        e = a.series;
        var b = e.tooltipOptions,
            m = b.xDateFormat,
            d = e.xAxis,
            r = d && "datetime" === d.options.type && u(a.key),
            c = b[c + "Format"];
        r && !m && (m = this.getXDateFormat(a, b, d));
        r && m && (c = c.replace("{point.key}", "{point.key:" + m + "}"));
        return E(c, {
          point: a,
          series: e
        });
      },
      bodyFormatter: function (a) {
        return h(a, function (a) {
          var c = a.series.tooltipOptions;
          return (c.pointFormatter || a.point.tooltipFormatter).call(a.point, c.pointFormat);
        });
      }
    };
  })(I);

  (function (a) {
    var B = a.addEvent,
        z = a.attr,
        C = a.charts,
        E = a.color,
        u = a.css,
        h = a.defined,
        n = a.doc,
        t = a.each,
        l = a.extend,
        k = a.fireEvent,
        e = a.offset,
        c = a.pick,
        p = a.removeEvent,
        H = a.splat,
        b = a.Tooltip,
        m = a.win;

    a.Pointer = function (a, b) {
      this.init(a, b);
    };

    a.Pointer.prototype = {
      init: function (a, e) {
        (this || _global).options = e;
        (this || _global).chart = a;
        (this || _global).runChartClick = e.chart.events && !!e.chart.events.click;
        (this || _global).pinchDown = [];
        (this || _global).lastValidTouch = {};
        b && e.tooltip.enabled && (a.tooltip = new b(a, e.tooltip), (this || _global).followTouchMove = c(e.tooltip.followTouchMove, !0));
        this.setDOMEvents();
      },
      zoomOption: function (a) {
        var b = (this || _global).chart,
            d = b.options.chart,
            e = d.zoomType || "",
            b = b.inverted;
        /touch/.test(a.type) && (e = c(d.pinchType, e));
        (this || _global).zoomX = a = /x/.test(e);
        (this || _global).zoomY = e = /y/.test(e);
        (this || _global).zoomHor = a && !b || e && b;
        (this || _global).zoomVert = e && !b || a && b;
        (this || _global).hasZoom = a || e;
      },
      normalize: function (a, b) {
        var d, c;
        a = a || m.event;
        a.target || (a.target = a.srcElement);
        c = a.touches ? a.touches.length ? a.touches.item(0) : a.changedTouches[0] : a;
        b || ((this || _global).chartPosition = b = e((this || _global).chart.container));
        void 0 === c.pageX ? (d = Math.max(a.x, a.clientX - b.left), b = a.y) : (d = c.pageX - b.left, b = c.pageY - b.top);
        return l(a, {
          chartX: Math.round(d),
          chartY: Math.round(b)
        });
      },
      getCoordinates: function (a) {
        var b = {
          xAxis: [],
          yAxis: []
        };
        t((this || _global).chart.axes, function (d) {
          b[d.isXAxis ? "xAxis" : "yAxis"].push({
            axis: d,
            value: d.toValue(a[d.horiz ? "chartX" : "chartY"])
          });
        });
        return b;
      },
      getKDPoints: function (a, b, e) {
        var d = [],
            f,
            m,
            r;
        t(a, function (a) {
          f = a.noSharedTooltip && b;
          m = !b && a.directTouch;
          a.visible && !f && !m && c(a.options.enableMouseTracking, !0) && (r = a.searchPoint(e, !f && 1 === a.kdDimensions)) && r.series && d.push(r);
        });
        d.sort(function (a, d) {
          var f = a.distX - d.distX,
              c = a.dist - d.dist,
              e = (d.series.group && d.series.group.zIndex) - (a.series.group && a.series.group.zIndex);
          return 0 !== f && b ? f : 0 !== c ? c : 0 !== e ? e : a.series.index > d.series.index ? -1 : 1;
        });
        if (b) for (a = d.length; a--;) (d[a].x !== d[0].x || d[a].series.noSharedTooltip) && d.splice(a, 1);
        return d;
      },
      getPointFromEvent: function (a) {
        a = a.target;

        for (var b; a && !b;) b = a.point, a = a.parentNode;

        return b;
      },
      getHoverData: function (a, b, e, m, f, k) {
        var d = a;
        a = b;
        var r;
        if (m) f ? (r = [], t(e, function (a) {
          var b = a.noSharedTooltip && f,
              e = !f && a.directTouch;
          a.visible && !b && !e && c(a.options.enableMouseTracking, !0) && (a = a.searchKDTree({
            clientX: d.clientX,
            plotY: d.plotY
          }, !b && 1 === a.kdDimensions)) && a.series && r.push(a);
        }), 0 === r.length && (r = [d])) : r = [d];else {
          if (a && !a.options.stickyTracking) r = this.getKDPoints([a], f, k);else {
            if (!f) if (a) a.options.stickyTracking || (e = [a]);else for (m = 0; m < e.length; m++) if (e[m].directTouch || !e[m].options.stickyTracking) e = [];
            r = this.getKDPoints(e, f, k);
          }
          a = (d = r[0]) && d.series;
        }
        r.sort(function (a, b) {
          return a.series.index - b.series.index;
        });
        return {
          hoverPoint: d,
          hoverSeries: a,
          hoverPoints: r
        };
      },
      runPointActions: function (b, c) {
        var d = (this || _global).chart,
            e = d.tooltip,
            f = e ? e.shared : !1,
            m = c || d.hoverPoint,
            r = m && m.series || d.hoverSeries;
        c = this.getHoverData(m, r, d.series, !!c || r && r.directTouch, f, b);
        var k,
            q,
            m = c.hoverPoint;
        k = (r = c.hoverSeries) && r.tooltipOptions.followPointer;
        q = (f = f && m && !m.series.noSharedTooltip) ? c.hoverPoints : [m];

        if (m && (m !== d.hoverPoint || e && e.isHidden)) {
          t(d.hoverPoints || [], function (b) {
            -1 === a.inArray(b, q) && b.setState();
          });
          t(q || [], function (a) {
            a.setState("hover");
          });
          if (d.hoverSeries !== r) r.onMouseOver();
          r && !r.directTouch && (d.hoverPoint && d.hoverPoint.firePointEvent("mouseOut"), m.firePointEvent("mouseOver"));
          d.hoverPoints = q;
          d.hoverPoint = m;
          e && e.refresh(f ? q : m, b);
        } else k && e && !e.isHidden && (m = e.getAnchor([{}], b), e.updatePosition({
          plotX: m[0],
          plotY: m[1]
        }));

        (this || _global).unDocMouseMove || ((this || _global).unDocMouseMove = B(n, "mousemove", function (b) {
          var d = C[a.hoverChartIndex];
          if (d) d.pointer.onDocumentMouseMove(b);
        }));
        t(q, function (a) {
          t(d.axes, function (d) {
            (!a || a.series && a.series[d.coll] === d) && d.drawCrosshair(b, a);
          });
        });
      },
      reset: function (a, b) {
        var d = (this || _global).chart,
            c = d.hoverSeries,
            f = d.hoverPoint,
            e = d.hoverPoints,
            m = d.tooltip,
            r = m && m.shared ? e : f;
        a && r && t(H(r), function (b) {
          b.series.isCartesian && void 0 === b.plotX && (a = !1);
        });
        if (a) m && r && (m.refresh(r), f && (f.setState(f.state, !0), t(d.axes, function (a) {
          a.crosshair && a.drawCrosshair(null, f);
        })));else {
          if (f) f.onMouseOut();
          e && t(e, function (a) {
            a.setState();
          });
          if (c) c.onMouseOut();
          m && m.hide(b);
          (this || _global).unDocMouseMove && ((this || _global).unDocMouseMove = this.unDocMouseMove());
          t(d.axes, function (a) {
            a.hideCrosshair();
          });
          (this || _global).hoverX = d.hoverPoints = d.hoverPoint = null;
        }
      },
      scaleGroups: function (a, b) {
        var d = (this || _global).chart,
            c;
        t(d.series, function (f) {
          c = a || f.getPlotBox();
          f.xAxis && f.xAxis.zoomEnabled && f.group && (f.group.attr(c), f.markerGroup && (f.markerGroup.attr(c), f.markerGroup.clip(b ? d.clipRect : null)), f.dataLabelsGroup && f.dataLabelsGroup.attr(c));
        });
        d.clipRect.attr(b || d.clipBox);
      },
      dragStart: function (a) {
        var b = (this || _global).chart;
        b.mouseIsDown = a.type;
        b.cancelClick = !1;
        b.mouseDownX = (this || _global).mouseDownX = a.chartX;
        b.mouseDownY = (this || _global).mouseDownY = a.chartY;
      },
      drag: function (a) {
        var b = (this || _global).chart,
            d = b.options.chart,
            c = a.chartX,
            f = a.chartY,
            e = (this || _global).zoomHor,
            m = (this || _global).zoomVert,
            k = b.plotLeft,
            q = b.plotTop,
            x = b.plotWidth,
            p = b.plotHeight,
            h,
            l = (this || _global).selectionMarker,
            g = (this || _global).mouseDownX,
            n = (this || _global).mouseDownY,
            t = d.panKey && a[d.panKey + "Key"];
        l && l.touch || (c < k ? c = k : c > k + x && (c = k + x), f < q ? f = q : f > q + p && (f = q + p), (this || _global).hasDragged = Math.sqrt(Math.pow(g - c, 2) + Math.pow(n - f, 2)), 10 < (this || _global).hasDragged && (h = b.isInsidePlot(g - k, n - q), b.hasCartesianSeries && ((this || _global).zoomX || (this || _global).zoomY) && h && !t && !l && ((this || _global).selectionMarker = l = b.renderer.rect(k, q, e ? 1 : x, m ? 1 : p, 0).attr({
          fill: d.selectionMarkerFill || E("#335cad").setOpacity(0.25).get(),
          "class": "highcharts-selection-marker",
          zIndex: 7
        }).add()), l && e && (c -= g, l.attr({
          width: Math.abs(c),
          x: (0 < c ? 0 : c) + g
        })), l && m && (c = f - n, l.attr({
          height: Math.abs(c),
          y: (0 < c ? 0 : c) + n
        })), h && !l && d.panning && b.pan(a, d.panning)));
      },
      drop: function (a) {
        var b = this || _global,
            d = (this || _global).chart,
            c = (this || _global).hasPinched;

        if ((this || _global).selectionMarker) {
          var f = {
            originalEvent: a,
            xAxis: [],
            yAxis: []
          },
              e = (this || _global).selectionMarker,
              m = e.attr ? e.attr("x") : e.x,
              p = e.attr ? e.attr("y") : e.y,
              q = e.attr ? e.attr("width") : e.width,
              x = e.attr ? e.attr("height") : e.height,
              n;
          if ((this || _global).hasDragged || c) t(d.axes, function (d) {
            if (d.zoomEnabled && h(d.min) && (c || b[{
              xAxis: "zoomX",
              yAxis: "zoomY"
            }[d.coll]])) {
              var e = d.horiz,
                  g = "touchend" === a.type ? d.minPixelPadding : 0,
                  r = d.toValue((e ? m : p) + g),
                  e = d.toValue((e ? m + q : p + x) - g);
              f[d.coll].push({
                axis: d,
                min: Math.min(r, e),
                max: Math.max(r, e)
              });
              n = !0;
            }
          }), n && k(d, "selection", f, function (a) {
            d.zoom(l(a, c ? {
              animation: !1
            } : null));
          });
          (this || _global).selectionMarker = (this || _global).selectionMarker.destroy();
          c && this.scaleGroups();
        }

        d && (u(d.container, {
          cursor: d._cursor
        }), d.cancelClick = 10 < (this || _global).hasDragged, d.mouseIsDown = (this || _global).hasDragged = (this || _global).hasPinched = !1, (this || _global).pinchDown = []);
      },
      onContainerMouseDown: function (a) {
        a = this.normalize(a);
        this.zoomOption(a);
        a.preventDefault && a.preventDefault();
        this.dragStart(a);
      },
      onDocumentMouseUp: function (b) {
        C[a.hoverChartIndex] && C[a.hoverChartIndex].pointer.drop(b);
      },
      onDocumentMouseMove: function (a) {
        var b = (this || _global).chart,
            d = (this || _global).chartPosition;
        a = this.normalize(a, d);
        !d || this.inClass(a.target, "highcharts-tracker") || b.isInsidePlot(a.chartX - b.plotLeft, a.chartY - b.plotTop) || this.reset();
      },
      onContainerMouseLeave: function (b) {
        var d = C[a.hoverChartIndex];
        d && (b.relatedTarget || b.toElement) && (d.pointer.reset(), d.pointer.chartPosition = null);
      },
      onContainerMouseMove: function (b) {
        var d = (this || _global).chart;
        h(a.hoverChartIndex) && C[a.hoverChartIndex] && C[a.hoverChartIndex].mouseIsDown || (a.hoverChartIndex = d.index);
        b = this.normalize(b);
        b.returnValue = !1;
        "mousedown" === d.mouseIsDown && this.drag(b);
        !this.inClass(b.target, "highcharts-tracker") && !d.isInsidePlot(b.chartX - d.plotLeft, b.chartY - d.plotTop) || d.openMenu || this.runPointActions(b);
      },
      inClass: function (a, b) {
        for (var d; a;) {
          if (d = z(a, "class")) {
            if (-1 !== d.indexOf(b)) return !0;
            if (-1 !== d.indexOf("highcharts-container")) return !1;
          }

          a = a.parentNode;
        }
      },
      onTrackerMouseOut: function (a) {
        var b = (this || _global).chart.hoverSeries;
        a = a.relatedTarget || a.toElement;
        if (!(!b || !a || b.options.stickyTracking || this.inClass(a, "highcharts-tooltip") || this.inClass(a, "highcharts-series-" + b.index) && this.inClass(a, "highcharts-tracker"))) b.onMouseOut();
      },
      onContainerClick: function (a) {
        var b = (this || _global).chart,
            d = b.hoverPoint,
            c = b.plotLeft,
            f = b.plotTop;
        a = this.normalize(a);
        b.cancelClick || (d && this.inClass(a.target, "highcharts-tracker") ? (k(d.series, "click", l(a, {
          point: d
        })), b.hoverPoint && d.firePointEvent("click", a)) : (l(a, this.getCoordinates(a)), b.isInsidePlot(a.chartX - c, a.chartY - f) && k(b, "click", a)));
      },
      setDOMEvents: function () {
        var b = this || _global,
            c = b.chart.container;

        c.onmousedown = function (a) {
          b.onContainerMouseDown(a);
        };

        c.onmousemove = function (a) {
          b.onContainerMouseMove(a);
        };

        c.onclick = function (a) {
          b.onContainerClick(a);
        };

        B(c, "mouseleave", b.onContainerMouseLeave);
        1 === a.chartCount && B(n, "mouseup", b.onDocumentMouseUp);
        a.hasTouch && (c.ontouchstart = function (a) {
          b.onContainerTouchStart(a);
        }, c.ontouchmove = function (a) {
          b.onContainerTouchMove(a);
        }, 1 === a.chartCount && B(n, "touchend", b.onDocumentTouchEnd));
      },
      destroy: function () {
        var b;
        p((this || _global).chart.container, "mouseleave", (this || _global).onContainerMouseLeave);
        a.chartCount || (p(n, "mouseup", (this || _global).onDocumentMouseUp), p(n, "touchend", (this || _global).onDocumentTouchEnd));
        clearInterval((this || _global).tooltipTimeout);

        for (b in this || _global) (this || _global)[b] = null;
      }
    };
  })(I);

  (function (a) {
    var B = a.charts,
        z = a.each,
        C = a.extend,
        E = a.map,
        u = a.noop,
        h = a.pick;
    C(a.Pointer.prototype, {
      pinchTranslate: function (a, h, l, k, e, c) {
        (this || _global).zoomHor && this.pinchTranslateDirection(!0, a, h, l, k, e, c);
        (this || _global).zoomVert && this.pinchTranslateDirection(!1, a, h, l, k, e, c);
      },
      pinchTranslateDirection: function (a, h, l, k, e, c, p, u) {
        var b = (this || _global).chart,
            m = a ? "x" : "y",
            d = a ? "X" : "Y",
            r = "chart" + d,
            n = a ? "width" : "height",
            v = b["plot" + (a ? "Left" : "Top")],
            f,
            y,
            t = u || 1,
            F = b.inverted,
            q = b.bounds[a ? "h" : "v"],
            x = 1 === h.length,
            J = h[0][r],
            K = l[0][r],
            L = !x && h[1][r],
            g = !x && l[1][r],
            D;

        l = function () {
          !x && 20 < Math.abs(J - L) && (t = u || Math.abs(K - g) / Math.abs(J - L));
          y = (v - K) / t + J;
          f = b["plot" + (a ? "Width" : "Height")] / t;
        };

        l();
        h = y;
        h < q.min ? (h = q.min, D = !0) : h + f > q.max && (h = q.max - f, D = !0);
        D ? (K -= 0.8 * (K - p[m][0]), x || (g -= 0.8 * (g - p[m][1])), l()) : p[m] = [K, g];
        F || (c[m] = y - v, c[n] = f);
        c = F ? 1 / t : t;
        e[n] = f;
        e[m] = h;
        k[F ? a ? "scaleY" : "scaleX" : "scale" + d] = t;
        k["translate" + d] = c * v + (K - c * J);
      },
      pinch: function (a) {
        var n = this || _global,
            l = n.chart,
            k = n.pinchDown,
            e = a.touches,
            c = e.length,
            p = n.lastValidTouch,
            H = n.hasZoom,
            b = n.selectionMarker,
            m = {},
            d = 1 === c && (n.inClass(a.target, "highcharts-tracker") && l.runTrackerClick || n.runChartClick),
            r = {};
        1 < c && (n.initiated = !0);
        H && n.initiated && !d && a.preventDefault();
        E(e, function (a) {
          return n.normalize(a);
        });
        "touchstart" === a.type ? (z(e, function (a, b) {
          k[b] = {
            chartX: a.chartX,
            chartY: a.chartY
          };
        }), p.x = [k[0].chartX, k[1] && k[1].chartX], p.y = [k[0].chartY, k[1] && k[1].chartY], z(l.axes, function (a) {
          if (a.zoomEnabled) {
            var b = l.bounds[a.horiz ? "h" : "v"],
                d = a.minPixelPadding,
                c = a.toPixels(h(a.options.min, a.dataMin)),
                e = a.toPixels(h(a.options.max, a.dataMax)),
                m = Math.max(c, e);
            b.min = Math.min(a.pos, Math.min(c, e) - d);
            b.max = Math.max(a.pos + a.len, m + d);
          }
        }), n.res = !0) : n.followTouchMove && 1 === c ? this.runPointActions(n.normalize(a)) : k.length && (b || (n.selectionMarker = b = C({
          destroy: u,
          touch: !0
        }, l.plotBox)), n.pinchTranslate(k, e, m, b, r, p), n.hasPinched = H, n.scaleGroups(m, r), n.res && (n.res = !1, this.reset(!1, 0)));
      },
      touch: function (n, t) {
        var l = (this || _global).chart,
            k,
            e;
        if (l.index !== a.hoverChartIndex) this.onContainerMouseLeave({
          relatedTarget: !0
        });
        a.hoverChartIndex = l.index;
        1 === n.touches.length ? (n = this.normalize(n), (e = l.isInsidePlot(n.chartX - l.plotLeft, n.chartY - l.plotTop)) && !l.openMenu ? (t && this.runPointActions(n), "touchmove" === n.type && (t = (this || _global).pinchDown, k = t[0] ? 4 <= Math.sqrt(Math.pow(t[0].chartX - n.chartX, 2) + Math.pow(t[0].chartY - n.chartY, 2)) : !1), h(k, !0) && this.pinch(n)) : t && this.reset()) : 2 === n.touches.length && this.pinch(n);
      },
      onContainerTouchStart: function (a) {
        this.zoomOption(a);
        this.touch(a, !0);
      },
      onContainerTouchMove: function (a) {
        this.touch(a);
      },
      onDocumentTouchEnd: function (h) {
        B[a.hoverChartIndex] && B[a.hoverChartIndex].pointer.drop(h);
      }
    });
  })(I);

  (function (a) {
    var B = a.addEvent,
        z = a.charts,
        C = a.css,
        E = a.doc,
        u = a.extend,
        h = a.noop,
        n = a.Pointer,
        t = a.removeEvent,
        l = a.win,
        k = a.wrap;

    if (l.PointerEvent || l.MSPointerEvent) {
      var e = {},
          c = !!l.PointerEvent,
          p = function () {
        var a,
            c = [];

        c.item = function (a) {
          return (this || _global)[a];
        };

        for (a in e) e.hasOwnProperty(a) && c.push({
          pageX: e[a].pageX,
          pageY: e[a].pageY,
          target: e[a].target
        });

        return c;
      },
          H = function (b, c, d, e) {
        "touch" !== b.pointerType && b.pointerType !== b.MSPOINTER_TYPE_TOUCH || !z[a.hoverChartIndex] || (e(b), e = z[a.hoverChartIndex].pointer, e[c]({
          type: d,
          target: b.currentTarget,
          preventDefault: h,
          touches: p()
        }));
      };

      u(n.prototype, {
        onContainerPointerDown: function (a) {
          H(a, "onContainerTouchStart", "touchstart", function (a) {
            e[a.pointerId] = {
              pageX: a.pageX,
              pageY: a.pageY,
              target: a.currentTarget
            };
          });
        },
        onContainerPointerMove: function (a) {
          H(a, "onContainerTouchMove", "touchmove", function (a) {
            e[a.pointerId] = {
              pageX: a.pageX,
              pageY: a.pageY
            };
            e[a.pointerId].target || (e[a.pointerId].target = a.currentTarget);
          });
        },
        onDocumentPointerUp: function (a) {
          H(a, "onDocumentTouchEnd", "touchend", function (a) {
            delete e[a.pointerId];
          });
        },
        batchMSEvents: function (a) {
          a((this || _global).chart.container, c ? "pointerdown" : "MSPointerDown", (this || _global).onContainerPointerDown);
          a((this || _global).chart.container, c ? "pointermove" : "MSPointerMove", (this || _global).onContainerPointerMove);
          a(E, c ? "pointerup" : "MSPointerUp", (this || _global).onDocumentPointerUp);
        }
      });
      k(n.prototype, "init", function (a, c, d) {
        a.call(this || _global, c, d);
        (this || _global).hasZoom && C(c.container, {
          "-ms-touch-action": "none",
          "touch-action": "none"
        });
      });
      k(n.prototype, "setDOMEvents", function (a) {
        a.apply(this || _global);
        ((this || _global).hasZoom || (this || _global).followTouchMove) && this.batchMSEvents(B);
      });
      k(n.prototype, "destroy", function (a) {
        this.batchMSEvents(t);
        a.call(this || _global);
      });
    }
  })(I);

  (function (a) {
    var B,
        z = a.addEvent,
        C = a.css,
        E = a.discardElement,
        u = a.defined,
        h = a.each,
        n = a.isFirefox,
        t = a.marginNames,
        l = a.merge,
        k = a.pick,
        e = a.setAnimation,
        c = a.stableSort,
        p = a.win,
        H = a.wrap;

    B = a.Legend = function (a, c) {
      this.init(a, c);
    };

    B.prototype = {
      init: function (a, c) {
        (this || _global).chart = a;
        this.setOptions(c);
        c.enabled && (this.render(), z((this || _global).chart, "endResize", function () {
          (this || _global).legend.positionCheckboxes();
        }));
      },
      setOptions: function (a) {
        var b = k(a.padding, 8);
        (this || _global).options = a;
        (this || _global).itemStyle = a.itemStyle;
        (this || _global).itemHiddenStyle = l((this || _global).itemStyle, a.itemHiddenStyle);
        (this || _global).itemMarginTop = a.itemMarginTop || 0;
        (this || _global).initialItemX = (this || _global).padding = b;
        (this || _global).initialItemY = b - 5;
        (this || _global).itemHeight = (this || _global).maxItemWidth = 0;
        (this || _global).symbolWidth = k(a.symbolWidth, 16);
        (this || _global).pages = [];
      },
      update: function (a, c) {
        var b = (this || _global).chart;
        this.setOptions(l(!0, (this || _global).options, a));
        this.destroy();
        b.isDirtyLegend = b.isDirtyBox = !0;
        k(c, !0) && b.redraw();
      },
      colorizeItem: function (a, c) {
        a.legendGroup[c ? "removeClass" : "addClass"]("highcharts-legend-item-hidden");
        var b = (this || _global).options,
            e = a.legendItem,
            m = a.legendLine,
            k = a.legendSymbol,
            f = (this || _global).itemHiddenStyle.color,
            b = c ? b.itemStyle.color : f,
            p = c ? a.color || f : f,
            h = a.options && a.options.marker,
            l = {
          fill: p
        },
            q;
        e && e.css({
          fill: b,
          color: b
        });
        m && m.attr({
          stroke: p
        });

        if (k) {
          if (h && k.isMarker && (l = a.pointAttribs(), !c)) for (q in l) l[q] = f;
          k.attr(l);
        }
      },
      positionItem: function (a) {
        var b = (this || _global).options,
            d = b.symbolPadding,
            b = !b.rtl,
            c = a._legendItemPos,
            e = c[0],
            c = c[1],
            k = a.checkbox;
        (a = a.legendGroup) && a.element && a.translate(b ? e : (this || _global).legendWidth - e - 2 * d - 4, c);
        k && (k.x = e, k.y = c);
      },
      destroyItem: function (a) {
        var b = a.checkbox;
        h(["legendItem", "legendLine", "legendSymbol", "legendGroup"], function (b) {
          a[b] && (a[b] = a[b].destroy());
        });
        b && E(a.checkbox);
      },
      destroy: function () {
        function a(a) {
          (this || _global)[a] && ((this || _global)[a] = (this || _global)[a].destroy());
        }

        h(this.getAllItems(), function (b) {
          h(["legendItem", "legendGroup"], a, b);
        });
        h("clipRect up down pager nav box title group".split(" "), a, this || _global);
        (this || _global).display = null;
      },
      positionCheckboxes: function (a) {
        var b = (this || _global).group && (this || _global).group.alignAttr,
            d,
            c = (this || _global).clipHeight || (this || _global).legendHeight,
            e = (this || _global).titleHeight;
        b && (d = b.translateY, h((this || _global).allItems, function (m) {
          var f = m.checkbox,
              k;
          f && (k = d + e + f.y + (a || 0) + 3, C(f, {
            left: b.translateX + m.checkboxOffset + f.x - 20 + "px",
            top: k + "px",
            display: k > d - 6 && k < d + c - 6 ? "" : "none"
          }));
        }));
      },
      renderTitle: function () {
        var a = (this || _global).padding,
            c = (this || _global).options.title,
            d = 0;
        c.text && ((this || _global).title || ((this || _global).title = (this || _global).chart.renderer.label(c.text, a - 3, a - 4, null, null, null, null, null, "legend-title").attr({
          zIndex: 1
        }).css(c.style).add((this || _global).group)), a = (this || _global).title.getBBox(), d = a.height, (this || _global).offsetWidth = a.width, (this || _global).contentGroup.attr({
          translateY: d
        }));
        (this || _global).titleHeight = d;
      },
      setText: function (b) {
        var c = (this || _global).options;
        b.legendItem.attr({
          text: c.labelFormat ? a.format(c.labelFormat, b) : c.labelFormatter.call(b)
        });
      },
      renderItem: function (a) {
        var b = (this || _global).chart,
            d = b.renderer,
            c = (this || _global).options,
            e = "horizontal" === c.layout,
            p = (this || _global).symbolWidth,
            f = c.symbolPadding,
            h = (this || _global).itemStyle,
            n = (this || _global).itemHiddenStyle,
            t = (this || _global).padding,
            q = e ? k(c.itemDistance, 20) : 0,
            x = !c.rtl,
            J = c.width,
            u = c.itemMarginBottom || 0,
            L = (this || _global).itemMarginTop,
            g = (this || _global).initialItemX,
            D = a.legendItem,
            H = !a.series,
            z = !H && a.series.drawLegendSymbol ? a.series : a,
            B = z.options,
            B = (this || _global).createCheckboxForItem && B && B.showCheckbox,
            C = c.useHTML;
        D || (a.legendGroup = d.g("legend-item").addClass("highcharts-" + z.type + "-series highcharts-color-" + a.colorIndex + (a.options.className ? " " + a.options.className : "") + (H ? " highcharts-series-" + a.index : "")).attr({
          zIndex: 1
        }).add((this || _global).scrollGroup), a.legendItem = D = d.text("", x ? p + f : -f, (this || _global).baseline || 0, C).css(l(a.visible ? h : n)).attr({
          align: x ? "left" : "right",
          zIndex: 2
        }).add(a.legendGroup), (this || _global).baseline || (h = h.fontSize, (this || _global).fontMetrics = d.fontMetrics(h, D), (this || _global).baseline = (this || _global).fontMetrics.f + 3 + L, D.attr("y", (this || _global).baseline)), (this || _global).symbolHeight = c.symbolHeight || (this || _global).fontMetrics.f, z.drawLegendSymbol(this || _global, a), (this || _global).setItemEvents && this.setItemEvents(a, D, C), B && this.createCheckboxForItem(a));
        this.colorizeItem(a, a.visible);
        this.setText(a);
        d = D.getBBox();
        p = a.checkboxOffset = c.itemWidth || a.legendItemWidth || p + f + d.width + q + (B ? 20 : 0);
        (this || _global).itemHeight = f = Math.round(a.legendItemHeight || d.height);
        e && (this || _global).itemX - g + p > (J || b.chartWidth - 2 * t - g - c.x) && ((this || _global).itemX = g, (this || _global).itemY += L + (this || _global).lastLineHeight + u, (this || _global).lastLineHeight = 0);
        (this || _global).maxItemWidth = Math.max((this || _global).maxItemWidth, p);
        (this || _global).lastItemY = L + (this || _global).itemY + u;
        (this || _global).lastLineHeight = Math.max(f, (this || _global).lastLineHeight);
        a._legendItemPos = [(this || _global).itemX, (this || _global).itemY];
        e ? (this || _global).itemX += p : ((this || _global).itemY += L + f + u, (this || _global).lastLineHeight = f);
        (this || _global).offsetWidth = J || Math.max((e ? (this || _global).itemX - g - q : p) + t, (this || _global).offsetWidth);
      },
      getAllItems: function () {
        var a = [];
        h((this || _global).chart.series, function (b) {
          var d = b && b.options;
          b && k(d.showInLegend, u(d.linkedTo) ? !1 : void 0, !0) && (a = a.concat(b.legendItems || ("point" === d.legendType ? b.data : b)));
        });
        return a;
      },
      adjustMargins: function (a, c) {
        var b = (this || _global).chart,
            e = (this || _global).options,
            m = e.align.charAt(0) + e.verticalAlign.charAt(0) + e.layout.charAt(0);
        e.floating || h([/(lth|ct|rth)/, /(rtv|rm|rbv)/, /(rbh|cb|lbh)/, /(lbv|lm|ltv)/], function (d, f) {
          d.test(m) && !u(a[f]) && (b[t[f]] = Math.max(b[t[f]], b.legend[(f + 1) % 2 ? "legendHeight" : "legendWidth"] + [1, -1, -1, 1][f] * e[f % 2 ? "x" : "y"] + k(e.margin, 12) + c[f]));
        });
      },
      render: function () {
        var a = this || _global,
            e = a.chart,
            d = e.renderer,
            k = a.group,
            p,
            n,
            f,
            y,
            t = a.box,
            u = a.options,
            q = a.padding;
        a.itemX = a.initialItemX;
        a.itemY = a.initialItemY;
        a.offsetWidth = 0;
        a.lastItemY = 0;
        k || (a.group = k = d.g("legend").attr({
          zIndex: 7
        }).add(), a.contentGroup = d.g().attr({
          zIndex: 1
        }).add(k), a.scrollGroup = d.g().add(a.contentGroup));
        a.renderTitle();
        p = a.getAllItems();
        c(p, function (a, b) {
          return (a.options && a.options.legendIndex || 0) - (b.options && b.options.legendIndex || 0);
        });
        u.reversed && p.reverse();
        a.allItems = p;
        a.display = n = !!p.length;
        a.lastLineHeight = 0;
        h(p, function (b) {
          a.renderItem(b);
        });
        f = (u.width || a.offsetWidth) + q;
        y = a.lastItemY + a.lastLineHeight + a.titleHeight;
        y = a.handleOverflow(y);
        y += q;
        t || (a.box = t = d.rect().addClass("highcharts-legend-box").attr({
          r: u.borderRadius
        }).add(k), t.isNew = !0);
        t.attr({
          stroke: u.borderColor,
          "stroke-width": u.borderWidth || 0,
          fill: u.backgroundColor || "none"
        }).shadow(u.shadow);
        0 < f && 0 < y && (t[t.isNew ? "attr" : "animate"](t.crisp({
          x: 0,
          y: 0,
          width: f,
          height: y
        }, t.strokeWidth())), t.isNew = !1);
        t[n ? "show" : "hide"]();
        a.legendWidth = f;
        a.legendHeight = y;
        h(p, function (b) {
          a.positionItem(b);
        });
        n && k.align(l(u, {
          width: f,
          height: y
        }), !0, "spacingBox");
        e.isResizing || this.positionCheckboxes();
      },
      handleOverflow: function (a) {
        var b = this || _global,
            d = (this || _global).chart,
            c = d.renderer,
            e = (this || _global).options,
            p = e.y,
            d = d.spacingBox.height + ("top" === e.verticalAlign ? -p : p) - (this || _global).padding,
            p = e.maxHeight,
            f,
            l = (this || _global).clipRect,
            n = e.navigation,
            t = k(n.animation, !0),
            q = n.arrowSize || 12,
            x = (this || _global).nav,
            u = (this || _global).pages,
            K = (this || _global).padding,
            L,
            g = (this || _global).allItems,
            D = function (a) {
          a ? l.attr({
            height: a
          }) : l && (b.clipRect = l.destroy(), b.contentGroup.clip());
          b.contentGroup.div && (b.contentGroup.div.style.clip = a ? "rect(" + K + "px,9999px," + (K + a) + "px,0)" : "auto");
        };

        "horizontal" !== e.layout || "middle" === e.verticalAlign || e.floating || (d /= 2);
        p && (d = Math.min(d, p));
        u.length = 0;
        a > d && !1 !== n.enabled ? ((this || _global).clipHeight = f = Math.max(d - 20 - (this || _global).titleHeight - K, 0), (this || _global).currentPage = k((this || _global).currentPage, 1), (this || _global).fullHeight = a, h(g, function (a, b) {
          var d = a._legendItemPos[1];
          a = Math.round(a.legendItem.getBBox().height);
          var c = u.length;
          if (!c || d - u[c - 1] > f && (L || d) !== u[c - 1]) u.push(L || d), c++;
          b === g.length - 1 && d + a - u[c - 1] > f && u.push(d);
          d !== L && (L = d);
        }), l || (l = b.clipRect = c.clipRect(0, K, 9999, 0), b.contentGroup.clip(l)), D(f), x || ((this || _global).nav = x = c.g().attr({
          zIndex: 1
        }).add((this || _global).group), (this || _global).up = c.symbol("triangle", 0, 0, q, q).on("click", function () {
          b.scroll(-1, t);
        }).add(x), (this || _global).pager = c.text("", 15, 10).addClass("highcharts-legend-navigation").css(n.style).add(x), (this || _global).down = c.symbol("triangle-down", 0, 0, q, q).on("click", function () {
          b.scroll(1, t);
        }).add(x)), b.scroll(0), a = d) : x && (D(), (this || _global).nav = x.destroy(), (this || _global).scrollGroup.attr({
          translateY: 1
        }), (this || _global).clipHeight = 0);
        return a;
      },
      scroll: function (a, c) {
        var b = (this || _global).pages,
            m = b.length;
        a = (this || _global).currentPage + a;
        var k = (this || _global).clipHeight,
            p = (this || _global).options.navigation,
            f = (this || _global).pager,
            h = (this || _global).padding;
        a > m && (a = m);
        0 < a && (void 0 !== c && e(c, (this || _global).chart), (this || _global).nav.attr({
          translateX: h,
          translateY: k + (this || _global).padding + 7 + (this || _global).titleHeight,
          visibility: "visible"
        }), (this || _global).up.attr({
          "class": 1 === a ? "highcharts-legend-nav-inactive" : "highcharts-legend-nav-active"
        }), f.attr({
          text: a + "/" + m
        }), (this || _global).down.attr({
          x: 18 + (this || _global).pager.getBBox().width,
          "class": a === m ? "highcharts-legend-nav-inactive" : "highcharts-legend-nav-active"
        }), (this || _global).up.attr({
          fill: 1 === a ? p.inactiveColor : p.activeColor
        }).css({
          cursor: 1 === a ? "default" : "pointer"
        }), (this || _global).down.attr({
          fill: a === m ? p.inactiveColor : p.activeColor
        }).css({
          cursor: a === m ? "default" : "pointer"
        }), c = -b[a - 1] + (this || _global).initialItemY, (this || _global).scrollGroup.animate({
          translateY: c
        }), (this || _global).currentPage = a, this.positionCheckboxes(c));
      }
    };
    a.LegendSymbolMixin = {
      drawRectangle: function (a, c) {
        var b = a.symbolHeight,
            e = a.options.squareSymbol;
        c.legendSymbol = (this || _global).chart.renderer.rect(e ? (a.symbolWidth - b) / 2 : 0, a.baseline - b + 1, e ? b : a.symbolWidth, b, k(a.options.symbolRadius, b / 2)).addClass("highcharts-point").attr({
          zIndex: 3
        }).add(c.legendGroup);
      },
      drawLineMarker: function (a) {
        var b = (this || _global).options,
            c = b.marker,
            e = a.symbolWidth,
            p = a.symbolHeight,
            h = p / 2,
            f = (this || _global).chart.renderer,
            n = (this || _global).legendGroup;
        a = a.baseline - Math.round(0.3 * a.fontMetrics.b);
        var t;
        t = {
          "stroke-width": b.lineWidth || 0
        };
        b.dashStyle && (t.dashstyle = b.dashStyle);
        (this || _global).legendLine = f.path(["M", 0, a, "L", e, a]).addClass("highcharts-graph").attr(t).add(n);
        c && !1 !== c.enabled && (b = Math.min(k(c.radius, h), h), 0 === (this || _global).symbol.indexOf("url") && (c = l(c, {
          width: p,
          height: p
        }), b = 0), (this || _global).legendSymbol = c = f.symbol((this || _global).symbol, e / 2 - b, a - b, 2 * b, 2 * b, c).addClass("highcharts-point").add(n), c.isMarker = !0);
      }
    };
    (/Trident\/7\.0/.test(p.navigator.userAgent) || n) && H(B.prototype, "positionItem", function (a, c) {
      var b = this || _global,
          e = function () {
        c._legendItemPos && a.call(b, c);
      };

      e();
      setTimeout(e);
    });
  })(I);

  (function (a) {
    var B = a.addEvent,
        z = a.animate,
        C = a.animObject,
        E = a.attr,
        u = a.doc,
        h = a.Axis,
        n = a.createElement,
        t = a.defaultOptions,
        l = a.discardElement,
        k = a.charts,
        e = a.css,
        c = a.defined,
        p = a.each,
        H = a.extend,
        b = a.find,
        m = a.fireEvent,
        d = a.getStyle,
        r = a.grep,
        A = a.isNumber,
        v = a.isObject,
        f = a.isString,
        y = a.Legend,
        G = a.marginNames,
        F = a.merge,
        q = a.Pointer,
        x = a.pick,
        J = a.pInt,
        K = a.removeEvent,
        L = a.seriesTypes,
        g = a.splat,
        D = a.svg,
        S = a.syncTimeout,
        M = a.win,
        R = a.Renderer,
        N = a.Chart = function () {
      (this || _global).getArgs.apply(this || _global, arguments);
    };

    a.chart = function (a, b, c) {
      return new N(a, b, c);
    };

    N.prototype = {
      callbacks: [],
      getArgs: function () {
        var a = [].slice.call(arguments);
        if (f(a[0]) || a[0].nodeName) (this || _global).renderTo = a.shift();
        this.init(a[0], a[1]);
      },
      init: function (b, c) {
        var d,
            f = b.series;
        b.series = null;
        d = F(t, b);
        d.series = b.series = f;
        (this || _global).userOptions = b;
        b = d.chart;
        f = b.events;
        (this || _global).margin = [];
        (this || _global).spacing = [];
        (this || _global).bounds = {
          h: {},
          v: {}
        };
        (this || _global).callback = c;
        (this || _global).isResizing = 0;
        (this || _global).options = d;
        (this || _global).axes = [];
        (this || _global).series = [];
        (this || _global).hasCartesianSeries = b.showAxes;
        var g;
        (this || _global).index = k.length;
        k.push(this || _global);
        a.chartCount++;
        if (f) for (g in f) B(this || _global, g, f[g]);
        (this || _global).xAxis = [];
        (this || _global).yAxis = [];
        (this || _global).pointCount = (this || _global).colorCounter = (this || _global).symbolCounter = 0;
        this.firstRender();
      },
      initSeries: function (b) {
        var c = (this || _global).options.chart;
        (c = L[b.type || c.type || c.defaultSeriesType]) || a.error(17, !0);
        c = new c();
        c.init(this || _global, b);
        return c;
      },
      orderSeries: function (a) {
        var b = (this || _global).series;

        for (a = a || 0; a < b.length; a++) b[a] && (b[a].index = a, b[a].name = b[a].name || "Series " + (b[a].index + 1));
      },
      isInsidePlot: function (a, b, c) {
        var d = c ? b : a;
        a = c ? a : b;
        return 0 <= d && d <= (this || _global).plotWidth && 0 <= a && a <= (this || _global).plotHeight;
      },
      redraw: function (b) {
        var c = (this || _global).axes,
            d = (this || _global).series,
            f = (this || _global).pointer,
            g = (this || _global).legend,
            e = (this || _global).isDirtyLegend,
            q,
            k,
            h = (this || _global).hasCartesianSeries,
            x = (this || _global).isDirtyBox,
            l,
            w = (this || _global).renderer,
            r = w.isHidden(),
            n = [];
        (this || _global).setResponsive && this.setResponsive(!1);
        a.setAnimation(b, this || _global);
        r && this.cloneRenderTo();
        this.layOutTitles();

        for (b = d.length; b--;) if (l = d[b], l.options.stacking && (q = !0, l.isDirty)) {
          k = !0;
          break;
        }

        if (k) for (b = d.length; b--;) l = d[b], l.options.stacking && (l.isDirty = !0);
        p(d, function (a) {
          a.isDirty && "point" === a.options.legendType && (a.updateTotals && a.updateTotals(), e = !0);
          a.isDirtyData && m(a, "updatedData");
        });
        e && g.options.enabled && (g.render(), (this || _global).isDirtyLegend = !1);
        q && this.getStacks();
        h && p(c, function (a) {
          a.updateNames();
          a.setScale();
        });
        this.getMargins();
        h && (p(c, function (a) {
          a.isDirty && (x = !0);
        }), p(c, function (a) {
          var b = a.min + "," + a.max;
          a.extKey !== b && (a.extKey = b, n.push(function () {
            m(a, "afterSetExtremes", H(a.eventArgs, a.getExtremes()));
            delete a.eventArgs;
          }));
          (x || q) && a.redraw();
        }));
        x && this.drawChartBox();
        m(this || _global, "predraw");
        p(d, function (a) {
          (x || a.isDirty) && a.visible && a.redraw();
          a.isDirtyData = !1;
        });
        f && f.reset(!0);
        w.draw();
        m(this || _global, "redraw");
        m(this || _global, "render");
        r && this.cloneRenderTo(!0);
        p(n, function (a) {
          a.call();
        });
      },
      get: function (a) {
        function c(b) {
          return b.id === a || b.options && b.options.id === a;
        }

        var d,
            f = (this || _global).series,
            g;
        d = b((this || _global).axes, c) || b((this || _global).series, c);

        for (g = 0; !d && g < f.length; g++) d = b(f[g].points || [], c);

        return d;
      },
      getAxes: function () {
        var a = this || _global,
            b = (this || _global).options,
            c = b.xAxis = g(b.xAxis || {}),
            b = b.yAxis = g(b.yAxis || {});
        p(c, function (a, b) {
          a.index = b;
          a.isX = !0;
        });
        p(b, function (a, b) {
          a.index = b;
        });
        c = c.concat(b);
        p(c, function (b) {
          new h(a, b);
        });
      },
      getSelectedPoints: function () {
        var a = [];
        p((this || _global).series, function (b) {
          a = a.concat(r(b.points || [], function (a) {
            return a.selected;
          }));
        });
        return a;
      },
      getSelectedSeries: function () {
        return r((this || _global).series, function (a) {
          return a.selected;
        });
      },
      setTitle: function (a, b, c) {
        var d = this || _global,
            f = d.options,
            g;
        g = f.title = F({
          style: {
            color: "#333333",
            fontSize: f.isStock ? "16px" : "18px"
          }
        }, f.title, a);
        f = f.subtitle = F({
          style: {
            color: "#666666"
          }
        }, f.subtitle, b);
        p([["title", a, g], ["subtitle", b, f]], function (a, b) {
          var c = a[0],
              f = d[c],
              g = a[1];
          a = a[2];
          f && g && (d[c] = f = f.destroy());
          a && a.text && !f && (d[c] = d.renderer.text(a.text, 0, 0, a.useHTML).attr({
            align: a.align,
            "class": "highcharts-" + c,
            zIndex: a.zIndex || 4
          }).add(), d[c].update = function (a) {
            d.setTitle(!b && a, b && a);
          }, d[c].css(a.style));
        });
        d.layOutTitles(c);
      },
      layOutTitles: function (a) {
        var b = 0,
            c,
            d = (this || _global).renderer,
            f = (this || _global).spacingBox;
        p(["title", "subtitle"], function (a) {
          var c = (this || _global)[a],
              g = (this || _global).options[a],
              e;
          c && (e = g.style.fontSize, e = d.fontMetrics(e, c).b, c.css({
            width: (g.width || f.width + g.widthAdjust) + "px"
          }).align(H({
            y: b + e + ("title" === a ? -3 : 2)
          }, g), !1, "spacingBox"), g.floating || g.verticalAlign || (b = Math.ceil(b + c.getBBox().height)));
        }, this || _global);
        c = (this || _global).titleOffset !== b;
        (this || _global).titleOffset = b;
        !(this || _global).isDirtyBox && c && ((this || _global).isDirtyBox = c, (this || _global).hasRendered && x(a, !0) && (this || _global).isDirtyBox && this.redraw());
      },
      getChartSize: function () {
        var b = (this || _global).options.chart,
            f = b.width,
            b = b.height,
            g = (this || _global).renderToClone || (this || _global).renderTo;
        c(f) || ((this || _global).containerWidth = d(g, "width"));
        c(b) || ((this || _global).containerHeight = d(g, "height"));
        (this || _global).chartWidth = Math.max(0, f || (this || _global).containerWidth || 600);
        (this || _global).chartHeight = Math.max(0, a.relativeLength(b, (this || _global).chartWidth) || (this || _global).containerHeight || 400);
      },
      cloneRenderTo: function (a) {
        var b = (this || _global).renderToClone,
            c = (this || _global).container;

        if (a) {
          if (b) {
            for (; b.childNodes.length;) (this || _global).renderTo.appendChild(b.firstChild);

            l(b);
            delete (this || _global).renderToClone;
          }
        } else c && c.parentNode === (this || _global).renderTo && (this || _global).renderTo.removeChild(c), (this || _global).renderToClone = b = (this || _global).renderTo.cloneNode(0), e(b, {
          position: "absolute",
          top: "-9999px",
          display: "block"
        }), b.style.setProperty && b.style.setProperty("display", "block", "important"), u.body.appendChild(b), c && b.appendChild(c);
      },
      setClassName: function (a) {
        (this || _global).container.className = "highcharts-container " + (a || "");
      },
      getContainer: function () {
        var b,
            c = (this || _global).options,
            d = c.chart,
            g,
            e;
        b = (this || _global).renderTo;
        var q = a.uniqueKey(),
            m;
        b || ((this || _global).renderTo = b = d.renderTo);
        f(b) && ((this || _global).renderTo = b = u.getElementById(b));
        b || a.error(13, !0);
        g = J(E(b, "data-highcharts-chart"));
        A(g) && k[g] && k[g].hasRendered && k[g].destroy();
        E(b, "data-highcharts-chart", (this || _global).index);
        b.innerHTML = "";
        d.skipClone || b.offsetWidth || this.cloneRenderTo();
        this.getChartSize();
        g = (this || _global).chartWidth;
        e = (this || _global).chartHeight;
        m = H({
          position: "relative",
          overflow: "hidden",
          width: g + "px",
          height: e + "px",
          textAlign: "left",
          lineHeight: "normal",
          zIndex: 0,
          "-webkit-tap-highlight-color": "rgba(0,0,0,0)"
        }, d.style);
        (this || _global).container = b = n("div", {
          id: q
        }, m, (this || _global).renderToClone || b);
        (this || _global)._cursor = b.style.cursor;
        (this || _global).renderer = new (a[d.renderer] || R)(b, g, e, null, d.forExport, c.exporting && c.exporting.allowHTML);
        this.setClassName(d.className);

        (this || _global).renderer.setStyle(d.style);

        (this || _global).renderer.chartIndex = (this || _global).index;
      },
      getMargins: function (a) {
        var b = (this || _global).spacing,
            d = (this || _global).margin,
            f = (this || _global).titleOffset;
        this.resetMargins();
        f && !c(d[0]) && ((this || _global).plotTop = Math.max((this || _global).plotTop, f + (this || _global).options.title.margin + b[0]));
        (this || _global).legend.display && (this || _global).legend.adjustMargins(d, b);
        (this || _global).extraMargin && ((this || _global)[(this || _global).extraMargin.type] = ((this || _global)[(this || _global).extraMargin.type] || 0) + (this || _global).extraMargin.value);
        (this || _global).extraTopMargin && ((this || _global).plotTop += (this || _global).extraTopMargin);
        a || this.getAxisMargins();
      },
      getAxisMargins: function () {
        var a = this || _global,
            b = a.axisOffset = [0, 0, 0, 0],
            d = a.margin;
        a.hasCartesianSeries && p(a.axes, function (a) {
          a.visible && a.getOffset();
        });
        p(G, function (f, g) {
          c(d[g]) || (a[f] += b[g]);
        });
        a.setChartSize();
      },
      reflow: function (a) {
        var b = this || _global,
            f = b.options.chart,
            g = b.renderTo,
            e = c(f.width),
            q = f.width || d(g, "width"),
            f = f.height || d(g, "height"),
            g = a ? a.target : M;

        if (!e && !b.isPrinting && q && f && (g === M || g === u)) {
          if (q !== b.containerWidth || f !== b.containerHeight) clearTimeout(b.reflowTimeout), b.reflowTimeout = S(function () {
            b.container && b.setSize(void 0, void 0, !1);
          }, a ? 100 : 0);
          b.containerWidth = q;
          b.containerHeight = f;
        }
      },
      initReflow: function () {
        var a = this || _global,
            b;
        b = B(M, "resize", function (b) {
          a.reflow(b);
        });
        B(a, "destroy", b);
      },
      setSize: function (b, c, d) {
        var f = this || _global,
            g = f.renderer;
        f.isResizing += 1;
        a.setAnimation(d, f);
        f.oldChartHeight = f.chartHeight;
        f.oldChartWidth = f.chartWidth;
        void 0 !== b && (f.options.chart.width = b);
        void 0 !== c && (f.options.chart.height = c);
        f.getChartSize();
        b = g.globalAnimation;
        (b ? z : e)(f.container, {
          width: f.chartWidth + "px",
          height: f.chartHeight + "px"
        }, b);
        f.setChartSize(!0);
        g.setSize(f.chartWidth, f.chartHeight, d);
        p(f.axes, function (a) {
          a.isDirty = !0;
          a.setScale();
        });
        f.isDirtyLegend = !0;
        f.isDirtyBox = !0;
        f.layOutTitles();
        f.getMargins();
        f.redraw(d);
        f.oldChartHeight = null;
        m(f, "resize");
        S(function () {
          f && m(f, "endResize", null, function () {
            --f.isResizing;
          });
        }, C(b).duration);
      },
      setChartSize: function (a) {
        var b = (this || _global).inverted,
            c = (this || _global).renderer,
            d = (this || _global).chartWidth,
            f = (this || _global).chartHeight,
            g = (this || _global).options.chart,
            e = (this || _global).spacing,
            q = (this || _global).clipOffset,
            k,
            m,
            h,
            x;
        (this || _global).plotLeft = k = Math.round((this || _global).plotLeft);
        (this || _global).plotTop = m = Math.round((this || _global).plotTop);
        (this || _global).plotWidth = h = Math.max(0, Math.round(d - k - (this || _global).marginRight));
        (this || _global).plotHeight = x = Math.max(0, Math.round(f - m - (this || _global).marginBottom));
        (this || _global).plotSizeX = b ? x : h;
        (this || _global).plotSizeY = b ? h : x;
        (this || _global).plotBorderWidth = g.plotBorderWidth || 0;
        (this || _global).spacingBox = c.spacingBox = {
          x: e[3],
          y: e[0],
          width: d - e[3] - e[1],
          height: f - e[0] - e[2]
        };
        (this || _global).plotBox = c.plotBox = {
          x: k,
          y: m,
          width: h,
          height: x
        };
        d = 2 * Math.floor((this || _global).plotBorderWidth / 2);
        b = Math.ceil(Math.max(d, q[3]) / 2);
        c = Math.ceil(Math.max(d, q[0]) / 2);
        (this || _global).clipBox = {
          x: b,
          y: c,
          width: Math.floor((this || _global).plotSizeX - Math.max(d, q[1]) / 2 - b),
          height: Math.max(0, Math.floor((this || _global).plotSizeY - Math.max(d, q[2]) / 2 - c))
        };
        a || p((this || _global).axes, function (a) {
          a.setAxisSize();
          a.setAxisTranslation();
        });
      },
      resetMargins: function () {
        var a = this || _global,
            b = a.options.chart;
        p(["margin", "spacing"], function (c) {
          var d = b[c],
              f = v(d) ? d : [d, d, d, d];
          p(["Top", "Right", "Bottom", "Left"], function (d, g) {
            a[c][g] = x(b[c + d], f[g]);
          });
        });
        p(G, function (b, c) {
          a[b] = x(a.margin[c], a.spacing[c]);
        });
        a.axisOffset = [0, 0, 0, 0];
        a.clipOffset = [0, 0, 0, 0];
      },
      drawChartBox: function () {
        var a = (this || _global).options.chart,
            b = (this || _global).renderer,
            c = (this || _global).chartWidth,
            d = (this || _global).chartHeight,
            f = (this || _global).chartBackground,
            g = (this || _global).plotBackground,
            e = (this || _global).plotBorder,
            q,
            k = (this || _global).plotBGImage,
            m = a.backgroundColor,
            p = a.plotBackgroundColor,
            h = a.plotBackgroundImage,
            x,
            l = (this || _global).plotLeft,
            r = (this || _global).plotTop,
            n = (this || _global).plotWidth,
            v = (this || _global).plotHeight,
            t = (this || _global).plotBox,
            y = (this || _global).clipRect,
            u = (this || _global).clipBox,
            J = "animate";
        f || ((this || _global).chartBackground = f = b.rect().addClass("highcharts-background").add(), J = "attr");
        q = a.borderWidth || 0;
        x = q + (a.shadow ? 8 : 0);
        m = {
          fill: m || "none"
        };
        if (q || f["stroke-width"]) m.stroke = a.borderColor, m["stroke-width"] = q;
        f.attr(m).shadow(a.shadow);
        f[J]({
          x: x / 2,
          y: x / 2,
          width: c - x - q % 2,
          height: d - x - q % 2,
          r: a.borderRadius
        });
        J = "animate";
        g || (J = "attr", (this || _global).plotBackground = g = b.rect().addClass("highcharts-plot-background").add());
        g[J](t);
        g.attr({
          fill: p || "none"
        }).shadow(a.plotShadow);
        h && (k ? k.animate(t) : (this || _global).plotBGImage = b.image(h, l, r, n, v).add());
        y ? y.animate({
          width: u.width,
          height: u.height
        }) : (this || _global).clipRect = b.clipRect(u);
        J = "animate";
        e || (J = "attr", (this || _global).plotBorder = e = b.rect().addClass("highcharts-plot-border").attr({
          zIndex: 1
        }).add());
        e.attr({
          stroke: a.plotBorderColor,
          "stroke-width": a.plotBorderWidth || 0,
          fill: "none"
        });
        e[J](e.crisp({
          x: l,
          y: r,
          width: n,
          height: v
        }, -e.strokeWidth()));
        (this || _global).isDirtyBox = !1;
      },
      propFromSeries: function () {
        var a = this || _global,
            b = a.options.chart,
            c,
            d = a.options.series,
            f,
            g;
        p(["inverted", "angular", "polar"], function (e) {
          c = L[b.type || b.defaultSeriesType];
          g = b[e] || c && c.prototype[e];

          for (f = d && d.length; !g && f--;) (c = L[d[f].type]) && c.prototype[e] && (g = !0);

          a[e] = g;
        });
      },
      linkSeries: function () {
        var a = this || _global,
            b = a.series;
        p(b, function (a) {
          a.linkedSeries.length = 0;
        });
        p(b, function (b) {
          var c = b.options.linkedTo;
          f(c) && (c = ":previous" === c ? a.series[b.index - 1] : a.get(c)) && c.linkedParent !== b && (c.linkedSeries.push(b), b.linkedParent = c, b.visible = x(b.options.visible, c.options.visible, b.visible));
        });
      },
      renderSeries: function () {
        p((this || _global).series, function (a) {
          a.translate();
          a.render();
        });
      },
      renderLabels: function () {
        var a = this || _global,
            b = a.options.labels;
        b.items && p(b.items, function (c) {
          var d = H(b.style, c.style),
              f = J(d.left) + a.plotLeft,
              g = J(d.top) + a.plotTop + 12;
          delete d.left;
          delete d.top;
          a.renderer.text(c.html, f, g).attr({
            zIndex: 2
          }).css(d).add();
        });
      },
      render: function () {
        var a = (this || _global).axes,
            b = (this || _global).renderer,
            c = (this || _global).options,
            d,
            f,
            g;
        this.setTitle();
        (this || _global).legend = new y(this || _global, c.legend);
        (this || _global).getStacks && this.getStacks();
        this.getMargins(!0);
        this.setChartSize();
        c = (this || _global).plotWidth;
        d = (this || _global).plotHeight -= 21;
        p(a, function (a) {
          a.setScale();
        });
        this.getAxisMargins();
        f = 1.1 < c / (this || _global).plotWidth;
        g = 1.05 < d / (this || _global).plotHeight;
        if (f || g) p(a, function (a) {
          (a.horiz && f || !a.horiz && g) && a.setTickInterval(!0);
        }), this.getMargins();
        this.drawChartBox();
        (this || _global).hasCartesianSeries && p(a, function (a) {
          a.visible && a.render();
        });
        (this || _global).seriesGroup || ((this || _global).seriesGroup = b.g("series-group").attr({
          zIndex: 3
        }).add());
        this.renderSeries();
        this.renderLabels();
        this.addCredits();
        (this || _global).setResponsive && this.setResponsive();
        (this || _global).hasRendered = !0;
      },
      addCredits: function (a) {
        var b = this || _global;
        a = F(!0, (this || _global).options.credits, a);
        a.enabled && !(this || _global).credits && ((this || _global).credits = (this || _global).renderer.text(a.text + ((this || _global).mapCredits || ""), 0, 0).addClass("highcharts-credits").on("click", function () {
          a.href && (M.location.href = a.href);
        }).attr({
          align: a.position.align,
          zIndex: 8
        }).css(a.style).add().align(a.position), (this || _global).credits.update = function (a) {
          b.credits = b.credits.destroy();
          b.addCredits(a);
        });
      },
      destroy: function () {
        var b = this || _global,
            c = b.axes,
            d = b.series,
            f = b.container,
            g,
            e = f && f.parentNode;
        m(b, "destroy");
        k[b.index] = void 0;
        a.chartCount--;
        b.renderTo.removeAttribute("data-highcharts-chart");
        K(b);

        for (g = c.length; g--;) c[g] = c[g].destroy();

        (this || _global).scroller && (this || _global).scroller.destroy && (this || _global).scroller.destroy();

        for (g = d.length; g--;) d[g] = d[g].destroy();

        p("title subtitle chartBackground plotBackground plotBGImage plotBorder seriesGroup clipRect credits pointer rangeSelector legend resetZoomButton tooltip renderer".split(" "), function (a) {
          var c = b[a];
          c && c.destroy && (b[a] = c.destroy());
        });
        f && (f.innerHTML = "", K(f), e && l(f));

        for (g in b) delete b[g];
      },
      isReadyToRender: function () {
        var a = this || _global;
        return D || M != M.top || "complete" === u.readyState ? !0 : (u.attachEvent("onreadystatechange", function () {
          u.detachEvent("onreadystatechange", a.firstRender);
          "complete" === u.readyState && a.firstRender();
        }), !1);
      },
      firstRender: function () {
        var a = this || _global,
            b = a.options;

        if (a.isReadyToRender()) {
          a.getContainer();
          m(a, "init");
          a.resetMargins();
          a.setChartSize();
          a.propFromSeries();
          a.getAxes();
          p(b.series || [], function (b) {
            a.initSeries(b);
          });
          a.linkSeries();
          m(a, "beforeRender");
          q && (a.pointer = new q(a, b));
          a.render();
          if (!a.renderer.imgCount && a.onload) a.onload();
          a.cloneRenderTo(!0);
        }
      },
      onload: function () {
        p([(this || _global).callback].concat((this || _global).callbacks), function (a) {
          a && void 0 !== (this || _global).index && a.apply(this || _global, [this || _global]);
        }, this || _global);
        m(this || _global, "load");
        m(this || _global, "render");
        c((this || _global).index) && !1 !== (this || _global).options.chart.reflow && this.initReflow();
        (this || _global).onload = null;
      }
    };
  })(I);

  (function (a) {
    var B,
        z = a.each,
        C = a.extend,
        E = a.erase,
        u = a.fireEvent,
        h = a.format,
        n = a.isArray,
        t = a.isNumber,
        l = a.pick,
        k = a.removeEvent;

    B = a.Point = function () {};

    B.prototype = {
      init: function (a, c, k) {
        (this || _global).series = a;
        (this || _global).color = a.color;
        this.applyOptions(c, k);
        a.options.colorByPoint ? (c = a.options.colors || a.chart.options.colors, (this || _global).color = (this || _global).color || c[a.colorCounter], c = c.length, k = a.colorCounter, a.colorCounter++, a.colorCounter === c && (a.colorCounter = 0)) : k = a.colorIndex;
        (this || _global).colorIndex = l((this || _global).colorIndex, k);
        a.chart.pointCount++;
        return this || _global;
      },
      applyOptions: function (a, c) {
        var e = (this || _global).series,
            k = e.options.pointValKey || e.pointValKey;
        a = B.prototype.optionsToObject.call(this || _global, a);
        C(this || _global, a);
        (this || _global).options = (this || _global).options ? C((this || _global).options, a) : a;
        a.group && delete (this || _global).group;
        k && ((this || _global).y = (this || _global)[k]);
        (this || _global).isNull = l((this || _global).isValid && !this.isValid(), null === (this || _global).x || !t((this || _global).y, !0));
        (this || _global).selected && ((this || _global).state = "select");
        "name" in (this || _global) && void 0 === c && e.xAxis && e.xAxis.hasNames && ((this || _global).x = e.xAxis.nameToX(this || _global));
        void 0 === (this || _global).x && e && ((this || _global).x = void 0 === c ? e.autoIncrement(this || _global) : c);
        return this || _global;
      },
      optionsToObject: function (a) {
        var c = {},
            e = (this || _global).series,
            k = e.options.keys,
            b = k || e.pointArrayMap || ["y"],
            m = b.length,
            d = 0,
            h = 0;
        if (t(a) || null === a) c[b[0]] = a;else if (n(a)) for (!k && a.length > m && (e = typeof a[0], "string" === e ? c.name = a[0] : "number" === e && (c.x = a[0]), d++); h < m;) k && void 0 === a[d] || (c[b[h]] = a[d]), d++, h++;else "object" === typeof a && (c = a, a.dataLabels && (e._hasPointLabels = !0), a.marker && (e._hasPointMarkers = !0));
        return c;
      },
      getClassName: function () {
        return "highcharts-point" + ((this || _global).selected ? " highcharts-point-select" : "") + ((this || _global).negative ? " highcharts-negative" : "") + ((this || _global).isNull ? " highcharts-null-point" : "") + (void 0 !== (this || _global).colorIndex ? " highcharts-color-" + (this || _global).colorIndex : "") + ((this || _global).options.className ? " " + (this || _global).options.className : "") + ((this || _global).zone && (this || _global).zone.className ? " " + (this || _global).zone.className.replace("highcharts-negative", "") : "");
      },
      getZone: function () {
        var a = (this || _global).series,
            c = a.zones,
            a = a.zoneAxis || "y",
            k = 0,
            h;

        for (h = c[k]; (this || _global)[a] >= h.value;) h = c[++k];

        h && h.color && !(this || _global).options.color && ((this || _global).color = h.color);
        return h;
      },
      destroy: function () {
        var a = (this || _global).series.chart,
            c = a.hoverPoints,
            h;
        a.pointCount--;
        c && (this.setState(), E(c, this || _global), c.length || (a.hoverPoints = null));
        if ((this || _global) === a.hoverPoint) this.onMouseOut();
        if ((this || _global).graphic || (this || _global).dataLabel) k(this || _global), this.destroyElements();
        (this || _global).legendItem && a.legend.destroyItem(this || _global);

        for (h in this || _global) (this || _global)[h] = null;
      },
      destroyElements: function () {
        for (var a = ["graphic", "dataLabel", "dataLabelUpper", "connector", "shadowGroup"], c, k = 6; k--;) c = a[k], (this || _global)[c] && ((this || _global)[c] = (this || _global)[c].destroy());
      },
      getLabelConfig: function () {
        return {
          x: (this || _global).category,
          y: (this || _global).y,
          color: (this || _global).color,
          colorIndex: (this || _global).colorIndex,
          key: (this || _global).name || (this || _global).category,
          series: (this || _global).series,
          point: this || _global,
          percentage: (this || _global).percentage,
          total: (this || _global).total || (this || _global).stackTotal
        };
      },
      tooltipFormatter: function (a) {
        var c = (this || _global).series,
            e = c.tooltipOptions,
            k = l(e.valueDecimals, ""),
            b = e.valuePrefix || "",
            m = e.valueSuffix || "";
        z(c.pointArrayMap || ["y"], function (c) {
          c = "{point." + c;
          if (b || m) a = a.replace(c + "}", b + c + "}" + m);
          a = a.replace(c + "}", c + ":,." + k + "f}");
        });
        return h(a, {
          point: this || _global,
          series: (this || _global).series
        });
      },
      firePointEvent: function (a, c, k) {
        var e = this || _global,
            b = (this || _global).series.options;
        (b.point.events[a] || e.options && e.options.events && e.options.events[a]) && this.importEvents();
        "click" === a && b.allowPointSelect && (k = function (a) {
          e.select && e.select(null, a.ctrlKey || a.metaKey || a.shiftKey);
        });
        u(this || _global, a, c, k);
      },
      visible: !0
    };
  })(I);

  (function (a) {
    var B = a.addEvent,
        z = a.animObject,
        C = a.arrayMax,
        E = a.arrayMin,
        u = a.correctFloat,
        h = a.Date,
        n = a.defaultOptions,
        t = a.defaultPlotOptions,
        l = a.defined,
        k = a.each,
        e = a.erase,
        c = a.extend,
        p = a.fireEvent,
        H = a.grep,
        b = a.isArray,
        m = a.isNumber,
        d = a.isString,
        r = a.merge,
        A = a.pick,
        v = a.removeEvent,
        f = a.splat,
        y = a.SVGElement,
        G = a.syncTimeout,
        F = a.win;
    a.Series = a.seriesType("line", null, {
      lineWidth: 2,
      allowPointSelect: !1,
      showCheckbox: !1,
      animation: {
        duration: 1000
      },
      events: {},
      marker: {
        lineWidth: 0,
        lineColor: "#ffffff",
        radius: 4,
        states: {
          hover: {
            animation: {
              duration: 50
            },
            enabled: !0,
            radiusPlus: 2,
            lineWidthPlus: 1
          },
          select: {
            fillColor: "#cccccc",
            lineColor: "#000000",
            lineWidth: 2
          }
        }
      },
      point: {
        events: {}
      },
      dataLabels: {
        align: "center",
        formatter: function () {
          return null === (this || _global).y ? "" : a.numberFormat((this || _global).y, -1);
        },
        style: {
          fontSize: "11px",
          fontWeight: "bold",
          color: "contrast",
          textOutline: "1px contrast"
        },
        verticalAlign: "bottom",
        x: 0,
        y: 0,
        padding: 5
      },
      cropThreshold: 300,
      pointRange: 0,
      softThreshold: !0,
      states: {
        hover: {
          animation: {
            duration: 50
          },
          lineWidthPlus: 1,
          marker: {},
          halo: {
            size: 10,
            opacity: 0.25
          }
        },
        select: {
          marker: {}
        }
      },
      stickyTracking: !0,
      turboThreshold: 1000
    }, {
      isCartesian: !0,
      pointClass: a.Point,
      sorted: !0,
      requireSorting: !0,
      directTouch: !1,
      axisTypes: ["xAxis", "yAxis"],
      colorCounter: 0,
      parallelArrays: ["x", "y"],
      coll: "series",
      init: function (a, b) {
        var d = this || _global,
            f,
            e,
            g = a.series,
            q;
        d.chart = a;
        d.options = b = d.setOptions(b);
        d.linkedSeries = [];
        d.bindAxes();
        c(d, {
          name: b.name,
          state: "",
          visible: !1 !== b.visible,
          selected: !0 === b.selected
        });
        e = b.events;

        for (f in e) B(d, f, e[f]);

        if (e && e.click || b.point && b.point.events && b.point.events.click || b.allowPointSelect) a.runTrackerClick = !0;
        d.getColor();
        d.getSymbol();
        k(d.parallelArrays, function (a) {
          d[a + "Data"] = [];
        });
        d.setData(b.data, !1);
        d.isCartesian && (a.hasCartesianSeries = !0);
        g.length && (q = g[g.length - 1]);
        d._i = A(q && q._i, -1) + 1;
        a.orderSeries(this.insert(g));
      },
      insert: function (a) {
        var b = (this || _global).options.index,
            c;

        if (m(b)) {
          for (c = a.length; c--;) if (b >= A(a[c].options.index, a[c]._i)) {
            a.splice(c + 1, 0, this || _global);
            break;
          }

          -1 === c && a.unshift(this || _global);
          c += 1;
        } else a.push(this || _global);

        return A(c, a.length - 1);
      },
      bindAxes: function () {
        var b = this || _global,
            c = b.options,
            d = b.chart,
            f;
        k(b.axisTypes || [], function (e) {
          k(d[e], function (a) {
            f = a.options;
            if (c[e] === f.index || void 0 !== c[e] && c[e] === f.id || void 0 === c[e] && 0 === f.index) b.insert(a.series), b[e] = a, a.isDirty = !0;
          });
          b[e] || b.optionalAxis === e || a.error(18, !0);
        });
      },
      updateParallelArrays: function (a, b) {
        var c = a.series,
            d = arguments,
            f = m(b) ? function (d) {
          var f = "y" === d && c.toYData ? c.toYData(a) : a[d];
          c[d + "Data"][b] = f;
        } : function (a) {
          Array.prototype[b].apply(c[a + "Data"], Array.prototype.slice.call(d, 2));
        };
        k(c.parallelArrays, f);
      },
      autoIncrement: function () {
        var a = (this || _global).options,
            b = (this || _global).xIncrement,
            c,
            d = a.pointIntervalUnit,
            b = A(b, a.pointStart, 0);
        (this || _global).pointInterval = c = A((this || _global).pointInterval, a.pointInterval, 1);
        d && (a = new h(b), "day" === d ? a = +a[h.hcSetDate](a[h.hcGetDate]() + c) : "month" === d ? a = +a[h.hcSetMonth](a[h.hcGetMonth]() + c) : "year" === d && (a = +a[h.hcSetFullYear](a[h.hcGetFullYear]() + c)), c = a - b);
        (this || _global).xIncrement = b + c;
        return b;
      },
      setOptions: function (a) {
        var b = (this || _global).chart,
            c = b.options.plotOptions,
            b = b.userOptions || {},
            d = b.plotOptions || {},
            f = c[(this || _global).type];
        (this || _global).userOptions = a;
        c = r(f, c.series, a);
        (this || _global).tooltipOptions = r(n.tooltip, n.plotOptions[(this || _global).type].tooltip, b.tooltip, d.series && d.series.tooltip, d[(this || _global).type] && d[(this || _global).type].tooltip, a.tooltip);
        null === f.marker && delete c.marker;
        (this || _global).zoneAxis = c.zoneAxis;
        a = (this || _global).zones = (c.zones || []).slice();
        !c.negativeColor && !c.negativeFillColor || c.zones || a.push({
          value: c[(this || _global).zoneAxis + "Threshold"] || c.threshold || 0,
          className: "highcharts-negative",
          color: c.negativeColor,
          fillColor: c.negativeFillColor
        });
        a.length && l(a[a.length - 1].value) && a.push({
          color: (this || _global).color,
          fillColor: (this || _global).fillColor
        });
        return c;
      },
      getCyclic: function (a, b, c) {
        var d,
            f = (this || _global).chart,
            g = (this || _global).userOptions,
            e = a + "Index",
            q = a + "Counter",
            k = c ? c.length : A(f.options.chart[a + "Count"], f[a + "Count"]);
        b || (d = A(g[e], g["_" + e]), l(d) || (f.series.length || (f[q] = 0), g["_" + e] = d = f[q] % k, f[q] += 1), c && (b = c[d]));
        void 0 !== d && ((this || _global)[e] = d);
        (this || _global)[a] = b;
      },
      getColor: function () {
        (this || _global).options.colorByPoint ? (this || _global).options.color = null : this.getCyclic("color", (this || _global).options.color || t[(this || _global).type].color, (this || _global).chart.options.colors);
      },
      getSymbol: function () {
        this.getCyclic("symbol", (this || _global).options.marker.symbol, (this || _global).chart.options.symbols);
      },
      drawLegendSymbol: a.LegendSymbolMixin.drawLineMarker,
      setData: function (c, f, e, h) {
        var q = this || _global,
            g = q.points,
            p = g && g.length || 0,
            l,
            x = q.options,
            r = q.chart,
            n = null,
            v = q.xAxis,
            t = x.turboThreshold,
            y = (this || _global).xData,
            u = (this || _global).yData,
            G = (l = q.pointArrayMap) && l.length;
        c = c || [];
        l = c.length;
        f = A(f, !0);
        if (!1 !== h && l && p === l && !q.cropped && !q.hasGroupedData && q.visible) k(c, function (a, b) {
          g[b].update && a !== x.data[b] && g[b].update(a, !1, null, !1);
        });else {
          q.xIncrement = null;
          q.colorCounter = 0;
          k((this || _global).parallelArrays, function (a) {
            q[a + "Data"].length = 0;
          });

          if (t && l > t) {
            for (e = 0; null === n && e < l;) n = c[e], e++;

            if (m(n)) for (e = 0; e < l; e++) y[e] = this.autoIncrement(), u[e] = c[e];else if (b(n)) {
              if (G) for (e = 0; e < l; e++) n = c[e], y[e] = n[0], u[e] = n.slice(1, G + 1);else for (e = 0; e < l; e++) n = c[e], y[e] = n[0], u[e] = n[1];
            } else a.error(12);
          } else for (e = 0; e < l; e++) void 0 !== c[e] && (n = {
            series: q
          }, q.pointClass.prototype.applyOptions.apply(n, [c[e]]), q.updateParallelArrays(n, e));

          d(u[0]) && a.error(14, !0);
          q.data = [];
          q.options.data = q.userOptions.data = c;

          for (e = p; e--;) g[e] && g[e].destroy && g[e].destroy();

          v && (v.minRange = v.userMinRange);
          q.isDirty = r.isDirtyBox = !0;
          q.isDirtyData = !!g;
          e = !1;
        }
        "point" === x.legendType && (this.processData(), this.generatePoints());
        f && r.redraw(e);
      },
      processData: function (b) {
        var c = (this || _global).xData,
            d = (this || _global).yData,
            f = c.length,
            e;
        e = 0;
        var g,
            q,
            k = (this || _global).xAxis,
            m,
            h = (this || _global).options;
        m = h.cropThreshold;
        var l = (this || _global).getExtremesFromAll || h.getExtremesFromAll,
            p = (this || _global).isCartesian,
            h = k && k.val2lin,
            n = k && k.isLog,
            r,
            v;
        if (p && !(this || _global).isDirty && !k.isDirty && !(this || _global).yAxis.isDirty && !b) return !1;
        k && (b = k.getExtremes(), r = b.min, v = b.max);
        if (p && (this || _global).sorted && !l && (!m || f > m || (this || _global).forceCrop)) if (c[f - 1] < r || c[0] > v) c = [], d = [];else if (c[0] < r || c[f - 1] > v) e = this.cropData((this || _global).xData, (this || _global).yData, r, v), c = e.xData, d = e.yData, e = e.start, g = !0;

        for (m = c.length || 1; --m;) f = n ? h(c[m]) - h(c[m - 1]) : c[m] - c[m - 1], 0 < f && (void 0 === q || f < q) ? q = f : 0 > f && (this || _global).requireSorting && a.error(15);

        (this || _global).cropped = g;
        (this || _global).cropStart = e;
        (this || _global).processedXData = c;
        (this || _global).processedYData = d;
        (this || _global).closestPointRange = q;
      },
      cropData: function (a, b, c, d) {
        var f = a.length,
            g = 0,
            e = f,
            q = A((this || _global).cropShoulder, 1),
            k;

        for (k = 0; k < f; k++) if (a[k] >= c) {
          g = Math.max(0, k - q);
          break;
        }

        for (c = k; c < f; c++) if (a[c] > d) {
          e = c + q;
          break;
        }

        return {
          xData: a.slice(g, e),
          yData: b.slice(g, e),
          start: g,
          end: e
        };
      },
      generatePoints: function () {
        var a = (this || _global).options.data,
            b = (this || _global).data,
            c,
            d = (this || _global).processedXData,
            e = (this || _global).processedYData,
            g = (this || _global).pointClass,
            k = d.length,
            m = (this || _global).cropStart || 0,
            h,
            l = (this || _global).hasGroupedData,
            p,
            n = [],
            r;
        b || l || (b = [], b.length = a.length, b = (this || _global).data = b);

        for (r = 0; r < k; r++) h = m + r, l ? (p = new g().init(this || _global, [d[r]].concat(f(e[r]))), p.dataGroup = (this || _global).groupMap[r]) : (p = b[h]) || void 0 === a[h] || (b[h] = p = new g().init(this || _global, a[h], d[r])), p.index = h, n[r] = p;

        if (b && (k !== (c = b.length) || l)) for (r = 0; r < c; r++) r !== m || l || (r += k), b[r] && (b[r].destroyElements(), b[r].plotX = void 0);
        (this || _global).data = b;
        (this || _global).points = n;
      },
      getExtremes: function (a) {
        var c = (this || _global).yAxis,
            d = (this || _global).processedXData,
            f,
            e = [],
            g = 0;
        f = (this || _global).xAxis.getExtremes();
        var q = f.min,
            k = f.max,
            h,
            l,
            p,
            r;
        a = a || (this || _global).stackedYData || (this || _global).processedYData || [];
        f = a.length;

        for (r = 0; r < f; r++) if (l = d[r], p = a[r], h = (m(p, !0) || b(p)) && (!c.positiveValuesOnly || p.length || 0 < p), l = (this || _global).getExtremesFromAll || (this || _global).options.getExtremesFromAll || (this || _global).cropped || (d[r + 1] || l) >= q && (d[r - 1] || l) <= k, h && l) if (h = p.length) for (; h--;) null !== p[h] && (e[g++] = p[h]);else e[g++] = p;

        (this || _global).dataMin = E(e);
        (this || _global).dataMax = C(e);
      },
      translate: function () {
        (this || _global).processedXData || this.processData();
        this.generatePoints();
        var a = (this || _global).options,
            b = a.stacking,
            c = (this || _global).xAxis,
            d = c.categories,
            f = (this || _global).yAxis,
            e = (this || _global).points,
            k = e.length,
            h = !!(this || _global).modifyValue,
            p = a.pointPlacement,
            r = "between" === p || m(p),
            n = a.threshold,
            v = a.startFromThreshold ? n : 0,
            t,
            y,
            G,
            F,
            H = Number.MAX_VALUE;
        "between" === p && (p = 0.5);
        m(p) && (p *= A(a.pointRange || c.pointRange));

        for (a = 0; a < k; a++) {
          var z = e[a],
              B = z.x,
              C = z.y;
          y = z.low;
          var E = b && f.stacks[((this || _global).negStacks && C < (v ? 0 : n) ? "-" : "") + (this || _global).stackKey],
              I;
          f.positiveValuesOnly && null !== C && 0 >= C && (z.isNull = !0);
          z.plotX = t = u(Math.min(Math.max(-100000, c.translate(B, 0, 0, 0, 1, p, "flags" === (this || _global).type)), 100000));
          b && (this || _global).visible && !z.isNull && E && E[B] && (F = this.getStackIndicator(F, B, (this || _global).index), I = E[B], C = I.points[F.key], y = C[0], C = C[1], y === v && F.key === E[B].base && (y = A(n, f.min)), f.positiveValuesOnly && 0 >= y && (y = null), z.total = z.stackTotal = I.total, z.percentage = I.total && z.y / I.total * 100, z.stackY = C, I.setOffset((this || _global).pointXOffset || 0, (this || _global).barW || 0));
          z.yBottom = l(y) ? f.translate(y, 0, 1, 0, 1) : null;
          h && (C = this.modifyValue(C, z));
          z.plotY = y = "number" === typeof C && Infinity !== C ? Math.min(Math.max(-100000, f.translate(C, 0, 1, 0, 1)), 100000) : void 0;
          z.isInside = void 0 !== y && 0 <= y && y <= f.len && 0 <= t && t <= c.len;
          z.clientX = r ? u(c.translate(B, 0, 0, 0, 1, p)) : t;
          z.negative = z.y < (n || 0);
          z.category = d && void 0 !== d[z.x] ? d[z.x] : z.x;
          z.isNull || (void 0 !== G && (H = Math.min(H, Math.abs(t - G))), G = t);
          z.zone = (this || _global).zones.length && z.getZone();
        }

        (this || _global).closestPointRangePx = H;
      },
      getValidPoints: function (a, b) {
        var c = (this || _global).chart;
        return H(a || (this || _global).points || [], function (a) {
          return b && !c.isInsidePlot(a.plotX, a.plotY, c.inverted) ? !1 : !a.isNull;
        });
      },
      setClip: function (a) {
        var b = (this || _global).chart,
            c = (this || _global).options,
            d = b.renderer,
            f = b.inverted,
            e = (this || _global).clipBox,
            q = e || b.clipBox,
            k = (this || _global).sharedClipKey || ["_sharedClip", a && a.duration, a && a.easing, q.height, c.xAxis, c.yAxis].join(),
            m = b[k],
            h = b[k + "m"];
        m || (a && (q.width = 0, b[k + "m"] = h = d.clipRect(-99, f ? -b.plotLeft : -b.plotTop, 99, f ? b.chartWidth : b.chartHeight)), b[k] = m = d.clipRect(q), m.count = {
          length: 0
        });
        a && !m.count[(this || _global).index] && (m.count[(this || _global).index] = !0, m.count.length += 1);
        !1 !== c.clip && ((this || _global).group.clip(a || e ? m : b.clipRect), (this || _global).markerGroup.clip(h), (this || _global).sharedClipKey = k);
        a || (m.count[(this || _global).index] && (delete m.count[(this || _global).index], --m.count.length), 0 === m.count.length && k && b[k] && (e || (b[k] = b[k].destroy()), b[k + "m"] && ((this || _global).markerGroup.clip(), b[k + "m"] = b[k + "m"].destroy())));
      },
      animate: function (a) {
        var b = (this || _global).chart,
            c = z((this || _global).options.animation),
            d;
        a ? this.setClip(c) : (d = (this || _global).sharedClipKey, (a = b[d]) && a.animate({
          width: b.plotSizeX
        }, c), b[d + "m"] && b[d + "m"].animate({
          width: b.plotSizeX + 99
        }, c), (this || _global).animate = null);
      },
      afterAnimate: function () {
        this.setClip();
        p(this || _global, "afterAnimate");
      },
      drawPoints: function () {
        var a = (this || _global).points,
            b = (this || _global).chart,
            c,
            d,
            f,
            e,
            k = (this || _global).options.marker,
            h,
            p,
            l,
            r,
            n = (this || _global).markerGroup,
            v = A(k.enabled, (this || _global).xAxis.isRadial ? !0 : null, (this || _global).closestPointRangePx >= 2 * k.radius);
        if (!1 !== k.enabled || (this || _global)._hasPointMarkers) for (d = 0; d < a.length; d++) f = a[d], c = f.plotY, e = f.graphic, h = f.marker || {}, p = !!f.marker, l = v && void 0 === h.enabled || h.enabled, r = f.isInside, l && m(c) && null !== f.y ? (c = A(h.symbol, (this || _global).symbol), f.hasImage = 0 === c.indexOf("url"), l = this.markerAttribs(f, f.selected && "select"), e ? e[r ? "show" : "hide"](!0).animate(l) : r && (0 < l.width || f.hasImage) && (f.graphic = e = b.renderer.symbol(c, l.x, l.y, l.width, l.height, p ? h : k).add(n)), e && e.attr(this.pointAttribs(f, f.selected && "select")), e && e.addClass(f.getClassName(), !0)) : e && (f.graphic = e.destroy());
      },
      markerAttribs: function (a, b) {
        var c = (this || _global).options.marker,
            d = a.marker || {},
            f = A(d.radius, c.radius);
        b && (c = c.states[b], b = d.states && d.states[b], f = A(b && b.radius, c && c.radius, f + (c && c.radiusPlus || 0)));
        a.hasImage && (f = 0);
        a = {
          x: Math.floor(a.plotX) - f,
          y: a.plotY - f
        };
        f && (a.width = a.height = 2 * f);
        return a;
      },
      pointAttribs: function (a, b) {
        var c = (this || _global).options.marker,
            d = a && a.options,
            f = d && d.marker || {},
            e = (this || _global).color,
            q = d && d.color,
            k = a && a.color,
            d = A(f.lineWidth, c.lineWidth);
        a = a && a.zone && a.zone.color;
        e = q || a || k || e;
        a = f.fillColor || c.fillColor || e;
        e = f.lineColor || c.lineColor || e;
        b && (c = c.states[b], b = f.states && f.states[b] || {}, d = A(b.lineWidth, c.lineWidth, d + A(b.lineWidthPlus, c.lineWidthPlus, 0)), a = b.fillColor || c.fillColor || a, e = b.lineColor || c.lineColor || e);
        return {
          stroke: e,
          "stroke-width": d,
          fill: a
        };
      },
      destroy: function () {
        var a = this || _global,
            b = a.chart,
            c = /AppleWebKit\/533/.test(F.navigator.userAgent),
            d,
            f = a.data || [],
            g,
            m,
            h;
        p(a, "destroy");
        v(a);
        k(a.axisTypes || [], function (b) {
          (h = a[b]) && h.series && (e(h.series, a), h.isDirty = h.forceRedraw = !0);
        });
        a.legendItem && a.chart.legend.destroyItem(a);

        for (d = f.length; d--;) (g = f[d]) && g.destroy && g.destroy();

        a.points = null;
        clearTimeout(a.animationTimeout);

        for (m in a) a[m] instanceof y && !a[m].survive && (d = c && "group" === m ? "hide" : "destroy", a[m][d]());

        b.hoverSeries === a && (b.hoverSeries = null);
        e(b.series, a);
        b.orderSeries();

        for (m in a) delete a[m];
      },
      getGraphPath: function (a, b, c) {
        var d = this || _global,
            f = d.options,
            e = f.step,
            q,
            m = [],
            h = [],
            p;
        a = a || d.points;
        (q = a.reversed) && a.reverse();
        (e = {
          right: 1,
          center: 2
        }[e] || e && 3) && q && (e = 4 - e);
        !f.connectNulls || b || c || (a = this.getValidPoints(a));
        k(a, function (g, k) {
          var q = g.plotX,
              r = g.plotY,
              n = a[k - 1];
          (g.leftCliff || n && n.rightCliff) && !c && (p = !0);
          g.isNull && !l(b) && 0 < k ? p = !f.connectNulls : g.isNull && !b ? p = !0 : (0 === k || p ? k = ["M", g.plotX, g.plotY] : d.getPointSpline ? k = d.getPointSpline(a, g, k) : e ? (k = 1 === e ? ["L", n.plotX, r] : 2 === e ? ["L", (n.plotX + q) / 2, n.plotY, "L", (n.plotX + q) / 2, r] : ["L", q, n.plotY], k.push("L", q, r)) : k = ["L", q, r], h.push(g.x), e && h.push(g.x), m.push.apply(m, k), p = !1);
        });
        m.xMap = h;
        return d.graphPath = m;
      },
      drawGraph: function () {
        var a = this || _global,
            b = (this || _global).options,
            c = ((this || _global).gappedPath || (this || _global).getGraphPath).call(this || _global),
            d = [["graph", "highcharts-graph", b.lineColor || (this || _global).color, b.dashStyle]];

        k((this || _global).zones, function (c, f) {
          d.push(["zone-graph-" + f, "highcharts-graph highcharts-zone-graph-" + f + " " + (c.className || ""), c.color || a.color, c.dashStyle || b.dashStyle]);
        });
        k(d, function (d, f) {
          var e = d[0],
              g = a[e];
          g ? (g.endX = c.xMap, g.animate({
            d: c
          })) : c.length && (a[e] = a.chart.renderer.path(c).addClass(d[1]).attr({
            zIndex: 1
          }).add(a.group), g = {
            stroke: d[2],
            "stroke-width": b.lineWidth,
            fill: a.fillGraph && a.color || "none"
          }, d[3] ? g.dashstyle = d[3] : "square" !== b.linecap && (g["stroke-linecap"] = g["stroke-linejoin"] = "round"), g = a[e].attr(g).shadow(2 > f && b.shadow));
          g && (g.startX = c.xMap, g.isArea = c.isArea);
        });
      },
      applyZones: function () {
        var a = this || _global,
            b = (this || _global).chart,
            c = b.renderer,
            d = (this || _global).zones,
            f,
            e,
            m = (this || _global).clips || [],
            h,
            p = (this || _global).graph,
            l = (this || _global).area,
            r = Math.max(b.chartWidth, b.chartHeight),
            n = (this || _global)[((this || _global).zoneAxis || "y") + "Axis"],
            v,
            t,
            y = b.inverted,
            u,
            G,
            F,
            H,
            z = !1;
        d.length && (p || l) && n && void 0 !== n.min && (t = n.reversed, u = n.horiz, p && p.hide(), l && l.hide(), v = n.getExtremes(), k(d, function (d, g) {
          f = t ? u ? b.plotWidth : 0 : u ? 0 : n.toPixels(v.min);
          f = Math.min(Math.max(A(e, f), 0), r);
          e = Math.min(Math.max(Math.round(n.toPixels(A(d.value, v.max), !0)), 0), r);
          z && (f = e = n.toPixels(v.max));
          G = Math.abs(f - e);
          F = Math.min(f, e);
          H = Math.max(f, e);
          n.isXAxis ? (h = {
            x: y ? H : F,
            y: 0,
            width: G,
            height: r
          }, u || (h.x = b.plotHeight - h.x)) : (h = {
            x: 0,
            y: y ? H : F,
            width: r,
            height: G
          }, u && (h.y = b.plotWidth - h.y));
          y && c.isVML && (h = n.isXAxis ? {
            x: 0,
            y: t ? F : H,
            height: h.width,
            width: b.chartWidth
          } : {
            x: h.y - b.plotLeft - b.spacingBox.x,
            y: 0,
            width: h.height,
            height: b.chartHeight
          });
          m[g] ? m[g].animate(h) : (m[g] = c.clipRect(h), p && a["zone-graph-" + g].clip(m[g]), l && a["zone-area-" + g].clip(m[g]));
          z = d.value > v.max;
        }), (this || _global).clips = m);
      },
      invertGroups: function (a) {
        function b() {
          k(["group", "markerGroup"], function (b) {
            c[b] && (d.renderer.isVML && c[b].attr({
              width: c.yAxis.len,
              height: c.xAxis.len
            }), c[b].width = c.yAxis.len, c[b].height = c.xAxis.len, c[b].invert(a));
          });
        }

        var c = this || _global,
            d = c.chart,
            f;
        c.xAxis && (f = B(d, "resize", b), B(c, "destroy", f), b(a), c.invertGroups = b);
      },
      plotGroup: function (a, b, c, d, f) {
        var e = (this || _global)[a],
            k = !e;
        k && ((this || _global)[a] = e = (this || _global).chart.renderer.g(b).attr({
          zIndex: d || 0.1
        }).add(f), e.addClass("highcharts-series-" + (this || _global).index + " highcharts-" + (this || _global).type + "-series highcharts-color-" + (this || _global).colorIndex + " " + ((this || _global).options.className || "")));
        e.attr({
          visibility: c
        })[k ? "attr" : "animate"](this.getPlotBox());
        return e;
      },
      getPlotBox: function () {
        var a = (this || _global).chart,
            b = (this || _global).xAxis,
            c = (this || _global).yAxis;
        a.inverted && (b = c, c = (this || _global).xAxis);
        return {
          translateX: b ? b.left : a.plotLeft,
          translateY: c ? c.top : a.plotTop,
          scaleX: 1,
          scaleY: 1
        };
      },
      render: function () {
        var a = this || _global,
            b = a.chart,
            c,
            d = a.options,
            f = !!a.animate && b.renderer.isSVG && z(d.animation).duration,
            e = a.visible ? "inherit" : "hidden",
            k = d.zIndex,
            m = a.hasRendered,
            h = b.seriesGroup,
            p = b.inverted;
        c = a.plotGroup("group", "series", e, k, h);
        a.markerGroup = a.plotGroup("markerGroup", "markers", e, k, h);
        f && a.animate(!0);
        c.inverted = a.isCartesian ? p : !1;
        a.drawGraph && (a.drawGraph(), a.applyZones());
        a.drawDataLabels && a.drawDataLabels();
        a.visible && a.drawPoints();
        a.drawTracker && !1 !== a.options.enableMouseTracking && a.drawTracker();
        a.invertGroups(p);
        !1 === d.clip || a.sharedClipKey || m || c.clip(b.clipRect);
        f && a.animate();
        m || (a.animationTimeout = G(function () {
          a.afterAnimate();
        }, f));
        a.isDirty = !1;
        a.hasRendered = !0;
      },
      redraw: function () {
        var a = (this || _global).chart,
            b = (this || _global).isDirty || (this || _global).isDirtyData,
            c = (this || _global).group,
            d = (this || _global).xAxis,
            f = (this || _global).yAxis;
        c && (a.inverted && c.attr({
          width: a.plotWidth,
          height: a.plotHeight
        }), c.animate({
          translateX: A(d && d.left, a.plotLeft),
          translateY: A(f && f.top, a.plotTop)
        }));
        this.translate();
        this.render();
        b && delete (this || _global).kdTree;
      },
      kdDimensions: 1,
      kdAxisArray: ["clientX", "plotY"],
      searchPoint: function (a, b) {
        var c = (this || _global).xAxis,
            d = (this || _global).yAxis,
            f = (this || _global).chart.inverted;
        return this.searchKDTree({
          clientX: f ? c.len - a.chartY + c.pos : a.chartX - c.pos,
          plotY: f ? d.len - a.chartX + d.pos : a.chartY - d.pos
        }, b);
      },
      buildKDTree: function () {
        function a(c, d, f) {
          var e, g;
          if (g = c && c.length) return e = b.kdAxisArray[d % f], c.sort(function (a, b) {
            return a[e] - b[e];
          }), g = Math.floor(g / 2), {
            point: c[g],
            left: a(c.slice(0, g), d + 1, f),
            right: a(c.slice(g + 1), d + 1, f)
          };
        }

        (this || _global).buildingKdTree = !0;
        var b = this || _global,
            c = b.kdDimensions;
        delete b.kdTree;
        G(function () {
          b.kdTree = a(b.getValidPoints(null, !b.directTouch), c, c);
          b.buildingKdTree = !1;
        }, b.options.kdNow ? 0 : 1);
      },
      searchKDTree: function (a, b) {
        function c(a, b, g, m) {
          var h = b.point,
              p = d.kdAxisArray[g % m],
              q,
              r,
              n = h;
          r = l(a[f]) && l(h[f]) ? Math.pow(a[f] - h[f], 2) : null;
          q = l(a[e]) && l(h[e]) ? Math.pow(a[e] - h[e], 2) : null;
          q = (r || 0) + (q || 0);
          h.dist = l(q) ? Math.sqrt(q) : Number.MAX_VALUE;
          h.distX = l(r) ? Math.sqrt(r) : Number.MAX_VALUE;
          p = a[p] - h[p];
          q = 0 > p ? "left" : "right";
          r = 0 > p ? "right" : "left";
          b[q] && (q = c(a, b[q], g + 1, m), n = q[k] < n[k] ? q : h);
          b[r] && Math.sqrt(p * p) < n[k] && (a = c(a, b[r], g + 1, m), n = a[k] < n[k] ? a : n);
          return n;
        }

        var d = this || _global,
            f = (this || _global).kdAxisArray[0],
            e = (this || _global).kdAxisArray[1],
            k = b ? "distX" : "dist";
        (this || _global).kdTree || (this || _global).buildingKdTree || this.buildKDTree();
        if ((this || _global).kdTree) return c(a, (this || _global).kdTree, (this || _global).kdDimensions, (this || _global).kdDimensions);
      }
    });
  })(I);

  (function (a) {
    function B(a, e, c, h, n) {
      var b = a.chart.inverted;
      (this || _global).axis = a;
      (this || _global).isNegative = c;
      (this || _global).options = e;
      (this || _global).x = h;
      (this || _global).total = null;
      (this || _global).points = {};
      (this || _global).stack = n;
      (this || _global).rightCliff = (this || _global).leftCliff = 0;
      (this || _global).alignOptions = {
        align: e.align || (b ? c ? "left" : "right" : "center"),
        verticalAlign: e.verticalAlign || (b ? "middle" : c ? "bottom" : "top"),
        y: l(e.y, b ? 4 : c ? 14 : -6),
        x: l(e.x, b ? c ? -6 : 6 : 0)
      };
      (this || _global).textAlign = e.textAlign || (b ? c ? "right" : "left" : "center");
    }

    var z = a.Axis,
        C = a.Chart,
        E = a.correctFloat,
        u = a.defined,
        h = a.destroyObjectProperties,
        n = a.each,
        t = a.format,
        l = a.pick;
    a = a.Series;
    B.prototype = {
      destroy: function () {
        h(this || _global, (this || _global).axis);
      },
      render: function (a) {
        var e = (this || _global).options,
            c = e.format,
            c = c ? t(c, this || _global) : e.formatter.call(this || _global);
        (this || _global).label ? (this || _global).label.attr({
          text: c,
          visibility: "hidden"
        }) : (this || _global).label = (this || _global).axis.chart.renderer.text(c, null, null, e.useHTML).css(e.style).attr({
          align: (this || _global).textAlign,
          rotation: e.rotation,
          visibility: "hidden"
        }).add(a);
      },
      setOffset: function (a, e) {
        var c = (this || _global).axis,
            k = c.chart,
            h = k.inverted,
            b = c.reversed,
            b = (this || _global).isNegative && !b || !(this || _global).isNegative && b,
            m = c.translate(c.usePercentage ? 100 : (this || _global).total, 0, 0, 0, 1),
            c = c.translate(0),
            c = Math.abs(m - c);
        a = k.xAxis[0].translate((this || _global).x) + a;
        var d = k.plotHeight,
            h = {
          x: h ? b ? m : m - c : a,
          y: h ? d - a - e : b ? d - m - c : d - m,
          width: h ? c : e,
          height: h ? e : c
        };
        if (e = (this || _global).label) e.align((this || _global).alignOptions, null, h), h = e.alignAttr, e[!1 === (this || _global).options.crop || k.isInsidePlot(h.x, h.y) ? "show" : "hide"](!0);
      }
    };

    C.prototype.getStacks = function () {
      var a = this || _global;
      n(a.yAxis, function (a) {
        a.stacks && a.hasVisibleSeries && (a.oldStacks = a.stacks);
      });
      n(a.series, function (e) {
        !e.options.stacking || !0 !== e.visible && !1 !== a.options.chart.ignoreHiddenSeries || (e.stackKey = e.type + l(e.options.stack, ""));
      });
    };

    z.prototype.buildStacks = function () {
      var a = (this || _global).series,
          e,
          c = l((this || _global).options.reversedStacks, !0),
          h = a.length,
          n;

      if (!(this || _global).isXAxis) {
        (this || _global).usePercentage = !1;

        for (n = h; n--;) a[c ? n : h - n - 1].setStackedPoints();

        for (n = h; n--;) e = a[c ? n : h - n - 1], e.setStackCliffs && e.setStackCliffs();

        if ((this || _global).usePercentage) for (n = 0; n < h; n++) a[n].setPercentStacks();
      }
    };

    z.prototype.renderStackTotals = function () {
      var a = (this || _global).chart,
          e = a.renderer,
          c = (this || _global).stacks,
          h,
          l,
          b = (this || _global).stackTotalGroup;
      b || ((this || _global).stackTotalGroup = b = e.g("stack-labels").attr({
        visibility: "visible",
        zIndex: 6
      }).add());
      b.translate(a.plotLeft, a.plotTop);

      for (h in c) for (l in a = c[h], a) a[l].render(b);
    };

    z.prototype.resetStacks = function () {
      var a = (this || _global).stacks,
          e,
          c;
      if (!(this || _global).isXAxis) for (e in a) for (c in a[e]) a[e][c].touched < (this || _global).stacksTouched ? (a[e][c].destroy(), delete a[e][c]) : (a[e][c].total = null, a[e][c].cum = null);
    };

    z.prototype.cleanStacks = function () {
      var a, e, c;
      if (!(this || _global).isXAxis) for (e in (this || _global).oldStacks && (a = (this || _global).stacks = (this || _global).oldStacks), a) for (c in a[e]) a[e][c].cum = a[e][c].total;
    };

    a.prototype.setStackedPoints = function () {
      if ((this || _global).options.stacking && (!0 === (this || _global).visible || !1 === (this || _global).chart.options.chart.ignoreHiddenSeries)) {
        var a = (this || _global).processedXData,
            e = (this || _global).processedYData,
            c = [],
            h = e.length,
            n = (this || _global).options,
            b = n.threshold,
            m = n.startFromThreshold ? b : 0,
            d = n.stack,
            n = n.stacking,
            r = (this || _global).stackKey,
            t = "-" + r,
            v = (this || _global).negStacks,
            f = (this || _global).yAxis,
            y = f.stacks,
            G = f.oldStacks,
            F,
            q,
            x,
            z,
            K,
            C,
            g;
        f.stacksTouched += 1;

        for (K = 0; K < h; K++) C = a[K], g = e[K], F = this.getStackIndicator(F, C, (this || _global).index), z = F.key, x = (q = v && g < (m ? 0 : b)) ? t : r, y[x] || (y[x] = {}), y[x][C] || (G[x] && G[x][C] ? (y[x][C] = G[x][C], y[x][C].total = null) : y[x][C] = new B(f, f.options.stackLabels, q, C, d)), x = y[x][C], null !== g && (x.points[z] = x.points[(this || _global).index] = [l(x.cum, m)], u(x.cum) || (x.base = z), x.touched = f.stacksTouched, 0 < F.index && !1 === (this || _global).singleStacks && (x.points[z][0] = x.points[(this || _global).index + "," + C + ",0"][0])), "percent" === n ? (q = q ? r : t, v && y[q] && y[q][C] ? (q = y[q][C], x.total = q.total = Math.max(q.total, x.total) + Math.abs(g) || 0) : x.total = E(x.total + (Math.abs(g) || 0))) : x.total = E(x.total + (g || 0)), x.cum = l(x.cum, m) + (g || 0), null !== g && (x.points[z].push(x.cum), c[K] = x.cum);

        "percent" === n && (f.usePercentage = !0);
        (this || _global).stackedYData = c;
        f.oldStacks = {};
      }
    };

    a.prototype.setPercentStacks = function () {
      var a = this || _global,
          e = a.stackKey,
          c = a.yAxis.stacks,
          h = a.processedXData,
          l;
      n([e, "-" + e], function (b) {
        for (var e = h.length, d, k; e--;) if (d = h[e], l = a.getStackIndicator(l, d, a.index, b), d = (k = c[b] && c[b][d]) && k.points[l.key]) k = k.total ? 100 / k.total : 0, d[0] = E(d[0] * k), d[1] = E(d[1] * k), a.stackedYData[e] = d[1];
      });
    };

    a.prototype.getStackIndicator = function (a, e, c, h) {
      !u(a) || a.x !== e || h && a.key !== h ? a = {
        x: e,
        index: 0,
        key: h
      } : a.index++;
      a.key = [c, e, a.index].join();
      return a;
    };
  })(I);

  (function (a) {
    var B = a.addEvent,
        z = a.animate,
        C = a.Axis,
        E = a.createElement,
        u = a.css,
        h = a.defined,
        n = a.each,
        t = a.erase,
        l = a.extend,
        k = a.fireEvent,
        e = a.inArray,
        c = a.isNumber,
        p = a.isObject,
        H = a.merge,
        b = a.pick,
        m = a.Point,
        d = a.Series,
        r = a.seriesTypes,
        A = a.setAnimation,
        v = a.splat;
    l(a.Chart.prototype, {
      addSeries: function (a, c, d) {
        var f,
            e = this || _global;
        a && (c = b(c, !0), k(e, "addSeries", {
          options: a
        }, function () {
          f = e.initSeries(a);
          e.isDirtyLegend = !0;
          e.linkSeries();
          c && e.redraw(d);
        }));
        return f;
      },
      addAxis: function (a, c, d, e) {
        var f = c ? "xAxis" : "yAxis",
            h = (this || _global).options;
        a = H(a, {
          index: (this || _global)[f].length,
          isX: c
        });
        new C(this || _global, a);
        h[f] = v(h[f] || {});
        h[f].push(a);
        b(d, !0) && this.redraw(e);
      },
      showLoading: function (a) {
        var b = this || _global,
            c = b.options,
            d = b.loadingDiv,
            f = c.loading,
            e = function () {
          d && u(d, {
            left: b.plotLeft + "px",
            top: b.plotTop + "px",
            width: b.plotWidth + "px",
            height: b.plotHeight + "px"
          });
        };

        d || (b.loadingDiv = d = E("div", {
          className: "highcharts-loading highcharts-loading-hidden"
        }, null, b.container), b.loadingSpan = E("span", {
          className: "highcharts-loading-inner"
        }, null, d), B(b, "redraw", e));
        d.className = "highcharts-loading";
        b.loadingSpan.innerHTML = a || c.lang.loading;
        u(d, l(f.style, {
          zIndex: 10
        }));
        u(b.loadingSpan, f.labelStyle);
        b.loadingShown || (u(d, {
          opacity: 0,
          display: ""
        }), z(d, {
          opacity: f.style.opacity || 0.5
        }, {
          duration: f.showDuration || 0
        }));
        b.loadingShown = !0;
        e();
      },
      hideLoading: function () {
        var a = (this || _global).options,
            b = (this || _global).loadingDiv;
        b && (b.className = "highcharts-loading highcharts-loading-hidden", z(b, {
          opacity: 0
        }, {
          duration: a.loading.hideDuration || 100,
          complete: function () {
            u(b, {
              display: "none"
            });
          }
        }));
        (this || _global).loadingShown = !1;
      },
      propsRequireDirtyBox: "backgroundColor borderColor borderWidth margin marginTop marginRight marginBottom marginLeft spacing spacingTop spacingRight spacingBottom spacingLeft borderRadius plotBackgroundColor plotBackgroundImage plotBorderColor plotBorderWidth plotShadow shadow".split(" "),
      propsRequireUpdateSeries: "chart.inverted chart.polar chart.ignoreHiddenSeries chart.type colors plotOptions".split(" "),
      update: function (a, d) {
        var f,
            k = {
          credits: "addCredits",
          title: "setTitle",
          subtitle: "setSubtitle"
        },
            m = a.chart,
            l,
            p;

        if (m) {
          H(!0, (this || _global).options.chart, m);
          "className" in m && this.setClassName(m.className);
          if ("inverted" in m || "polar" in m) this.propFromSeries(), l = !0;

          for (f in m) m.hasOwnProperty(f) && (-1 !== e("chart." + f, (this || _global).propsRequireUpdateSeries) && (p = !0), -1 !== e(f, (this || _global).propsRequireDirtyBox) && ((this || _global).isDirtyBox = !0));

          "style" in m && (this || _global).renderer.setStyle(m.style);
        }

        for (f in a) {
          if ((this || _global)[f] && "function" === typeof (this || _global)[f].update) (this || _global)[f].update(a[f], !1);else if ("function" === typeof (this || _global)[k[f]]) this[k[f]](a[f]);
          "chart" !== f && -1 !== e(f, (this || _global).propsRequireUpdateSeries) && (p = !0);
        }

        a.colors && ((this || _global).options.colors = a.colors);
        a.plotOptions && H(!0, (this || _global).options.plotOptions, a.plotOptions);
        n(["xAxis", "yAxis", "series"], function (b) {
          a[b] && n(v(a[b]), function (a, c) {
            (c = h(a.id) && this.get(a.id) || (this || _global)[b][c]) && c.coll === b && c.update(a, !1);
          }, this || _global);
        }, this || _global);
        l && n((this || _global).axes, function (a) {
          a.update({}, !1);
        });
        p && n((this || _global).series, function (a) {
          a.update({}, !1);
        });
        a.loading && H(!0, (this || _global).options.loading, a.loading);
        f = m && m.width;
        m = m && m.height;
        c(f) && f !== (this || _global).chartWidth || c(m) && m !== (this || _global).chartHeight ? this.setSize(f, m) : b(d, !0) && this.redraw();
      },
      setSubtitle: function (a) {
        this.setTitle(void 0, a);
      }
    });
    l(m.prototype, {
      update: function (a, c, d, e) {
        function f() {
          h.applyOptions(a);
          null === h.y && k && (h.graphic = k.destroy());
          p(a, !0) && (k && k.element && a && a.marker && a.marker.symbol && (h.graphic = k.destroy()), a && a.dataLabels && h.dataLabel && (h.dataLabel = h.dataLabel.destroy()));
          l = h.index;
          m.updateParallelArrays(h, l);
          n.data[l] = p(n.data[l], !0) || p(a, !0) ? h.options : a;
          m.isDirty = m.isDirtyData = !0;
          !m.fixedBox && m.hasCartesianSeries && (g.isDirtyBox = !0);
          "point" === n.legendType && (g.isDirtyLegend = !0);
          c && g.redraw(d);
        }

        var h = this || _global,
            m = h.series,
            k = h.graphic,
            l,
            g = m.chart,
            n = m.options;
        c = b(c, !0);
        !1 === e ? f() : h.firePointEvent("update", {
          options: a
        }, f);
      },
      remove: function (a, b) {
        (this || _global).series.removePoint(e(this || _global, (this || _global).series.data), a, b);
      }
    });
    l(d.prototype, {
      addPoint: function (a, c, d, e) {
        var f = (this || _global).options,
            h = (this || _global).data,
            m = (this || _global).chart,
            k = (this || _global).xAxis,
            k = k && k.hasNames && k.names,
            l = f.data,
            g,
            p,
            n = (this || _global).xData,
            r,
            v;
        c = b(c, !0);
        g = {
          series: this || _global
        };

        (this || _global).pointClass.prototype.applyOptions.apply(g, [a]);

        v = g.x;
        r = n.length;
        if ((this || _global).requireSorting && v < n[r - 1]) for (p = !0; r && n[r - 1] > v;) r--;
        this.updateParallelArrays(g, "splice", r, 0, 0);
        this.updateParallelArrays(g, r);
        k && g.name && (k[v] = g.name);
        l.splice(r, 0, a);
        p && ((this || _global).data.splice(r, 0, null), this.processData());
        "point" === f.legendType && this.generatePoints();
        d && (h[0] && h[0].remove ? h[0].remove(!1) : (h.shift(), this.updateParallelArrays(g, "shift"), l.shift()));
        (this || _global).isDirtyData = (this || _global).isDirty = !0;
        c && m.redraw(e);
      },
      removePoint: function (a, c, d) {
        var f = this || _global,
            e = f.data,
            h = e[a],
            m = f.points,
            k = f.chart,
            l = function () {
          m && m.length === e.length && m.splice(a, 1);
          e.splice(a, 1);
          f.options.data.splice(a, 1);
          f.updateParallelArrays(h || {
            series: f
          }, "splice", a, 1);
          h && h.destroy();
          f.isDirty = !0;
          f.isDirtyData = !0;
          c && k.redraw();
        };

        A(d, k);
        c = b(c, !0);
        h ? h.firePointEvent("remove", null, l) : l();
      },
      remove: function (a, c, d) {
        function f() {
          e.destroy();
          h.isDirtyLegend = h.isDirtyBox = !0;
          h.linkSeries();
          b(a, !0) && h.redraw(c);
        }

        var e = this || _global,
            h = e.chart;
        !1 !== d ? k(e, "remove", null, f) : f();
      },
      update: function (a, c) {
        var d = this || _global,
            f = (this || _global).chart,
            e = (this || _global).userOptions,
            h = (this || _global).oldType || (this || _global).type,
            m = a.type || e.type || f.options.chart.type,
            k = r[h].prototype,
            p = ["group", "markerGroup", "dataLabelsGroup"],
            g;
        if (m && m !== h || void 0 !== a.zIndex) p.length = 0;
        n(p, function (a) {
          p[a] = d[a];
          delete d[a];
        });
        a = H(e, {
          animation: !1,
          index: (this || _global).index,
          pointStart: (this || _global).xData[0]
        }, {
          data: (this || _global).options.data
        }, a);
        this.remove(!1, null, !1);

        for (g in k) (this || _global)[g] = void 0;

        l(this || _global, r[m || h].prototype);
        n(p, function (a) {
          d[a] = p[a];
        });
        this.init(f, a);
        (this || _global).oldType = h;
        f.linkSeries();
        b(c, !0) && f.redraw(!1);
      }
    });
    l(C.prototype, {
      update: function (a, c) {
        var d = (this || _global).chart;
        a = d.options[(this || _global).coll][(this || _global).options.index] = H((this || _global).userOptions, a);
        this.destroy(!0);
        this.init(d, l(a, {
          events: void 0
        }));
        d.isDirtyBox = !0;
        b(c, !0) && d.redraw();
      },
      remove: function (a) {
        for (var c = (this || _global).chart, d = (this || _global).coll, f = (this || _global).series, e = f.length; e--;) f[e] && f[e].remove(!1);

        t(c.axes, this || _global);
        t(c[d], this || _global);
        c.options[d].splice((this || _global).options.index, 1);
        n(c[d], function (a, b) {
          a.options.index = b;
        });
        this.destroy();
        c.isDirtyBox = !0;
        b(a, !0) && c.redraw();
      },
      setTitle: function (a, b) {
        this.update({
          title: a
        }, b);
      },
      setCategories: function (a, b) {
        this.update({
          categories: a
        }, b);
      }
    });
  })(I);

  (function (a) {
    var B = a.color,
        z = a.each,
        C = a.map,
        E = a.pick,
        u = a.Series,
        h = a.seriesType;
    h("area", "line", {
      softThreshold: !1,
      threshold: 0
    }, {
      singleStacks: !1,
      getStackPoints: function () {
        var a = [],
            h = [],
            l = (this || _global).xAxis,
            k = (this || _global).yAxis,
            e = k.stacks[(this || _global).stackKey],
            c = {},
            p = (this || _global).points,
            u = (this || _global).index,
            b = k.series,
            m = b.length,
            d,
            r = E(k.options.reversedStacks, !0) ? 1 : -1,
            A,
            v;

        if ((this || _global).options.stacking) {
          for (A = 0; A < p.length; A++) c[p[A].x] = p[A];

          for (v in e) null !== e[v].total && h.push(v);

          h.sort(function (a, b) {
            return a - b;
          });
          d = C(b, function () {
            return (this || _global).visible;
          });
          z(h, function (b, p) {
            var f = 0,
                n,
                q;
            if (c[b] && !c[b].isNull) a.push(c[b]), z([-1, 1], function (a) {
              var f = 1 === a ? "rightNull" : "leftNull",
                  k = 0,
                  l = e[h[p + a]];
              if (l) for (A = u; 0 <= A && A < m;) n = l.points[A], n || (A === u ? c[b][f] = !0 : d[A] && (q = e[b].points[A]) && (k -= q[1] - q[0])), A += r;
              c[b][1 === a ? "rightCliff" : "leftCliff"] = k;
            });else {
              for (A = u; 0 <= A && A < m;) {
                if (n = e[b].points[A]) {
                  f = n[1];
                  break;
                }

                A += r;
              }

              f = k.translate(f, 0, 1, 0, 1);
              a.push({
                isNull: !0,
                plotX: l.translate(b, 0, 0, 0, 1),
                x: b,
                plotY: f,
                yBottom: f
              });
            }
          });
        }

        return a;
      },
      getGraphPath: function (a) {
        var h = u.prototype.getGraphPath,
            l = (this || _global).options,
            k = l.stacking,
            e = (this || _global).yAxis,
            c,
            p,
            n = [],
            b = [],
            m = (this || _global).index,
            d,
            r = e.stacks[(this || _global).stackKey],
            A = l.threshold,
            v = e.getThreshold(l.threshold),
            f,
            l = l.connectNulls || "percent" === k,
            y = function (c, f, h) {
          var l = a[c];
          c = k && r[l.x].points[m];
          var p = l[h + "Null"] || 0;
          h = l[h + "Cliff"] || 0;
          var q,
              t,
              l = !0;
          h || p ? (q = (p ? c[0] : c[1]) + h, t = c[0] + h, l = !!p) : !k && a[f] && a[f].isNull && (q = t = A);
          void 0 !== q && (b.push({
            plotX: d,
            plotY: null === q ? v : e.getThreshold(q),
            isNull: l,
            isCliff: !0
          }), n.push({
            plotX: d,
            plotY: null === t ? v : e.getThreshold(t),
            doCurve: !1
          }));
        };

        a = a || (this || _global).points;
        k && (a = this.getStackPoints());

        for (c = 0; c < a.length; c++) if (p = a[c].isNull, d = E(a[c].rectPlotX, a[c].plotX), f = E(a[c].yBottom, v), !p || l) l || y(c, c - 1, "left"), p && !k && l || (b.push(a[c]), n.push({
          x: c,
          plotX: d,
          plotY: f
        })), l || y(c, c + 1, "right");

        c = h.call(this || _global, b, !0, !0);
        n.reversed = !0;
        p = h.call(this || _global, n, !0, !0);
        p.length && (p[0] = "L");
        p = c.concat(p);
        h = h.call(this || _global, b, !1, l);
        p.xMap = c.xMap;
        (this || _global).areaPath = p;
        return h;
      },
      drawGraph: function () {
        (this || _global).areaPath = [];
        u.prototype.drawGraph.apply(this || _global);
        var a = this || _global,
            h = (this || _global).areaPath,
            l = (this || _global).options,
            k = [["area", "highcharts-area", (this || _global).color, l.fillColor]];
        z((this || _global).zones, function (e, c) {
          k.push(["zone-area-" + c, "highcharts-area highcharts-zone-area-" + c + " " + e.className, e.color || a.color, e.fillColor || l.fillColor]);
        });
        z(k, function (e) {
          var c = e[0],
              k = a[c];
          k ? (k.endX = h.xMap, k.animate({
            d: h
          })) : (k = a[c] = a.chart.renderer.path(h).addClass(e[1]).attr({
            fill: E(e[3], B(e[2]).setOpacity(E(l.fillOpacity, 0.75)).get()),
            zIndex: 0
          }).add(a.group), k.isArea = !0);
          k.startX = h.xMap;
          k.shiftUnit = l.step ? 2 : 1;
        });
      },
      drawLegendSymbol: a.LegendSymbolMixin.drawRectangle
    });
  })(I);

  (function (a) {
    var B = a.pick;
    a = a.seriesType;
    a("spline", "line", {}, {
      getPointSpline: function (a, C, E) {
        var u = C.plotX,
            h = C.plotY,
            n = a[E - 1];
        E = a[E + 1];
        var t, l, k, e;

        if (n && !n.isNull && !1 !== n.doCurve && !C.isCliff && E && !E.isNull && !1 !== E.doCurve && !C.isCliff) {
          a = n.plotY;
          k = E.plotX;
          E = E.plotY;
          var c = 0;
          t = (1.5 * u + n.plotX) / 2.5;
          l = (1.5 * h + a) / 2.5;
          k = (1.5 * u + k) / 2.5;
          e = (1.5 * h + E) / 2.5;
          k !== t && (c = (e - l) * (k - u) / (k - t) + h - e);
          l += c;
          e += c;
          l > a && l > h ? (l = Math.max(a, h), e = 2 * h - l) : l < a && l < h && (l = Math.min(a, h), e = 2 * h - l);
          e > E && e > h ? (e = Math.max(E, h), l = 2 * h - e) : e < E && e < h && (e = Math.min(E, h), l = 2 * h - e);
          C.rightContX = k;
          C.rightContY = e;
        }

        C = ["C", B(n.rightContX, n.plotX), B(n.rightContY, n.plotY), B(t, u), B(l, h), u, h];
        n.rightContX = n.rightContY = null;
        return C;
      }
    });
  })(I);

  (function (a) {
    var B = a.seriesTypes.area.prototype,
        z = a.seriesType;
    z("areaspline", "spline", a.defaultPlotOptions.area, {
      getStackPoints: B.getStackPoints,
      getGraphPath: B.getGraphPath,
      setStackCliffs: B.setStackCliffs,
      drawGraph: B.drawGraph,
      drawLegendSymbol: a.LegendSymbolMixin.drawRectangle
    });
  })(I);

  (function (a) {
    var B = a.animObject,
        z = a.color,
        C = a.each,
        E = a.extend,
        u = a.isNumber,
        h = a.merge,
        n = a.pick,
        t = a.Series,
        l = a.seriesType,
        k = a.svg;
    l("column", "line", {
      borderRadius: 0,
      groupPadding: 0.2,
      marker: null,
      pointPadding: 0.1,
      minPointLength: 0,
      cropThreshold: 50,
      pointRange: null,
      states: {
        hover: {
          halo: !1,
          brightness: 0.1,
          shadow: !1
        },
        select: {
          color: "#cccccc",
          borderColor: "#000000",
          shadow: !1
        }
      },
      dataLabels: {
        align: null,
        verticalAlign: null,
        y: null
      },
      softThreshold: !1,
      startFromThreshold: !0,
      stickyTracking: !1,
      tooltip: {
        distance: 6
      },
      threshold: 0,
      borderColor: "#ffffff"
    }, {
      cropShoulder: 0,
      directTouch: !0,
      trackerGroups: ["group", "dataLabelsGroup"],
      negStacks: !0,
      init: function () {
        t.prototype.init.apply(this || _global, arguments);
        var a = this || _global,
            c = a.chart;
        c.hasRendered && C(c.series, function (c) {
          c.type === a.type && (c.isDirty = !0);
        });
      },
      getColumnMetrics: function () {
        var a = this || _global,
            c = a.options,
            h = a.xAxis,
            k = a.yAxis,
            b = h.reversed,
            m,
            d = {},
            l = 0;
        !1 === c.grouping ? l = 1 : C(a.chart.series, function (b) {
          var c = b.options,
              f = b.yAxis,
              e;
          b.type === a.type && b.visible && k.len === f.len && k.pos === f.pos && (c.stacking ? (m = b.stackKey, void 0 === d[m] && (d[m] = l++), e = d[m]) : !1 !== c.grouping && (e = l++), b.columnIndex = e);
        });
        var t = Math.min(Math.abs(h.transA) * (h.ordinalSlope || c.pointRange || h.closestPointRange || h.tickInterval || 1), h.len),
            v = t * c.groupPadding,
            f = (t - 2 * v) / (l || 1),
            c = Math.min(c.maxPointWidth || h.len, n(c.pointWidth, f * (1 - 2 * c.pointPadding)));
        a.columnMetrics = {
          width: c,
          offset: (f - c) / 2 + (v + ((a.columnIndex || 0) + (b ? 1 : 0)) * f - t / 2) * (b ? -1 : 1)
        };
        return a.columnMetrics;
      },
      crispCol: function (a, c, h, k) {
        var b = (this || _global).chart,
            e = (this || _global).borderWidth,
            d = -(e % 2 ? 0.5 : 0),
            e = e % 2 ? 0.5 : 1;
        b.inverted && b.renderer.isVML && (e += 1);
        h = Math.round(a + h) + d;
        a = Math.round(a) + d;
        k = Math.round(c + k) + e;
        d = 0.5 >= Math.abs(c) && 0.5 < k;
        c = Math.round(c) + e;
        k -= c;
        d && k && (--c, k += 1);
        return {
          x: a,
          y: c,
          width: h - a,
          height: k
        };
      },
      translate: function () {
        var a = this || _global,
            c = a.chart,
            h = a.options,
            k = a.dense = 2 > a.closestPointRange * a.xAxis.transA,
            k = a.borderWidth = n(h.borderWidth, k ? 0 : 1),
            b = a.yAxis,
            m = a.translatedThreshold = b.getThreshold(h.threshold),
            d = n(h.minPointLength, 5),
            l = a.getColumnMetrics(),
            u = l.width,
            v = a.barW = Math.max(u, 1 + 2 * k),
            f = a.pointXOffset = l.offset;
        c.inverted && (m -= 0.5);
        h.pointPadding && (v = Math.ceil(v));
        t.prototype.translate.apply(a);
        C(a.points, function (e) {
          var h = n(e.yBottom, m),
              k = 999 + Math.abs(h),
              k = Math.min(Math.max(-k, e.plotY), b.len + k),
              l = e.plotX + f,
              p = v,
              r = Math.min(k, h),
              t,
              y = Math.max(k, h) - r;
          Math.abs(y) < d && d && (y = d, t = !b.reversed && !e.negative || b.reversed && e.negative, r = Math.abs(r - m) > d ? h - d : m - (t ? d : 0));
          e.barX = l;
          e.pointWidth = u;
          e.tooltipPos = c.inverted ? [b.len + b.pos - c.plotLeft - k, a.xAxis.len - l - p / 2, y] : [l + p / 2, k + b.pos - c.plotTop, y];
          e.shapeType = "rect";
          e.shapeArgs = a.crispCol.apply(a, e.isNull ? [e.plotX, b.len / 2, 0, 0] : [l, r, p, y]);
        });
      },
      getSymbol: a.noop,
      drawLegendSymbol: a.LegendSymbolMixin.drawRectangle,
      drawGraph: function () {
        (this || _global).group[(this || _global).dense ? "addClass" : "removeClass"]("highcharts-dense-data");
      },
      pointAttribs: function (a, c) {
        var e = (this || _global).options,
            k,
            b = (this || _global).pointAttrToOptions || {};
        k = b.stroke || "borderColor";
        var m = b["stroke-width"] || "borderWidth",
            d = a && a.color || (this || _global).color,
            l = a[k] || e[k] || (this || _global).color || d,
            n = a[m] || e[m] || (this || _global)[m] || 0,
            b = e.dashStyle;
        a && (this || _global).zones.length && (d = (d = a.getZone()) && d.color || a.options.color || (this || _global).color);
        c && (a = h(e.states[c], a.options.states && a.options.states[c] || {}), c = a.brightness, d = a.color || void 0 !== c && z(d).brighten(a.brightness).get() || d, l = a[k] || l, n = a[m] || n, b = a.dashStyle || b);
        k = {
          fill: d,
          stroke: l,
          "stroke-width": n
        };
        e.borderRadius && (k.r = e.borderRadius);
        b && (k.dashstyle = b);
        return k;
      },
      drawPoints: function () {
        var a = this || _global,
            c = (this || _global).chart,
            k = a.options,
            l = c.renderer,
            b = k.animationLimit || 250,
            m;
        C(a.points, function (d) {
          var e = d.graphic;

          if (u(d.plotY) && null !== d.y) {
            m = d.shapeArgs;
            if (e) e[c.pointCount < b ? "animate" : "attr"](h(m));else d.graphic = e = l[d.shapeType](m).add(d.group || a.group);
            e.attr(a.pointAttribs(d, d.selected && "select")).shadow(k.shadow, null, k.stacking && !k.borderRadius);
            e.addClass(d.getClassName(), !0);
          } else e && (d.graphic = e.destroy());
        });
      },
      animate: function (a) {
        var c = this || _global,
            e = (this || _global).yAxis,
            h = c.options,
            b = (this || _global).chart.inverted,
            m = {};
        k && (a ? (m.scaleY = 0.001, a = Math.min(e.pos + e.len, Math.max(e.pos, e.toPixels(h.threshold))), b ? m.translateX = a - e.len : m.translateY = a, c.group.attr(m)) : (m[b ? "translateX" : "translateY"] = e.pos, c.group.animate(m, E(B(c.options.animation), {
          step: function (a, b) {
            c.group.attr({
              scaleY: Math.max(0.001, b.pos)
            });
          }
        })), c.animate = null));
      },
      remove: function () {
        var a = this || _global,
            c = a.chart;
        c.hasRendered && C(c.series, function (c) {
          c.type === a.type && (c.isDirty = !0);
        });
        t.prototype.remove.apply(a, arguments);
      }
    });
  })(I);

  (function (a) {
    a = a.seriesType;
    a("bar", "column", null, {
      inverted: !0
    });
  })(I);

  (function (a) {
    var B = a.Series;
    a = a.seriesType;
    a("scatter", "line", {
      lineWidth: 0,
      marker: {
        enabled: !0
      },
      tooltip: {
        headerFormat: "<span style=\"color:{point.color}\">\u25CF</span> <span style=\"font-size: 0.85em\"> {series.name}</span><br/>",
        pointFormat: "x: <b>{point.x}</b><br/>y: <b>{point.y}</b><br/>"
      }
    }, {
      sorted: !1,
      requireSorting: !1,
      noSharedTooltip: !0,
      trackerGroups: ["group", "markerGroup", "dataLabelsGroup"],
      takeOrdinalPosition: !1,
      kdDimensions: 2,
      drawGraph: function () {
        (this || _global).options.lineWidth && B.prototype.drawGraph.call(this || _global);
      }
    });
  })(I);

  (function (a) {
    var B = a.pick,
        z = a.relativeLength;
    a.CenteredSeriesMixin = {
      getCenter: function () {
        var a = (this || _global).options,
            E = (this || _global).chart,
            u = 2 * (a.slicedOffset || 0),
            h = E.plotWidth - 2 * u,
            E = E.plotHeight - 2 * u,
            n = a.center,
            n = [B(n[0], "50%"), B(n[1], "50%"), a.size || "100%", a.innerSize || 0],
            t = Math.min(h, E),
            l,
            k;

        for (l = 0; 4 > l; ++l) k = n[l], a = 2 > l || 2 === l && /%$/.test(k), n[l] = z(k, [h, E, t, n[2]][l]) + (a ? u : 0);

        n[3] > n[2] && (n[3] = n[2]);
        return n;
      }
    };
  })(I);

  (function (a) {
    var B = a.addEvent,
        z = a.defined,
        C = a.each,
        E = a.extend,
        u = a.inArray,
        h = a.noop,
        n = a.pick,
        t = a.Point,
        l = a.Series,
        k = a.seriesType,
        e = a.setAnimation;
    k("pie", "line", {
      center: [null, null],
      clip: !1,
      colorByPoint: !0,
      dataLabels: {
        distance: 30,
        enabled: !0,
        formatter: function () {
          return null === (this || _global).y ? void 0 : (this || _global).point.name;
        },
        x: 0
      },
      ignoreHiddenPoint: !0,
      legendType: "point",
      marker: null,
      size: null,
      showInLegend: !1,
      slicedOffset: 10,
      stickyTracking: !1,
      tooltip: {
        followPointer: !0
      },
      borderColor: "#ffffff",
      borderWidth: 1,
      states: {
        hover: {
          brightness: 0.1,
          shadow: !1
        }
      }
    }, {
      isCartesian: !1,
      requireSorting: !1,
      directTouch: !0,
      noSharedTooltip: !0,
      trackerGroups: ["group", "dataLabelsGroup"],
      axisTypes: [],
      pointAttribs: a.seriesTypes.column.prototype.pointAttribs,
      animate: function (a) {
        var c = this || _global,
            e = c.points,
            b = c.startAngleRad;
        a || (C(e, function (a) {
          var d = a.graphic,
              e = a.shapeArgs;
          d && (d.attr({
            r: a.startR || c.center[3] / 2,
            start: b,
            end: b
          }), d.animate({
            r: e.r,
            start: e.start,
            end: e.end
          }, c.options.animation));
        }), c.animate = null);
      },
      updateTotals: function () {
        var a,
            e = 0,
            h = (this || _global).points,
            b = h.length,
            k,
            d = (this || _global).options.ignoreHiddenPoint;

        for (a = 0; a < b; a++) k = h[a], 0 > k.y && (k.y = null), e += d && !k.visible ? 0 : k.y;

        (this || _global).total = e;

        for (a = 0; a < b; a++) k = h[a], k.percentage = 0 < e && (k.visible || !d) ? k.y / e * 100 : 0, k.total = e;
      },
      generatePoints: function () {
        l.prototype.generatePoints.call(this || _global);
        this.updateTotals();
      },
      translate: function (a) {
        this.generatePoints();
        var c = 0,
            e = (this || _global).options,
            b = e.slicedOffset,
            h = b + (e.borderWidth || 0),
            d,
            k,
            l,
            v = e.startAngle || 0,
            f = (this || _global).startAngleRad = Math.PI / 180 * (v - 90),
            v = ((this || _global).endAngleRad = Math.PI / 180 * (n(e.endAngle, v + 360) - 90)) - f,
            t = (this || _global).points,
            u = e.dataLabels.distance,
            e = e.ignoreHiddenPoint,
            F,
            q = t.length,
            x;
        a || ((this || _global).center = a = this.getCenter());

        (this || _global).getX = function (b, c) {
          l = Math.asin(Math.min((b - a[1]) / (a[2] / 2 + u), 1));
          return a[0] + (c ? -1 : 1) * Math.cos(l) * (a[2] / 2 + u);
        };

        for (F = 0; F < q; F++) {
          x = t[F];
          d = f + c * v;
          if (!e || x.visible) c += x.percentage / 100;
          k = f + c * v;
          x.shapeType = "arc";
          x.shapeArgs = {
            x: a[0],
            y: a[1],
            r: a[2] / 2,
            innerR: a[3] / 2,
            start: Math.round(1000 * d) / 1000,
            end: Math.round(1000 * k) / 1000
          };
          l = (k + d) / 2;
          l > 1.5 * Math.PI ? l -= 2 * Math.PI : l < -Math.PI / 2 && (l += 2 * Math.PI);
          x.slicedTranslation = {
            translateX: Math.round(Math.cos(l) * b),
            translateY: Math.round(Math.sin(l) * b)
          };
          d = Math.cos(l) * a[2] / 2;
          k = Math.sin(l) * a[2] / 2;
          x.tooltipPos = [a[0] + 0.7 * d, a[1] + 0.7 * k];
          x.half = l < -Math.PI / 2 || l > Math.PI / 2 ? 1 : 0;
          x.angle = l;
          h = Math.min(h, u / 5);
          x.labelPos = [a[0] + d + Math.cos(l) * u, a[1] + k + Math.sin(l) * u, a[0] + d + Math.cos(l) * h, a[1] + k + Math.sin(l) * h, a[0] + d, a[1] + k, 0 > u ? "center" : x.half ? "right" : "left", l];
        }
      },
      drawGraph: null,
      drawPoints: function () {
        var a = this || _global,
            e = a.chart.renderer,
            h,
            b,
            k,
            d,
            l = a.options.shadow;
        l && !a.shadowGroup && (a.shadowGroup = e.g("shadow").add(a.group));
        C(a.points, function (c) {
          if (null !== c.y) {
            b = c.graphic;
            d = c.shapeArgs;
            h = c.getTranslate();
            var m = c.shadowGroup;
            l && !m && (m = c.shadowGroup = e.g("shadow").add(a.shadowGroup));
            m && m.attr(h);
            k = a.pointAttribs(c, c.selected && "select");
            b ? b.setRadialReference(a.center).attr(k).animate(E(d, h)) : (c.graphic = b = e[c.shapeType](d).setRadialReference(a.center).attr(h).add(a.group), c.visible || b.attr({
              visibility: "hidden"
            }), b.attr(k).attr({
              "stroke-linejoin": "round"
            }).shadow(l, m));
            b.addClass(c.getClassName());
          }
        });
      },
      searchPoint: h,
      sortByAngle: function (a, e) {
        a.sort(function (a, b) {
          return void 0 !== a.angle && (b.angle - a.angle) * e;
        });
      },
      drawLegendSymbol: a.LegendSymbolMixin.drawRectangle,
      getCenter: a.CenteredSeriesMixin.getCenter,
      getSymbol: h
    }, {
      init: function () {
        t.prototype.init.apply(this || _global, arguments);
        var a = this || _global,
            e;
        a.name = n(a.name, "Slice");

        e = function (c) {
          a.slice("select" === c.type);
        };

        B(a, "select", e);
        B(a, "unselect", e);
        return a;
      },
      setVisible: function (a, e) {
        var c = this || _global,
            b = c.series,
            h = b.chart,
            d = b.options.ignoreHiddenPoint;
        e = n(e, d);
        a !== c.visible && (c.visible = c.options.visible = a = void 0 === a ? !c.visible : a, b.options.data[u(c, b.data)] = c.options, C(["graphic", "dataLabel", "connector", "shadowGroup"], function (b) {
          if (c[b]) c[b][a ? "show" : "hide"](!0);
        }), c.legendItem && h.legend.colorizeItem(c, a), a || "hover" !== c.state || c.setState(""), d && (b.isDirty = !0), e && h.redraw());
      },
      slice: function (a, h, k) {
        var b = (this || _global).series;
        e(k, b.chart);
        n(h, !0);
        (this || _global).sliced = (this || _global).options.sliced = z(a) ? a : !(this || _global).sliced;
        b.options.data[u(this || _global, b.data)] = (this || _global).options;

        (this || _global).graphic.animate(this.getTranslate());

        (this || _global).shadowGroup && (this || _global).shadowGroup.animate(this.getTranslate());
      },
      getTranslate: function () {
        return (this || _global).sliced ? (this || _global).slicedTranslation : {
          translateX: 0,
          translateY: 0
        };
      },
      haloPath: function (a) {
        var c = (this || _global).shapeArgs;
        return (this || _global).sliced || !(this || _global).visible ? [] : (this || _global).series.chart.renderer.symbols.arc(c.x, c.y, c.r + a, c.r + a, {
          innerR: (this || _global).shapeArgs.r,
          start: c.start,
          end: c.end
        });
      }
    });
  })(I);

  (function (a) {
    var B = a.addEvent,
        z = a.arrayMax,
        C = a.defined,
        E = a.each,
        u = a.extend,
        h = a.format,
        n = a.map,
        t = a.merge,
        l = a.noop,
        k = a.pick,
        e = a.relativeLength,
        c = a.Series,
        p = a.seriesTypes,
        H = a.stableSort;

    a.distribute = function (a, c) {
      function b(a, b) {
        return a.target - b.target;
      }

      var e,
          h = !0,
          k = a,
          f = [],
          l;
      l = 0;

      for (e = a.length; e--;) l += a[e].size;

      if (l > c) {
        H(a, function (a, b) {
          return (b.rank || 0) - (a.rank || 0);
        });

        for (l = e = 0; l <= c;) l += a[e].size, e++;

        f = a.splice(e - 1, a.length);
      }

      H(a, b);

      for (a = n(a, function (a) {
        return {
          size: a.size,
          targets: [a.target]
        };
      }); h;) {
        for (e = a.length; e--;) h = a[e], l = (Math.min.apply(0, h.targets) + Math.max.apply(0, h.targets)) / 2, h.pos = Math.min(Math.max(0, l - h.size / 2), c - h.size);

        e = a.length;

        for (h = !1; e--;) 0 < e && a[e - 1].pos + a[e - 1].size > a[e].pos && (a[e - 1].size += a[e].size, a[e - 1].targets = a[e - 1].targets.concat(a[e].targets), a[e - 1].pos + a[e - 1].size > c && (a[e - 1].pos = c - a[e - 1].size), a.splice(e, 1), h = !0);
      }

      e = 0;
      E(a, function (a) {
        var b = 0;
        E(a.targets, function () {
          k[e].pos = a.pos + b;
          b += k[e].size;
          e++;
        });
      });
      k.push.apply(k, f);
      H(k, b);
    };

    c.prototype.drawDataLabels = function () {
      var a = this || _global,
          c = a.options,
          d = c.dataLabels,
          e = a.points,
          l,
          n,
          f = a.hasRendered || 0,
          p,
          u,
          F = k(d.defer, !0),
          q = a.chart.renderer;
      if (d.enabled || a._hasPointLabels) a.dlProcessOptions && a.dlProcessOptions(d), u = a.plotGroup("dataLabelsGroup", "data-labels", F && !f ? "hidden" : "visible", d.zIndex || 6), F && (u.attr({
        opacity: +f
      }), f || B(a, "afterAnimate", function () {
        a.visible && u.show(!0);
        u[c.animation ? "animate" : "attr"]({
          opacity: 1
        }, {
          duration: 200
        });
      })), n = d, E(e, function (b) {
        var f,
            e = b.dataLabel,
            m,
            g,
            r,
            v = b.connector,
            y = !e,
            x;
        l = b.dlOptions || b.options && b.options.dataLabels;
        if (f = k(l && l.enabled, n.enabled) && null !== b.y) for (g in d = t(n, l), m = b.getLabelConfig(), p = d.format ? h(d.format, m) : d.formatter.call(m, d), x = d.style, r = d.rotation, x.color = k(d.color, x.color, a.color, "#000000"), "contrast" === x.color && (x.color = d.inside || 0 > d.distance || c.stacking ? q.getContrast(b.color || a.color) : "#000000"), c.cursor && (x.cursor = c.cursor), m = {
          fill: d.backgroundColor,
          stroke: d.borderColor,
          "stroke-width": d.borderWidth,
          r: d.borderRadius || 0,
          rotation: r,
          padding: d.padding,
          zIndex: 1
        }, m) void 0 === m[g] && delete m[g];
        !e || f && C(p) ? f && C(p) && (e ? m.text = p : (e = b.dataLabel = q[r ? "text" : "label"](p, 0, -9999, d.shape, null, null, d.useHTML, null, "data-label"), e.addClass("highcharts-data-label-color-" + b.colorIndex + " " + (d.className || "") + (d.useHTML ? "highcharts-tracker" : ""))), e.attr(m), e.css(x).shadow(d.shadow), e.added || e.add(u), a.alignDataLabel(b, e, d, null, y)) : (b.dataLabel = e.destroy(), v && (b.connector = v.destroy()));
      });
    };

    c.prototype.alignDataLabel = function (a, c, d, e, h) {
      var b = (this || _global).chart,
          f = b.inverted,
          l = k(a.plotX, -9999),
          m = k(a.plotY, -9999),
          n = c.getBBox(),
          q,
          p = d.rotation,
          r = d.align,
          t = (this || _global).visible && (a.series.forceDL || b.isInsidePlot(l, Math.round(m), f) || e && b.isInsidePlot(l, f ? e.x + 1 : e.y + e.height - 1, f)),
          A = "justify" === k(d.overflow, "justify");
      t && (q = d.style.fontSize, q = b.renderer.fontMetrics(q, c).b, e = u({
        x: f ? b.plotWidth - m : l,
        y: Math.round(f ? b.plotHeight - l : m),
        width: 0,
        height: 0
      }, e), u(d, {
        width: n.width,
        height: n.height
      }), p ? (A = !1, f = b.renderer.rotCorr(q, p), f = {
        x: e.x + d.x + e.width / 2 + f.x,
        y: e.y + d.y + {
          top: 0,
          middle: 0.5,
          bottom: 1
        }[d.verticalAlign] * e.height
      }, c[h ? "attr" : "animate"](f).attr({
        align: r
      }), l = (p + 720) % 360, l = 180 < l && 360 > l, "left" === r ? f.y -= l ? n.height : 0 : "center" === r ? (f.x -= n.width / 2, f.y -= n.height / 2) : "right" === r && (f.x -= n.width, f.y -= l ? 0 : n.height)) : (c.align(d, null, e), f = c.alignAttr), A ? this.justifyDataLabel(c, d, f, n, e, h) : k(d.crop, !0) && (t = b.isInsidePlot(f.x, f.y) && b.isInsidePlot(f.x + n.width, f.y + n.height)), d.shape && !p && c.attr({
        anchorX: a.plotX,
        anchorY: a.plotY
      }));
      t || (c.attr({
        y: -9999
      }), c.placed = !1);
    };

    c.prototype.justifyDataLabel = function (a, c, d, e, h, k) {
      var b = (this || _global).chart,
          l = c.align,
          m = c.verticalAlign,
          n,
          q,
          p = a.box ? 0 : a.padding || 0;
      n = d.x + p;
      0 > n && ("right" === l ? c.align = "left" : c.x = -n, q = !0);
      n = d.x + e.width - p;
      n > b.plotWidth && ("left" === l ? c.align = "right" : c.x = b.plotWidth - n, q = !0);
      n = d.y + p;
      0 > n && ("bottom" === m ? c.verticalAlign = "top" : c.y = -n, q = !0);
      n = d.y + e.height - p;
      n > b.plotHeight && ("top" === m ? c.verticalAlign = "bottom" : c.y = b.plotHeight - n, q = !0);
      q && (a.placed = !k, a.align(c, null, h));
    };

    p.pie && (p.pie.prototype.drawDataLabels = function () {
      var b = this || _global,
          e = b.data,
          d,
          h = b.chart,
          l = b.options.dataLabels,
          p = k(l.connectorPadding, 10),
          f = k(l.connectorWidth, 1),
          t = h.plotWidth,
          u = h.plotHeight,
          F,
          q = l.distance,
          x = b.center,
          B = x[2] / 2,
          C = x[1],
          H = 0 < q,
          g,
          D,
          I,
          M,
          R = [[], []],
          N,
          w,
          O,
          Q,
          P = [0, 0, 0, 0];
      b.visible && (l.enabled || b._hasPointLabels) && (E(e, function (a) {
        a.dataLabel && a.visible && a.dataLabel.shortened && (a.dataLabel.attr({
          width: "auto"
        }).css({
          width: "auto",
          textOverflow: "clip"
        }), a.dataLabel.shortened = !1);
      }), c.prototype.drawDataLabels.apply(b), E(e, function (a) {
        a.dataLabel && a.visible && (R[a.half].push(a), a.dataLabel._pos = null);
      }), E(R, function (c, f) {
        var e,
            k,
            m = c.length,
            r,
            v,
            y;
        if (m) for (b.sortByAngle(c, f - 0.5), 0 < q && (e = Math.max(0, C - B - q), k = Math.min(C + B + q, h.plotHeight), r = n(c, function (a) {
          if (a.dataLabel) return y = a.dataLabel.getBBox().height || 21, {
            target: a.labelPos[1] - e + y / 2,
            size: y,
            rank: a.y
          };
        }), a.distribute(r, k + y - e)), Q = 0; Q < m; Q++) d = c[Q], I = d.labelPos, g = d.dataLabel, O = !1 === d.visible ? "hidden" : "inherit", v = I[1], r ? void 0 === r[Q].pos ? O = "hidden" : (M = r[Q].size, w = e + r[Q].pos) : w = v, N = l.justify ? x[0] + (f ? -1 : 1) * (B + q) : b.getX(w < e + 2 || w > k - 2 ? v : w, f), g._attr = {
          visibility: O,
          align: I[6]
        }, g._pos = {
          x: N + l.x + ({
            left: p,
            right: -p
          }[I[6]] || 0),
          y: w + l.y - 10
        }, I.x = N, I.y = w, null === b.options.size && (D = g.getBBox().width, v = null, N - D < p ? (v = Math.round(D - N + p), P[3] = Math.max(v, P[3])) : N + D > t - p && (v = Math.round(N + D - t + p), P[1] = Math.max(v, P[1])), 0 > w - M / 2 ? P[0] = Math.max(Math.round(-w + M / 2), P[0]) : w + M / 2 > u && (P[2] = Math.max(Math.round(w + M / 2 - u), P[2])), g.sideOverflow = v);
      }), 0 === z(P) || this.verifyDataLabelOverflow(P)) && (this.placeDataLabels(), H && f && E((this || _global).points, function (a) {
        var c;
        F = a.connector;

        if ((g = a.dataLabel) && g._pos && a.visible) {
          O = g._attr.visibility;
          if (c = !F) a.connector = F = h.renderer.path().addClass("highcharts-data-label-connector highcharts-color-" + a.colorIndex).add(b.dataLabelsGroup), F.attr({
            "stroke-width": f,
            stroke: l.connectorColor || a.color || "#666666"
          });
          F[c ? "attr" : "animate"]({
            d: b.connectorPath(a.labelPos)
          });
          F.attr("visibility", O);
        } else F && (a.connector = F.destroy());
      }));
    }, p.pie.prototype.connectorPath = function (a) {
      var b = a.x,
          c = a.y;
      return k((this || _global).options.dataLabels.softConnector, !0) ? ["M", b + ("left" === a[6] ? 5 : -5), c, "C", b, c, 2 * a[2] - a[4], 2 * a[3] - a[5], a[2], a[3], "L", a[4], a[5]] : ["M", b + ("left" === a[6] ? 5 : -5), c, "L", a[2], a[3], "L", a[4], a[5]];
    }, p.pie.prototype.placeDataLabels = function () {
      E((this || _global).points, function (a) {
        var b = a.dataLabel;
        b && a.visible && ((a = b._pos) ? (b.sideOverflow && (b._attr.width = b.getBBox().width - b.sideOverflow, b.css({
          width: b._attr.width + "px",
          textOverflow: "ellipsis"
        }), b.shortened = !0), b.attr(b._attr), b[b.moved ? "animate" : "attr"](a), b.moved = !0) : b && b.attr({
          y: -9999
        }));
      }, this || _global);
    }, p.pie.prototype.alignDataLabel = l, p.pie.prototype.verifyDataLabelOverflow = function (a) {
      var b = (this || _global).center,
          c = (this || _global).options,
          h = c.center,
          k = c.minSize || 80,
          l,
          f;
      null !== h[0] ? l = Math.max(b[2] - Math.max(a[1], a[3]), k) : (l = Math.max(b[2] - a[1] - a[3], k), b[0] += (a[3] - a[1]) / 2);
      null !== h[1] ? l = Math.max(Math.min(l, b[2] - Math.max(a[0], a[2])), k) : (l = Math.max(Math.min(l, b[2] - a[0] - a[2]), k), b[1] += (a[0] - a[2]) / 2);
      l < b[2] ? (b[2] = l, b[3] = Math.min(e(c.innerSize || 0, l), l), this.translate(b), (this || _global).drawDataLabels && this.drawDataLabels()) : f = !0;
      return f;
    });
    p.column && (p.column.prototype.alignDataLabel = function (a, e, d, h, l) {
      var b = (this || _global).chart.inverted,
          f = a.series,
          m = a.dlBox || a.shapeArgs,
          n = k(a.below, a.plotY > k((this || _global).translatedThreshold, f.yAxis.len)),
          p = k(d.inside, !!(this || _global).options.stacking);
      m && (h = t(m), 0 > h.y && (h.height += h.y, h.y = 0), m = h.y + h.height - f.yAxis.len, 0 < m && (h.height -= m), b && (h = {
        x: f.yAxis.len - h.y - h.height,
        y: f.xAxis.len - h.x - h.width,
        width: h.height,
        height: h.width
      }), p || (b ? (h.x += n ? 0 : h.width, h.width = 0) : (h.y += n ? h.height : 0, h.height = 0)));
      d.align = k(d.align, !b || p ? "center" : n ? "right" : "left");
      d.verticalAlign = k(d.verticalAlign, b || p ? "middle" : n ? "top" : "bottom");
      c.prototype.alignDataLabel.call(this || _global, a, e, d, h, l);
    });
  })(I);

  (function (a) {
    var B = a.Chart,
        z = a.each,
        C = a.pick,
        E = a.addEvent;
    B.prototype.callbacks.push(function (a) {
      function h() {
        var h = [];
        z(a.series || [], function (a) {
          var l = a.options.dataLabels,
              k = a.dataLabelCollections || ["dataLabel"];
          (l.enabled || a._hasPointLabels) && !l.allowOverlap && a.visible && z(k, function (e) {
            z(a.points, function (a) {
              a[e] && (a[e].labelrank = C(a.labelrank, a.shapeArgs && a.shapeArgs.height), h.push(a[e]));
            });
          });
        });
        a.hideOverlappingLabels(h);
      }

      h();
      E(a, "redraw", h);
    });

    B.prototype.hideOverlappingLabels = function (a) {
      var h = a.length,
          n,
          t,
          l,
          k,
          e,
          c,
          p,
          u,
          b,
          m = function (a, b, c, e, f, h, k, l) {
        return !(f > a + c || f + k < a || h > b + e || h + l < b);
      };

      for (t = 0; t < h; t++) if (n = a[t]) n.oldOpacity = n.opacity, n.newOpacity = 1;

      a.sort(function (a, b) {
        return (b.labelrank || 0) - (a.labelrank || 0);
      });

      for (t = 0; t < h; t++) for (l = a[t], n = t + 1; n < h; ++n) if (k = a[n], l && k && l.placed && k.placed && 0 !== l.newOpacity && 0 !== k.newOpacity && (e = l.alignAttr, c = k.alignAttr, p = l.parentGroup, u = k.parentGroup, b = 2 * (l.box ? 0 : l.padding), e = m(e.x + p.translateX, e.y + p.translateY, l.width - b, l.height - b, c.x + u.translateX, c.y + u.translateY, k.width - b, k.height - b))) (l.labelrank < k.labelrank ? l : k).newOpacity = 0;

      z(a, function (a) {
        var b, c;
        a && (c = a.newOpacity, a.oldOpacity !== c && a.placed && (c ? a.show(!0) : b = function () {
          a.hide();
        }, a.alignAttr.opacity = c, a[a.isOld ? "animate" : "attr"](a.alignAttr, null, b)), a.isOld = !0);
      });
    };
  })(I);

  (function (a) {
    var B = a.addEvent,
        z = a.Chart,
        C = a.createElement,
        E = a.css,
        u = a.defaultOptions,
        h = a.defaultPlotOptions,
        n = a.each,
        t = a.extend,
        l = a.fireEvent,
        k = a.hasTouch,
        e = a.inArray,
        c = a.isObject,
        p = a.Legend,
        H = a.merge,
        b = a.pick,
        m = a.Point,
        d = a.Series,
        r = a.seriesTypes,
        A = a.svg;
    a = a.TrackerMixin = {
      drawTrackerPoint: function () {
        var a = this || _global,
            b = a.chart.pointer,
            c = function (a) {
          var c = b.getPointFromEvent(a);
          if (void 0 !== c) c.onMouseOver(a);
        };

        n(a.points, function (a) {
          a.graphic && (a.graphic.element.point = a);
          a.dataLabel && (a.dataLabel.div ? a.dataLabel.div.point = a : a.dataLabel.element.point = a);
        });
        a._hasTracking || (n(a.trackerGroups, function (d) {
          if (a[d]) {
            a[d].addClass("highcharts-tracker").on("mouseover", c).on("mouseout", function (a) {
              b.onTrackerMouseOut(a);
            });
            if (k) a[d].on("touchstart", c);
            a.options.cursor && a[d].css(E).css({
              cursor: a.options.cursor
            });
          }
        }), a._hasTracking = !0);
      },
      drawTrackerGraph: function () {
        var a = this || _global,
            b = a.options,
            c = b.trackByArea,
            d = [].concat(c ? a.areaPath : a.graphPath),
            e = d.length,
            h = a.chart,
            l = h.pointer,
            m = h.renderer,
            p = h.options.tooltip.snap,
            t = a.tracker,
            g,
            r = function () {
          if (h.hoverSeries !== a) a.onMouseOver();
        },
            u = "rgba(192,192,192," + (A ? 0.0001 : 0.002) + ")";

        if (e && !c) for (g = e + 1; g--;) "M" === d[g] && d.splice(g + 1, 0, d[g + 1] - p, d[g + 2], "L"), (g && "M" === d[g] || g === e) && d.splice(g, 0, "L", d[g - 2] + p, d[g - 1]);
        t ? t.attr({
          d: d
        }) : a.graph && (a.tracker = m.path(d).attr({
          "stroke-linejoin": "round",
          visibility: a.visible ? "visible" : "hidden",
          stroke: u,
          fill: c ? u : "none",
          "stroke-width": a.graph.strokeWidth() + (c ? 0 : 2 * p),
          zIndex: 2
        }).add(a.group), n([a.tracker, a.markerGroup], function (a) {
          a.addClass("highcharts-tracker").on("mouseover", r).on("mouseout", function (a) {
            l.onTrackerMouseOut(a);
          });
          b.cursor && a.css({
            cursor: b.cursor
          });
          if (k) a.on("touchstart", r);
        }));
      }
    };
    r.column && (r.column.prototype.drawTracker = a.drawTrackerPoint);
    r.pie && (r.pie.prototype.drawTracker = a.drawTrackerPoint);
    r.scatter && (r.scatter.prototype.drawTracker = a.drawTrackerPoint);
    t(p.prototype, {
      setItemEvents: function (a, b, c) {
        var d = this || _global,
            e = d.chart.renderer.boxWrapper,
            f = "highcharts-legend-" + (a.series ? "point" : "series") + "-active";
        (c ? b : a.legendGroup).on("mouseover", function () {
          a.setState("hover");
          e.addClass(f);
          b.css(d.options.itemHoverStyle);
        }).on("mouseout", function () {
          b.css(a.visible ? d.itemStyle : d.itemHiddenStyle);
          e.removeClass(f);
          a.setState();
        }).on("click", function (b) {
          var c = function () {
            a.setVisible && a.setVisible();
          };

          b = {
            browserEvent: b
          };
          a.firePointEvent ? a.firePointEvent("legendItemClick", b, c) : l(a, "legendItemClick", b, c);
        });
      },
      createCheckboxForItem: function (a) {
        a.checkbox = C("input", {
          type: "checkbox",
          checked: a.selected,
          defaultChecked: a.selected
        }, (this || _global).options.itemCheckboxStyle, (this || _global).chart.container);
        B(a.checkbox, "click", function (b) {
          l(a.series || a, "checkboxClick", {
            checked: b.target.checked,
            item: a
          }, function () {
            a.select();
          });
        });
      }
    });
    u.legend.itemStyle.cursor = "pointer";
    t(z.prototype, {
      showResetZoom: function () {
        var a = this || _global,
            b = u.lang,
            c = a.options.chart.resetZoomButton,
            d = c.theme,
            e = d.states,
            h = "chart" === c.relativeTo ? null : "plotBox";
        (this || _global).resetZoomButton = a.renderer.button(b.resetZoom, null, null, function () {
          a.zoomOut();
        }, d, e && e.hover).attr({
          align: c.position.align,
          title: b.resetZoomTitle
        }).addClass("highcharts-reset-zoom").add().align(c.position, !1, h);
      },
      zoomOut: function () {
        var a = this || _global;
        l(a, "selection", {
          resetSelection: !0
        }, function () {
          a.zoom();
        });
      },
      zoom: function (a) {
        var d,
            e = (this || _global).pointer,
            h = !1,
            k;
        !a || a.resetSelection ? n((this || _global).axes, function (a) {
          d = a.zoom();
        }) : n(a.xAxis.concat(a.yAxis), function (a) {
          var b = a.axis;
          e[b.isXAxis ? "zoomX" : "zoomY"] && (d = b.zoom(a.min, a.max), b.displayBtn && (h = !0));
        });
        k = (this || _global).resetZoomButton;
        h && !k ? this.showResetZoom() : !h && c(k) && ((this || _global).resetZoomButton = k.destroy());
        d && this.redraw(b((this || _global).options.chart.animation, a && a.animation, 100 > (this || _global).pointCount));
      },
      pan: function (a, b) {
        var c = this || _global,
            d = c.hoverPoints,
            e;
        d && n(d, function (a) {
          a.setState();
        });
        n("xy" === b ? [1, 0] : [1], function (b) {
          b = c[b ? "xAxis" : "yAxis"][0];
          var d = b.horiz,
              f = a[d ? "chartX" : "chartY"],
              d = d ? "mouseDownX" : "mouseDownY",
              h = c[d],
              k = (b.pointRange || 0) / 2,
              g = b.getExtremes(),
              l = b.toValue(h - f, !0) + k,
              k = b.toValue(h + b.len - f, !0) - k,
              m = k < l,
              h = m ? k : l,
              l = m ? l : k,
              m = b.toValue(b.toPixels(g.min) - b.minPixelPadding),
              k = b.toValue(b.toPixels(g.max) + b.minPixelPadding),
              m = Math.min(g.dataMin, m) - h,
              g = l - Math.max(g.dataMax, k);
          b.series.length && 0 > m && 0 > g && (b.setExtremes(h, l, !1, !1, {
            trigger: "pan"
          }), e = !0);
          c[d] = f;
        });
        e && c.redraw(!1);
        E(c.container, {
          cursor: "move"
        });
      }
    });
    t(m.prototype, {
      select: function (a, c) {
        var d = this || _global,
            f = d.series,
            h = f.chart;
        a = b(a, !d.selected);
        d.firePointEvent(a ? "select" : "unselect", {
          accumulate: c
        }, function () {
          d.selected = d.options.selected = a;
          f.options.data[e(d, f.data)] = d.options;
          d.setState(a && "select");
          c || n(h.getSelectedPoints(), function (a) {
            a.selected && a !== d && (a.selected = a.options.selected = !1, f.options.data[e(a, f.data)] = a.options, a.setState(""), a.firePointEvent("unselect"));
          });
        });
      },
      onMouseOver: function (a) {
        var b = (this || _global).series.chart.pointer;
        this.firePointEvent("mouseOver");
        b.runPointActions(a, this || _global);
      },
      onMouseOut: function () {
        var a = (this || _global).series.chart;
        this.firePointEvent("mouseOut");
        n(a.hoverPoints || [], function (a) {
          a.setState();
        });
        a.hoverPoints = a.hoverPoint = null;
      },
      importEvents: function () {
        if (!(this || _global).hasImportedEvents) {
          var a = H((this || _global).series.options.point, (this || _global).options).events,
              b;
          (this || _global).events = a;

          for (b in a) B(this || _global, b, a[b]);

          (this || _global).hasImportedEvents = !0;
        }
      },
      setState: function (a, c) {
        var d = Math.floor((this || _global).plotX),
            e = (this || _global).plotY,
            f = (this || _global).series,
            k = f.options.states[a] || {},
            l = h[f.type].marker && f.options.marker,
            m = l && !1 === l.enabled,
            n = l && l.states && l.states[a] || {},
            p = !1 === n.enabled,
            g = f.stateMarkerGraphic,
            r = (this || _global).marker || {},
            u = f.chart,
            v = f.halo,
            z,
            A = l && f.markerAttribs;
        a = a || "";

        if (!(a === (this || _global).state && !c || (this || _global).selected && "select" !== a || !1 === k.enabled || a && (p || m && !1 === n.enabled) || a && r.states && r.states[a] && !1 === r.states[a].enabled)) {
          A && (z = f.markerAttribs(this || _global, a));
          if ((this || _global).graphic) (this || _global).state && (this || _global).graphic.removeClass("highcharts-point-" + (this || _global).state), a && (this || _global).graphic.addClass("highcharts-point-" + a), (this || _global).graphic.attr(f.pointAttribs(this || _global, a)), z && (this || _global).graphic.animate(z, b(u.options.chart.animation, n.animation, l.animation)), g && g.hide();else {
            if (a && n) {
              l = r.symbol || f.symbol;
              g && g.currentSymbol !== l && (g = g.destroy());
              if (g) g[c ? "animate" : "attr"]({
                x: z.x,
                y: z.y
              });else l && (f.stateMarkerGraphic = g = u.renderer.symbol(l, z.x, z.y, z.width, z.height).add(f.markerGroup), g.currentSymbol = l);
              g && g.attr(f.pointAttribs(this || _global, a));
            }

            g && (g[a && u.isInsidePlot(d, e, u.inverted) ? "show" : "hide"](), g.element.point = this || _global);
          }
          (d = k.halo) && d.size ? (v || (f.halo = v = u.renderer.path().add(A ? f.markerGroup : f.group)), v[c ? "animate" : "attr"]({
            d: this.haloPath(d.size)
          }), v.attr({
            "class": "highcharts-halo highcharts-color-" + b((this || _global).colorIndex, f.colorIndex)
          }), v.point = this || _global, v.attr(t({
            fill: (this || _global).color || f.color,
            "fill-opacity": d.opacity,
            zIndex: -1
          }, d.attributes))) : v && v.point && v.point.haloPath && v.animate({
            d: v.point.haloPath(0)
          });
          (this || _global).state = a;
        }
      },
      haloPath: function (a) {
        return (this || _global).series.chart.renderer.symbols.circle(Math.floor((this || _global).plotX) - a, (this || _global).plotY - a, 2 * a, 2 * a);
      }
    });
    t(d.prototype, {
      onMouseOver: function () {
        var a = (this || _global).chart,
            b = a.hoverSeries;
        if (b && b !== (this || _global)) b.onMouseOut();
        (this || _global).options.events.mouseOver && l(this || _global, "mouseOver");
        this.setState("hover");
        a.hoverSeries = this || _global;
      },
      onMouseOut: function () {
        var a = (this || _global).options,
            b = (this || _global).chart,
            c = b.tooltip,
            d = b.hoverPoint;
        b.hoverSeries = null;
        if (d) d.onMouseOut();
        (this || _global) && a.events.mouseOut && l(this || _global, "mouseOut");
        !c || a.stickyTracking || c.shared && !(this || _global).noSharedTooltip || c.hide();
        this.setState();
      },
      setState: function (a) {
        var c = this || _global,
            d = c.options,
            e = c.graph,
            h = d.states,
            k = d.lineWidth,
            d = 0;
        a = a || "";
        if (c.state !== a && (n([c.group, c.markerGroup, c.dataLabelsGroup], function (b) {
          b && (c.state && b.removeClass("highcharts-series-" + c.state), a && b.addClass("highcharts-series-" + a));
        }), c.state = a, !h[a] || !1 !== h[a].enabled) && (a && (k = h[a].lineWidth || k + (h[a].lineWidthPlus || 0)), e && !e.dashstyle)) for (k = {
          "stroke-width": k
        }, e.animate(k, b(c.chart.options.chart.animation, h[a] && h[a].animation)); c["zone-graph-" + d];) c["zone-graph-" + d].attr(k), d += 1;
      },
      setVisible: function (a, b) {
        var c = this || _global,
            d = c.chart,
            e = c.legendItem,
            f,
            h = d.options.chart.ignoreHiddenSeries,
            k = c.visible;
        f = (c.visible = a = c.options.visible = c.userOptions.visible = void 0 === a ? !k : a) ? "show" : "hide";
        n(["group", "dataLabelsGroup", "markerGroup", "tracker", "tt"], function (a) {
          if (c[a]) c[a][f]();
        });
        if (d.hoverSeries === c || (d.hoverPoint && d.hoverPoint.series) === c) c.onMouseOut();
        e && d.legend.colorizeItem(c, a);
        c.isDirty = !0;
        c.options.stacking && n(d.series, function (a) {
          a.options.stacking && a.visible && (a.isDirty = !0);
        });
        n(c.linkedSeries, function (b) {
          b.setVisible(a, !1);
        });
        h && (d.isDirtyBox = !0);
        !1 !== b && d.redraw();
        l(c, f);
      },
      show: function () {
        this.setVisible(!0);
      },
      hide: function () {
        this.setVisible(!1);
      },
      select: function (a) {
        (this || _global).selected = a = void 0 === a ? !(this || _global).selected : a;
        (this || _global).checkbox && ((this || _global).checkbox.checked = a);
        l(this || _global, a ? "select" : "unselect");
      },
      drawTracker: a.drawTrackerGraph
    });
  })(I);

  (function (a) {
    var B = a.Chart,
        z = a.each,
        C = a.inArray,
        E = a.isArray,
        u = a.isObject,
        h = a.pick,
        n = a.splat;

    B.prototype.setResponsive = function (h) {
      var l = (this || _global).options.responsive,
          k = [],
          e = (this || _global).currentResponsive;
      l && l.rules && z(l.rules, function (c) {
        void 0 === c._id && (c._id = a.uniqueKey());
        this.matchResponsiveRule(c, k, h);
      }, this || _global);
      var c = a.merge.apply(0, a.map(k, function (c) {
        return a.find(l.rules, function (a) {
          return a._id === c;
        }).chartOptions;
      })),
          k = k.toString() || void 0;
      k !== (e && e.ruleIds) && (e && this.update(e.undoOptions, h), k ? ((this || _global).currentResponsive = {
        ruleIds: k,
        mergedOptions: c,
        undoOptions: this.currentOptions(c)
      }, this.update(c, h)) : (this || _global).currentResponsive = void 0);
    };

    B.prototype.matchResponsiveRule = function (a, l) {
      var k = a.condition;
      (k.callback || function () {
        return (this || _global).chartWidth <= h(k.maxWidth, Number.MAX_VALUE) && (this || _global).chartHeight <= h(k.maxHeight, Number.MAX_VALUE) && (this || _global).chartWidth >= h(k.minWidth, 0) && (this || _global).chartHeight >= h(k.minHeight, 0);
      }).call(this || _global) && l.push(a._id);
    };

    B.prototype.currentOptions = function (a) {
      function h(a, c, k, l) {
        var b, e;

        for (b in a) if (!l && -1 < C(b, ["series", "xAxis", "yAxis"])) for (a[b] = n(a[b]), k[b] = [], e = 0; e < a[b].length; e++) c[b][e] && (k[b][e] = {}, h(a[b][e], c[b][e], k[b][e], l + 1));else u(a[b]) ? (k[b] = E(a[b]) ? [] : {}, h(a[b], c[b] || {}, k[b], l + 1)) : k[b] = c[b] || null;
      }

      var k = {};
      h(a, (this || _global).options, k, 0);
      return k;
    };
  })(I);

  return I;
});

export default exports;